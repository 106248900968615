//string to match feed
import {TemplateData} from './TemplateData';

export enum TemplateType {
    FRONT = "frontcover",
    FRONTADS = "frontcoverwithads",
    SECTION = "sectioncover",
    SECTIONADS = "sectioncoverwithads",
    ARTICLE = "article",
}

export class Template {
    private _templateguid: string;
    private _data: TemplateData;
    private _accountguid: string;

    constructor(templateGuid: string, data: TemplateData, accountGuid: string) {
        this._templateguid = templateGuid;
        this._data = data;
        this._accountguid = accountGuid;
    }

    /**
     * Getter templateguid
     * @return {string}
     */
    public get templateguid(): string {
        return this._templateguid;
    }

    /**
     * Getter data
     * @return {TemplateData}
     */
    public get data(): TemplateData {
        return this._data;
    }

    /**
     * Getter accountguid
     * @return {string}
     */
    public get accountguid(): string {
        return this._accountguid;
    }

    /**
     * Setter templateguid
     * @param {string} value
     */
    public set templateguid(value: string) {
        this._templateguid = value;
    }

    /**
     * Setter data
     * @param {TemplateData} value
     */
    public set data(value: TemplateData) {
        this._data = value;
    }

    /**
     * Setter accountguid
     * @param {string} value
     */
    public set accountguid(value: string) {
        this._accountguid = value;
    }
}



// export class TemplateHolder {
//     private _accountTemplates: Template[];
//     private _globalTemplates: Template[];

//     constructor(accountTpls: Template[], globalTpls: Template[]){
//         this._accountTemplates = accountTpls;
//         this._globalTemplates = globalTpls;
//     }

//     public get accountTemplates(): Template[] {
//         return this._accountTemplates;
//     }
//     public set accountTemplates(value: Template[]) {
//         this._accountTemplates = value;
//     }

//     public get globalTemplates(): Template[] {
//         return this._globalTemplates;
//     }
//     public set globalTemplates(value: Template[]) {
//         this._globalTemplates = value;
//     }
// }
