import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  success(message?: string) {
    let config = new MatSnackBarConfig();

    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.panelClass = ['success-class'];
    config.duration = 1000;

    if(message) {
      this.snackBar.open(message, '', config);
    } else {
      this.snackBar.open("Saved!", '', config);
    }
  }

  failed(message?: string) {
    let config = new MatSnackBarConfig();

    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.panelClass = ['failed-class'];
    config.duration = 3000;

    if (message) {
      this.snackBar.open(message, '', config);
    } else {
      this.snackBar.open("Failed to update. Please try again", '', config);

    }
  }
}
