import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-render-feed-replica',
  templateUrl: './render-feed-replica.component.html',
  styleUrls: ['./render-feed-replica.component.css']
})
export class RenderFeedReplicaComponent implements OnInit {

  public PubName : string;
  public PubGUID : string;
  constructor() { }

  ngOnInit() {
  }

}
