import { Component, OnInit, Input } from '@angular/core';
import {EditionService} from "../../../../services/edition.service";
import {ModalService} from "../../../../modal/services/modal.service";

@Component({
  selector: 'app-view-specials',
  templateUrl: './view-specials.component.html',
  styleUrls: ['./view-specials.component.css']
})
export class ViewSpecialsComponent implements OnInit {

  @Input('sectionName') sectionName: string;
  @Input('isSectionFront') isSectionFront: boolean;
  @Input('isMultiArticle') isMultiArticle: boolean;
  @Input('index') multiArticleIndex: number;


  @Input('pageguid') pageguid: string;

  public active: string = "";


  public specials: string = "";

  constructor(private editionService: EditionService, private modalService: ModalService) { }

  ngOnInit() {
    console.log("Section Name: ", this.sectionName, "isSectionFront: ", this.isSectionFront, "isMultiArticle: ", this.isMultiArticle, "Index: ", this.multiArticleIndex, "pageguid: ", this.pageguid);
  }

  setActive(element: string): void {
    this.active = element;
  }

  addSpecials() {
    if (this.active == 'ad') {
      this.editionService.updatePageAdCode(this.specials, this.sectionName, this.pageguid);
    }
    if (this.active == 'other') {
      this.editionService.updatePageNavigateUrl(this.specials, this.sectionName, this.pageguid);
    }
    this.modalService.destroy();
  }



}
