import { Article } from "./Article";
import {Advert} from "./Advert";

export class Section {

    private _articles: Article[];
    private _name: string;
    private _sectionguid: string;
    private _color: string;
    private _logourl: string;
    private _adverts: Advert;

    constructor(articles: Article[], name: string, sectionguid: string, sectionColor: string, logourl: string, adverts: Advert) {
        this._articles = articles;
        this._name = name;
        this._sectionguid = sectionguid;
        this._color = sectionColor;
        this._logourl = logourl;
        this._adverts = adverts;

    }

    /**
     * Getter articles
     * @return {Article[]}
     */
    public get articles(): Article[] {
        return this._articles;
    }

    /**
     * Getter name
     * @return {string}
     */
    public get name(): string {
        return this._name;
    }

    /**
     * Getter sectionguid
     * @return {string}
     */
    public get sectionguid(): string {
        return this._sectionguid;
    }

  /**
   * Getter logourl
   * @return {string}
   */
  public get logourl(): string {
    return this._logourl;
  }

    /**
     * Setter articles
     * @param {Article[]} value
     */
    public set articles(value: Article[]) {
        this._articles = value;
    }

    /**
     * Setter name
     * @param {string} value
     */
    public set name(value: string) {
        this._name = value;
    }

    /**
     * Setter sectionguid
     * @param {string} value
     */
    public set sectionguid(value: string) {
        this._sectionguid = value;
    }

    public get color(): string {
        return this._color;
    }
    public set color(value: string) {
        this._color = value;
    }

    get adverts(): Advert {
      return this._adverts;
    }

    set adverts(value: Advert) {
      this._adverts = value;
    }

    public set logourl(value: string) {
      this._logourl = value;
    }
}
