import {FeedTemplateRow} from './FeedTemplateRow';
import {FlatplanSection} from './FlatplanSection';


export class FeedTemplate {
  public name: string;
  private _isdirty: boolean;
  private templateguid: string;
  public rows: FeedTemplateRow[];

  constructor(id: string, name: string, rows: FeedTemplateRow[], isdirty: boolean) {
    this.templateguid = id;
    this.name = name;
    this.rows = rows;
    this._isdirty = isdirty;
  }

  get id(): string {
    return this.templateguid;
  }

  set id(value: string) {
    this.templateguid = value;
  }

  get isdirty(): boolean {
    return this._isdirty;
  }

  set isdirty(value: boolean) {
    this._isdirty = value;
  }


}
