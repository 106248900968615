import {Component, Input, OnInit} from '@angular/core';
import {LeftNavChild} from '../../../../models/left-nav';
import {Router} from '@angular/router';

@Component({
  selector: 'app-left-nav-accordion-item',
  templateUrl: './left-nav-accordion-item.component.html',
  styleUrls: ['./left-nav-accordion-item.component.css']
})
export class LeftNavAccordionItemComponent implements OnInit {

  @Input('child') child: LeftNavChild;
  @Input('activeRoute') activeRoute: string;

  constructor(private router: Router) { }

  ngOnInit() {

  }

  onClick() {
    this.router.navigate([this.child.route]);
  }

  isActive(): boolean {
    return this.activeRoute.indexOf(this.child.route) != -1;
  }
}
