import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Article} from '../../../models/Article';
import {RenderArticle} from '../RenderArticle';

@Component({
  selector: 'app-render-feed-style-a',
  templateUrl: './render-feed-style-a.component.html',
  styleUrls: ['./render-feed-style-a.component.css']
})
export class RenderFeedStyleAComponent extends RenderArticle implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

  }

  sanitizeImg(url)
  {
    return url;
  }

}
