import { Component, OnInit } from '@angular/core';
import {ToastService} from "../../services/toast.service";
import {ModalService} from "../../modal";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  step = 1;

  username: "";
  code: "";
  newPassword = "";
  confirmNewPassword = "";

  constructor(private toastService: ToastService, private modalService: ModalService, private authService: AuthService) { }

  ngOnInit() {

  }

  async sendRequest() {
    if(this.username != "") {
      try {
        const response = await this.authService.sendResetCode(this.username);
        console.log(response);
        this.step = 2;

      } catch (e) {
        console.log(e);
        this.toastService.failed(e.message);
      }
    } else {
      this.toastService.failed("Please enter your email address");
    }
  }

  async reset() {
    if(this.newPassword === this.confirmNewPassword) {
      try {
        const resetResponse = await this.authService.resetPasswordFromCode(this.username, this.code, this.newPassword);
        console.log(resetResponse);
        this.toastService.success("Password reset succesfully");
        this.modalService.destroy();
      } catch (e) {
        console.log(e);
        this.toastService.failed(e.message);
      }
    } else {
      this.toastService.failed("Your passwords do not match");
    }

  }



}
