import {Advert} from './Advert';
import {FlatplanSection} from './FlatplanSection';


export class Flatplan {

  private _id: number;
  private _name: string;
  private _flow: string;
  private _sections: FlatplanSection[];
  private _flatplanguid : string;

  constructor(id: number, name: string, sections: FlatplanSection[], flow: string, flatplanguid : string) {
    this._id = id;
    this._name = name;
    this._sections = sections;
    this._flow = flow;
    this._flatplanguid = flatplanguid;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get flatplanguid(): string {
    return this._flatplanguid;
  }

  set flatplanguid(value: string) {
    this._flatplanguid = value;
  }

  get flow(): string {
    return this._flow;
  }

  set flow(value: string) {
    this._flow = value;
  }

  get sections(): FlatplanSection[] {
    return this._sections;
  }

  set sections(value: FlatplanSection[]) {
    this._sections = value;
  }

}







