import { Component, OnInit, Input } from '@angular/core';
import {LeftNav, LeftNavChild} from '../../../models/left-nav';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-left-nav-accordion',
  templateUrl: './left-nav-accordion.component.html',
  styleUrls: ['./left-nav-accordion.component.css']
})
export class LeftNavAccordionComponent implements OnInit {

  @Input('category') category: LeftNav;
  @Input('activeRoute') activeRoute: string;

  public showChildren: boolean = false;

  constructor() { }

  ngOnInit() {


  }

  toggleAccordion() {
    this.showChildren = !this.showChildren;
  }

  isActive(): boolean {
    let me = this;

    let returnValue = false;

    this.category.children.forEach(function(value: LeftNavChild) {
      if (me.activeRoute.indexOf(value.route) != -1) {
        me.showChildren = true;
        returnValue = true;
      }
    });

    return returnValue;
  }

}
