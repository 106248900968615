export class Product {
  private _productguid: string;
  private _producttype: string;
  private _applicationguid: string;
  private _appleproductidentifier: string;
  private _androidproductidentifier: string;
  private _name: string;
  private _description: string;

  constructor( productGuid: string, productType: string, applicationGuid: string, appleProductIdentifier: string, androidProductIdentifier: string, name: string, description: string) {
    this._productguid = productGuid;
    this._producttype = productType;
    this._applicationguid = applicationGuid;
    this._appleproductidentifier = appleProductIdentifier;
    this._androidproductidentifier = androidProductIdentifier;
    this._name = name;
    this._description = description;
  }

  public get productguid(): string {
    return this._productguid;
  }
  public set productguid(value: string) {
    this._productguid = value;
  }
  public get producttype(): string {
    return this._producttype;
  }
  public set producttype(value: string) {
    this._producttype = value;
  }
  public get applicationguid(): string {
    return this._applicationguid;
  }
  public set applicationguid(value: string) {
    this._applicationguid = value;
  }
  public get appleproductidentifier(): string {
    return this._appleproductidentifier;
  }
  public set appleproductidentifier(value: string) {
    this._appleproductidentifier = value;
  }
  public get androidproductidentifier(): string {
    return this._androidproductidentifier;
  }
  public set androidproductidentifier(value: string) {
    this._androidproductidentifier = value;
  }
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }
}
