import { Component, OnInit } from '@angular/core';
import {LeftNav, LeftNavChild} from '../../models/left-nav';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import {RoutingService} from '../../services/routing.service';


@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css']
})
export class LeftNavComponent implements OnInit {

  constructor(private routing: RoutingService) { }

  public categories: LeftNav[] = new Array<LeftNav>();
  public activeRoute: string;

  ngOnInit() {

    this.setActiveRoute();

    this.categories.push(
      new LeftNav(
        'assets/img/Home/App-Icon.png',
        'App',
        [
          new LeftNavChild('View Apps', '/apps'),
          new LeftNavChild('Create App', '/apps/create'),
          new LeftNavChild('Feeds', '/apps/feeds')
        ]
      )
    );
    /*this.categories.push(
      new LeftNav(
        'AppIcon',
        'Publication',
        [
          new LeftNavChild('Create Publication', '')
        ]
      )
    );*/
  }

  private setActiveRoute() {

    this.activeRoute = this.routing.currentActiveRoute;

    this.routing.activeRoute.subscribe(
      (activeRoute: string) => {
        this.activeRoute = activeRoute;
      }
    );
  }

  public getActiveIndex(index: number): boolean {
    if (index == 0) {
      return true;
    }
    return false;
  }

}
