import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from "../services/loading.service";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  constructor(private loadsrc: LoadingService, private cd: ChangeDetectorRef, ) { }

  public working: boolean = false;



  ngOnInit() {
    this.loadsrc.subjLoading.subscribe(next => {
      console.log(next);
      if (next === true) {
        this.working = true;
      } else {
        this.working = false;
      }
      this.cd.detectChanges();
    });
  }


}
