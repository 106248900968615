import { Directive, HostListener, HostBinding, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {MessageDialogService} from "../modules/message-dialog-module/services/message-dialog.service";
import {Prompt} from "../modules/message-dialog-module/models/Prompt";

@Directive({
  selector: '[FileDrop]'
})
export class FileDropDirective implements OnInit {

  ngOnInit(): void { }

  @Input('allowedExts') allowedExts: Array<string>;
  @Output('fileChangeEmitter') fileChangeEmitter: EventEmitter<File[]> = new EventEmitter();
  @Output('fileInvalidEmitter') fileInvalidEmitter: EventEmitter<File[]> = new EventEmitter();
  @Output('fileListEmitter') fileListEmitter: EventEmitter<File[]> = new EventEmitter();

  // @HostBinding('style.background') bgColor = '#FFF';

  @HostListener('dragenter', ['$event']) onDragEnter(event) { }

  @HostListener('dragover', ['$event']) onDragOver(event) {
    this.prevent(event);
    // this.bgColor = '#999';
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    this.prevent(event);
    this.resetBgColor();
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.prevent(event);
    this.resetBgColor();

    let files = event.dataTransfer.files, validFiles: Array<File> = [], invalidFiles: Array<File> = [];

    if (!this.allowedExts) {
      this.allowedExts = ['jpg', 'svg', 'png'];
      console.log("Allowed types have been defaulted forcefully due to being undefined");
    }

    if (files && files.length > 0) {
      Array.from(files).forEach((file: File) => {
        //Extract extension from file name
        let ext: string = file.name.split('.')[file.name.split('.').length - 1];
        ext = ext.toLowerCase();
        ext = ext.trim();

        console.log("EXTENSION: ", ext, "ALLOWED: ", this.allowedExts);
        //Sort valid and invalid files by extension
        if (this.allowedExts.lastIndexOf(ext) != -1) {
          validFiles.push(file);
        }
        else {
          invalidFiles.push(file);
        }
      });

      this.checkInvalid(invalidFiles);

      if (validFiles) {
        this.fileChangeEmitter.emit(validFiles);
      }
    }
  }

  constructor(private messageService: MessageDialogService) { }

  /* Helper Methods */

  private resetBgColor(): void {
    // this.bgColor = '#FFF';
  }

  private prevent(e): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private isImage(file: File): boolean {
    let imageType = /^image\//;
    if (!imageType.test(file.type)) {
      alert('Sorry! ' + file.name + ' is not an image.');
      return false;
    }
    return true;
  }

  private checkInvalid(invalidFiles: Array<File>) {
    if (invalidFiles.length > 0) {
      let fileTypes: string = "";

      invalidFiles.forEach(file => {
        if (!fileTypes.includes(file.type)) {
          fileTypes.concat(" " + fileTypes);
        }
      });

      let invalid = new Prompt("File types invalid.", "The following file types are not permitted: \n" + fileTypes + " \nOnly " + this.allowedExts + " will be processed.", "ok");

      invalid.positiveCallback = () => {
        this.messageService.removeDialog();
      }

      this.messageService.createDialog(invalid);
    }
  }

}
