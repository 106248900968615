import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApplicationsService} from "../../../services/applications.service";
import {App} from "../../../models/App";
import {untilComponentDestroyed} from "@w11k/ngx-componentdestroyed";
import {ModalService} from "../../../modal/services/modal.service";

@Component({
  selector: 'app-add-app-to-kiosk',
  templateUrl: './add-app-to-kiosk.component.html',
  styleUrls: ['./add-app-to-kiosk.component.css']
})
export class AddAppToKioskComponent implements OnInit, OnDestroy {

  public apps: App[];
  @Input('kioskCategoryIndex') kioskCategoryIndex;

  constructor(private appService: ApplicationsService, private modalService: ModalService) { }

  ngOnInit() {

    this.appService.getAllApps().pipe(untilComponentDestroyed(this)).subscribe((apps: Array<App>) => {
      if (apps.length > 0) {
        this.apps = apps;
      }
    });

  }

  ngOnDestroy() {

  }

  //not setting boolean to false
  addApp(app: App) {
    if (this.appService.addingToArchive) {
      this.appService.addedItemToArchive.next(app);
      this.modalService.destroy();
    } else {
      this.appService.addedItemToKiosk.next(app);
      this.modalService.destroy();
    }
  }

}
