export class PageArticle {

    private _section: string;
    private _articleguid: string;

    constructor(sectionName: string, articleGuid: string) {
        this._section = sectionName;
        this._articleguid = articleGuid;
    }

    public get section(): string {
        return this._section;
    }
    public set section(value: string) {
        this._section = value;
    }
    public get articleguid(): string {
        return this._articleguid;
    }
    public set articleguid(value: string) {
        this._articleguid = value;
    }



}