import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Breadcrumb } from '../../models/breadcrumb';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'route-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input('crumb') breadcrumb: Breadcrumb;
  @Input('index') index: number;
  @Input('count') count: number;
  @Input('active') isActive: boolean;

  public hasParams: Function;
  path: string;

  constructor(private breadcrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.hasParams = this.breadcrumbService.hasParams;
    this.path = this.setPath();
  }

  setPath() {
    let backTrackCount = this.count - this.index;
    let string = "";
    for (let i = 0; i < backTrackCount; i++) {
      string = string + '../';
    }
    return string;
  }

  goToPage() {
    this.router.navigate(['../']);
  }
}
