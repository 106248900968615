import { Injectable } from '@angular/core';
import {Feed, FeedArticle, FeedItem, FeedMapping} from '../models/Feed';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject, Observable } from "rxjs/Rx";
import { ApiService } from './api.service';
import { FunctionService } from './function.service';
import { ToastService } from './toast.service';
import { App, IAppSection } from '../models/App';
import {FeedTemplate} from '../models/FeedTemplate';
import { take } from 'rxjs/operators';
import {reject} from 'q';

@Injectable({
  providedIn: 'root'
})
export class FeedsService {

  //Subjects
  public feedsClone = new Subject();

  //State managed observable objects
  private _ActiveFeedItem: BehaviorSubject<FeedItem> = new BehaviorSubject<FeedItem>(<FeedItem>{});
  private _HeadlineMapping: BehaviorSubject<FeedMapping> = new BehaviorSubject<FeedMapping>(<FeedMapping>{});
  private _FeedArray: BehaviorSubject<Array<Feed>> = new BehaviorSubject<Array<Feed>>(new Array<Feed>());
  private _FeedTemplateArray: BehaviorSubject<Array<FeedTemplate>> = new BehaviorSubject<Array<FeedTemplate>>(new Array<FeedTemplate>());
  private _AppSpecificFeeds: BehaviorSubject<Array<Feed>> = new BehaviorSubject<Array<Feed>>(new Array<Feed>());

  //This vars
  private _activeSingleFeed: Feed;
  private feedContentItems: any; //This object changes dependant on the feed of the account user... in this instance Type<any> is beneficial.

  constructor(private apiService: ApiService, private func: FunctionService, private toast: ToastService) {
  }

  /* Getters and Setters for Object Observables  */
  public getFeedArray(): Observable<Array<Feed>> {
    return this._FeedArray.asObservable();
  }
  public setFeedArray(value: Array<Feed>): void {
    this._FeedArray.next(value);
  }

  public getFeedTemplates(): Observable<Array<FeedTemplate>> {
    return this._FeedTemplateArray.asObservable();
  }
  public setFeedTemplates(value: Array<FeedTemplate>): void {
    this._FeedTemplateArray.next(value);
  }


  public getActiveFeedItem(): Observable<FeedItem> {
    return this._ActiveFeedItem.asObservable();
  }
  public setActiveFeedItem(value: FeedItem): void {
    this._ActiveFeedItem.next(value);
  }

  public getHeadlineMapping(): Observable<FeedMapping> {
    return this._HeadlineMapping.asObservable();
  }
  public setHeadlineMapping(value: FeedMapping): void {
    this._HeadlineMapping.next(value);
  }

  public getAppSpecificFeeds(): Observable<Array<Feed>> {
    return this._AppSpecificFeeds.asObservable();
  }

  public setAppSpecificFeeds(value: Array<Feed>) {
    this._AppSpecificFeeds.next(value);
  }

  /* Getters and Setters for Objects */

  public get activeSingleFeed(): Feed {
    return this._activeSingleFeed;
  }
  public set activeSingleFeed(value: Feed) {
    this._activeSingleFeed = value;
  }

  /*___End of Getters and Setters */

  public initialiseHeadlineMapping(): boolean {
    for (let thisMapping of this.activeSingleFeed.mappings) {
      if (thisMapping.node === "Headline" || thisMapping.node === "") {
        this.setHeadlineMapping(thisMapping);
        return true;
      }
    }
    this.setHeadlineMapping(<FeedMapping>{});
    return false;
  }

  public loadFeedTemplates() : void
  {
    this.apiService.getFeedTemplates().then((feedtemplates: Array<FeedTemplate>) => {
      this.setFeedTemplates(feedtemplates);
    }).catch(err => {
      console.error(err, "Failed to get feeds from account guid.");
    })
  }

  public loadFeeds(accountGuid: string, app?: App): void {
    this.apiService.getFeedsFromAccountGuid(accountGuid).then((feeds: Array<Feed>) => {
      this.setFeedArray(feeds);
      //this.dividedFeedLog(feeds);

      if (app) {
        let appFeeds: Array<Feed> = new Array<Feed>();
        // app.sections.forEach((section: IAppSection) => {
        //   this._FeedArray.value.forEach((feed: Feed) => {
        //     if (section.feedguid == feed.feedguid) {
        //       appFeeds.push(feed);
        //     }
        //   });
        // });
        // this.setAppSpecificFeeds(appFeeds);
      }

    }).catch(err => {
      console.error(err, "Failed to get feeds from account guid.");
    });
  }

  private loadingFeeds = false;

  public loadFeedsPromise(accountGuid: string): Promise<Array<Feed>> {
    return new Promise<Array<Feed>>((resolve, reject) => {
      if (this._FeedArray.value.length === 0) {
        // put in a loading feeds check incase 2 systems are trying to request feeds at the same time
        // for an example on the edit feed page, this page is requesting this plus the breadcrumbs so this stops 2 requests going out
        // the last request will listen to an observer on the feed array to get the content
        if (!this.loadingFeeds) {
          this.loadingFeeds = true;

          this.apiService.getFeedsFromAccountGuid(accountGuid).then((feeds: Array<Feed>) => {
            this.loadingFeeds = false;
            this.setFeedArray(feeds);
            resolve(feeds);
          }).catch(err => {
            reject(err);
          });
        }
        else {
          // I have piped this to automatically destroy the subscription after 2 takes
          // the reason it is 2 as the first take, the result is an empty array, which is why the if statement is in there
          this.getFeedArray().pipe(take(2)).subscribe((feedObjects) => {
            if (feedObjects.length > 0) {
              resolve(feedObjects);
            }
          });
        }
      }
      else {
        resolve(this._FeedArray.value);
      }
    })
  }

  public hasFeeds(accountGuid: string) {
    return new Promise((resolve, reject) => {
      this.apiService.getFeedsFromAccountGuid(accountGuid).then((feeds: Array<Feed>) => {
        if(feeds.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(err => {
        console.error(err, "Failed to get feeds from account guid.");
      });
    });

  }

  private dividedFeedLog(feeds: Array<Feed>) {
    let deleted: Array<Feed> = new Array<Feed>(), ok: Array<Feed> = new Array<Feed>();
    feeds.forEach(feed => {
      if (feed.isdeleted == true) {
        deleted.push(feed);
      }
      if (feed.isdeleted == false) {
        ok.push(feed);
      }
    });
    console.log("OK", ok, "DELETED", deleted);
  }

  public getFeed(feedGuid, accountGuid): Promise<Feed> {
    return new Promise((resolve) => {
      if (this._FeedArray.value.length <= 0) {
        this.apiService.getFeedsFromAccountGuid(accountGuid).then(feeds => {
          this.setFeedArray(feeds);
          resolve(this.getFeedFromFeedGuid(feedGuid));
        });
      }
      else {
        resolve(this.getFeedFromFeedGuid(feedGuid));
      }
    })
  }

  public getFeedFromFeedGuidPromise(feedGuid, accountGuid): Promise<Feed> {
    return new Promise<Feed>((resolve, reject) => {
      let feed = this.getFeedByGuid(feedGuid);
      if (feed != null) {
        resolve(feed);
      }
      else {
        this.loadFeedsPromise(accountGuid).then(() => {
          feed = this.getFeedByGuid(feedGuid);
          if (feed != null) {
            resolve(feed);
          }
          else {
            reject("Feed not found");
          }
        }).catch(err => {
          reject(err);
        });
      }
    });
  }

  public getFeedFromFeedGuid(feedGuid): Feed {
    for (let feed of this._FeedArray.value) {
      if (feed.feedguid == feedGuid) {
        this.activeSingleFeed = feed;
        return feed;
      }
    }
  }

  public getFeedByGuid(feedGuid: string): Feed {
    for (let thisFeed of this._FeedArray.value) {
      if (thisFeed.feedguid === feedGuid) {
        return thisFeed;
      }
    }
    return null;
  }



  /* Feed controllers */

  public addFeed(feed: Feed): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.postFeed(feed).then(res => {
        let newFeedGuid = res['feedGuid'];
        this.toast.success("");
        this.setFeedArray(new Array<Feed>());
        this.parseFeed(newFeedGuid);
        resolve(res);
      }).catch(err => {
        this.toast.failed();
        reject(err);
      });
    })
  }

  public deleteFeed(index: number): void {
    console.log(index);
    this._FeedArray.value[index].isdeleted = true;
    this.updateFeed(this._FeedArray.value[index]);
  }

  private updateFeed(feed: Feed): void {
    this.apiService.updateFeed(feed).then(() => {
      this.toast.success("");
      this.updateFeedUI(feed);
    }).catch(err => {
      this.toast.failed();
      console.error(err, "Couldn't update feed.");
    });
  }

  private updateFeedUI(feed: Feed): void {
    let idx = this.getFeedIndex(feed.feedguid), feedArray = this._FeedArray.value;
    feedArray[idx] = feed;
    //console.log("UPDATED ARRAY: ");
    //this.dividedFeedLog(feedArray);
    this.setFeedArray(feedArray);
  }

  /* End of Feed controllers */

  /* Mapping controllers */

  public addMapping(map: FeedMapping): void {
    this.activeSingleFeed.mappings.splice(0, 0, map);

    //TODO: Resolve this initilise check method call point? 19/9/2018
    if (this.func.isEmptyObject(this._HeadlineMapping.value)) {
      this.initialiseHeadlineMapping();
    }
  }

  public deleteMapping(index: number): void {
    this.activeSingleFeed.mappings.splice(index, 1);
  }

  public saveMappings(): void {
    this.updateFeed(this.activeSingleFeed);
    console.log(this.activeSingleFeed);
    this.parseFeed(this.activeSingleFeed.feedguid);
  }

  /* End of Mappings controllers */

  private getFeedIndex(feedguid: string): number {
    this._FeedArray.value.forEach((f: Feed, i: number) => {
      if (f.feedguid == feedguid) {
        return i;
      }
    });
    return -1;
  }

  public getFeedContent(url: string, articlePath: string): Promise<any> {
    console.log("GETTING FEED CONTENT");
    return new Promise((resolve) => {
      this.apiService.getFeedContent(url).then(res => {
        console.log(res);
        console.log(articlePath);

        //this.feedContentItems = this.getFeedArrayItems(res);
        if(articlePath == '*') {
          articlePath = "";
        }
        this.feedContentItems = this.getFeedArticlesUsingJsonPath(res, articlePath.replace('$', ''));
        console.log(this.feedContentItems);
        this.setActiveFeedItem(this.feedContentItems[0]);
        //

        // this.setActiveFeedItem(this.feedContentItems[0]);
        // if(articlePath == '*') {
        //   this.feedContentItems = res;
        // }
        resolve(this.feedContentItems);
      });
    });
  }

  getFeedArrayItems(items) {
    if (typeof items === 'object' && items.length > 0) {
      return items;
    } else if (typeof items === 'object') {
      let foundArray = [];
      Object.keys(items).forEach(key => {
        if(typeof items[key] === 'object' && items[key].length > 0) {
          foundArray = items[key];
        }
      })
      return foundArray;
    }
  }

  public updateActiveFeedItem(index: number): void {
    this.setActiveFeedItem(this.feedContentItems[index]);
  }

  public getFeedArticlesUsingJsonPath(jsonObject, articlePath: string) {
    var thisJsonObjectPath = this.parseJsonObjectWithJsonPath(jsonObject, articlePath);
    return thisJsonObjectPath;
  }

  public parseJsonObjectWithJsonPath(jsonObject, xPath: string) {
    if (xPath.indexOf('.') === -1) {
      let newPath = xPath.replace('[*]', '');
      if (newPath != '') {
        return jsonObject[xPath.replace('[*]', '')];
      }
      else {
        return jsonObject;
      }
    }
    else {
      let remainingNodes = xPath.split('.');
      let firstNode = remainingNodes[0];
      remainingNodes.shift();

      let childJson = null;

      if (firstNode.indexOf('[*]') > -1)
      {
        if(jsonObject[firstNode.replace('[*]', '')] && jsonObject[firstNode.replace('[*]', '')].length > 0) {
          childJson = jsonObject[firstNode.replace('[*]', '')][0];
        }
        console.log(jsonObject[firstNode.replace('[*]', '')]);


      }
      else {
        childJson = jsonObject[firstNode];
      }

      //If the child node doesn't exist, exit the recursion process with a blank string
      if (childJson != null) {
        let childXPath = remainingNodes.join('.');
        return this.parseJsonObjectWithJsonPath(childJson, childXPath);
      }
      else {
        return "";
      }
    }
  }

  public getParsedFeedContent(feedguid) {
    return this.apiService.getParsedFeedContent(feedguid);
  }

  public parseFeed(feedguid) {
    return this.apiService.parseFeed(feedguid);
  }
}
