import {AdvertType} from './AdvertType';

export class Advert {
  private _banner: AdvertType;
  private _interstitial: AdvertType;
  private _mpu: AdvertType;

  constructor(banner: AdvertType, interstitial: AdvertType, mpu: AdvertType) {
    this._banner = banner;
    this._interstitial = interstitial;
    this._mpu = mpu;
  }

  /**
   * Getter image
   * @return {string}
   */
  public get banner(): AdvertType {
    return this._banner;
  }

  /**
   * Getter caption
   * @return {string}
   */
  public get interstitial(): AdvertType {
    return this._interstitial;
  }

  /**
   * Getter size
   * @return {Object}
   */
  public get mpu(): AdvertType {
    return this._mpu;
  }

  /**
   * Setter image
   * @param {string} value
   */
  public set mpu(value: AdvertType) {
    this._mpu = value;
  }

  /**
   * Setter caption
   * @param {string} value
   */
  public set interstitial(value: AdvertType) {
    this._interstitial = value;
  }

  /**
   * Setter size
   * @param {Object} value
   */
  public set banner(value: AdvertType) {
    this._banner = value;
  }



}
