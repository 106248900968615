import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { EditionService } from "../../../../services/edition.service";
import { Template } from "../../../../models/Template";
import { Page } from "../../../../models/page";
import { Article } from "../../../../models/Article";
import { Edition } from "../../../../models/edition";
import { PageArticle } from "../../../../models/PageArticle";
import { ModalService } from "../../../../modal/services/modal.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { LoadingService } from "../../../../services/loading.service";
import { FunctionService } from "src/app/services/function.service";

import * as $ from "jquery";
import { TemplateService } from "../../../../services/template.service";
import { REST } from "../../../../models/enums/api-end-points";
import { DomSanitizer } from "@angular/platform-browser";
import { reject } from "q";
import { ToastService } from "../../../../services/toast.service";
import { Section } from "src/app/models/Section";
import { ApplicationsService } from 'src/app/services/applications.service';
require("jquery-ui");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/disable-selection");

@Component({
  selector: "app-section-front-editor",
  templateUrl: "./section-front-editor.component.html",
  styleUrls: ["./section-front-editor.component.css"],
})
export class SectionFrontEditorComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    this.makeArticleListSortable();
  }

  templatetypeof: string;
  showNewArticles = false;
  edition: Edition;
  template: Template;

  selectedIndex: number = -1;

  allArticles: Article[];

  @Input("page") page: Page;
  @Input("isNew") isNew: boolean;

  articles: PageArticle[] = [];

  newArticleIndex: number;

  sectionName: string;

  templateHtml: any;
  pageLoaded = false;

  platformId = 2;

  iframeSizes = {
    tablet: {
      height: "1024",
      width: "768",
      scale: "0.337",
    },
    phone: {
      height: "570",
      width: "320",
      scale: "0.6",
    },
    desktop: {
      height: "1024",
      width: "768",
      scale: "0.337",
    },
  };

  @ViewChild("iframe") iframe;

  // for importing pages
  pageView: string = "create";
  selectedPublicationName: {};
  selectedEditionName: {};
  selectedSectionNames: any = [];
  selectedPages: any = [];

  allPublications: any = [{ name: "Akash" }, { name: "Ben" }, { name: "Kev" }];
  allEditions: Edition[];
  allSections: any[];
  allPages: Page[];

  constructor(
    private editionService: EditionService,
    private modalService: ModalService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private func: FunctionService,
    private templateService: TemplateService,
    private domSanny: DomSanitizer,
    private changeDetection: ChangeDetectorRef,
    private toastService: ToastService,
    private appsrc: ApplicationsService
  ) {}

  ngOnInit() {
    this.templateHtml = this.domSanny.bypassSecurityTrustHtml(
      "<html><head><style>*{font-size: 100px}</style></head><body>hello</body></html>"
    );

    setTimeout(() => {
      this.loadingService.setFinished();
      this.setTemplateHtml();
      this.makeArticleListSortable();
    }, 500);

    this.templateService.getAllTemplatesForAccount().then(() => {
      if (this.page.contenturl) {
        this.template = this.templateService.getTemplateFromUrl(
          this.page.contenturl
        );

        this.edition = this.editionService.jsonedition;
        this.sectionName = this.page.section;

        if (this.editionService.currentPlatformId == 2) {
          this.templatetypeof = "tablet";
        } else if (this.editionService.currentPlatformId == 3) {
          this.templatetypeof = "phone";
        } else if (this.editionService.currentPlatformId == 4) {
          this.templatetypeof = "desktop";
        }

        this.platformId = this.editionService.currentPlatformId;

        this.setUpArticles();
      }
      this.pageLoaded = true;
    });

    this.getPublicationsEditions(); // to get all publications n sections for import
  }

  select(template: Template) {
    this.template = template;
    this.page.contenturl = template.data.html;
    this.page.properties = template.data.properties;
    if (template.data.articles < this.articles.length) {
      let articlesToSlice = this.articles.length - template.data.articles;
      this.articles.splice(template.data.articles, articlesToSlice);
    } else if (template.data.articles > this.articles.length) {
      let articlesNeeded = template.data.articles - this.articles.length;
      for (let i = 0; i < articlesNeeded; i++) {
        this.articles.push(new PageArticle("", null));
      }
    }
    this.setTemplateHtml();
    console.log(this.page);
  }

  private makeArticleListSortable(): void {
    let me = this,
      initialIndex: number = 0;

    //(<any>$("#article-list-ref")).sortable(); //{ items: "> div:not(:last)" });

    (<any>$("#article-list-ref"))
      .sortable({
        start: (event: any, ui: any) => {
          initialIndex = ui.item.index();
        },
        update: (event, ui) => {
          let updatedIndex = ui.item.index();
          me.articles = me.func.reorderItemInArray(
            me.articles,
            initialIndex,
            updatedIndex
          );
          me.setTemplateHtml();
        },
      })
      .disableSelection();
  }

  deletePromo(index: number) {
    this.page.promotions.splice(index, 1);
  }

  getSectionFrontPromotions() {
    return this.page.promotions;
  }

  getSectionFrontPromotionTypes() {
    return ["png", "jpg", "bmp"];
  }

  getTemplate(): Template {
    return this.template;
  }

  hasPromotions(): boolean {
    const template = this.getTemplate();
    if (template) {
      if (
        template != null &&
        template.data.promotions != null &&
        template.data.promotions > 0
      ) {
        return true;
      }
    } else if (this.page.promotions.length > 0) {
      return true;
    }

    return false;
  }

  sectionFrontPromotionsChange(event) {
    let fileList: FileList;
    //Handles various file-types
    if (event instanceof Event) {
      //Changes scope of event for typescript compiler
      event = event;
      fileList = event.target.files;
    } else {
      fileList = event;
    }

    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        let headers = new HttpHeaders({ "Content-Type": "image/jpeg" });
        var options = {
          headers: headers,
        };
        this.http
          .post(
            "https://jgeeiu4ydf.execute-api.eu-west-1.amazonaws.com/dev",
            file,
            options
          )
          .catch((error) => Observable.throw(error))
          .subscribe(
            (data) => {
              this.addSectionFrontPromotion(data.toString());
            },
            (error) => console.error(error)
          );
      }
    }
  }

  addSectionFrontPromotion(url: string) {
    this.page.promotions.push(url);
  }

  setUpArticles() {
    //Eliminates the potential for the array to be shallow-copied
    this.page.articles.forEach((article) => {
      this.articles.push(new PageArticle(article.section, article.articleguid));
    });
  }

  deleteArticle(article: Article) {
    article.articleguid = null;
  }

  addNewArticle(article: Article, i: number) {
    this.showNewArticles = true;
    this.newArticleIndex = i;
  }

  updateSelectedArticles(event) {
    let arts = event.articles;
    let pages = this.editionService.getPagesForSection(event.section);
    this.allArticles = [];
    console.log(pages);
    pages.forEach((page) => {
      if (page.type == "templateArticle" && !page.toDelete) {
        this.allArticles.push(
          this.editionService.getArticle(page.articles[0].articleguid)
        );
      }
    });
    this.selectedIndex = -1;
  }

  selectArticle(article: Article) {
    this.articles[this.newArticleIndex] = new PageArticle(
      article.section,
      article.articleguid
    );
    this.showNewArticles = false;
    setTimeout(() => {
      this.loadingService.setFinished();
      this.makeArticleListSortable();
      this.setTemplateHtml();
    }, 300);
  }

  addSectionFront() {
    this.page.articles = [];
    console.log(this.articles);
    let missingArticle = false;
    this.articles.forEach((art) => {
      if (art.articleguid == null) {
        missingArticle = true;
      }
    });
    console.log(this.template);
    if (missingArticle) {
      this.toastService.failed(
        "Please add the correct number of articles for this template"
      );
    } else {
      this.articles.forEach((art, index) => {
        let newArticleObj = new PageArticle(art.section, art.articleguid);
        this.page.articles.push(newArticleObj);
      });
      if (this.isNew) {
        this.editionService.addPageToEdition(this.page);
      }
      this.page.isEdited = true;
      this.editionService.iframeRefresh.next();
      this.modalService.destroy();
      this.editionService.editionHasChanged.next(true);
    }
  }

  getArticle(guid: string) {
    return this.editionService.getArticle(guid);
  }

  getArticleImage(guid: string) {
    let article = this.editionService.getArticle(guid);
    if (article != null && article.images && article.images[0]) {
      return article.images[0].image;
    }
  }

  getArticleHeadline(guid: string): string {
    let article = this.editionService.getArticle(guid);
    if (article != null) {
      return article.headline;
    }
    return "";
  }

  setTemplateHtml() {
    let postArticles = [];

    this.articles.forEach((art) => {
      postArticles.push(art.articleguid);
    });

    let postObject = {
      editionGuid: this.editionService.jsonedition.editionGUID,
      sectionName: this.sectionName,
      articles: postArticles,
      promotions: this.page.promotions,
      pageguid: this.page.pageguid,
    };

    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: headers,
      responseType: "text",
    };

    console.log(postObject);

    this.http
      .post(
        REST.GENERATE_SCREENSHOT_HTML.toString().replace(
          "{templateGuid}",
          this.templateService.getTemplateFromUrl(this.page.contenturl)
            .templateguid
        ),
        postObject,
        { responseType: "text" }
      )
      .subscribe((res) => {
        // this.templateHtml = this.domSanny.bypassSecurityTrustHtml(varry);
        this.iframe.nativeElement.contentDocument.open();
        this.iframe.nativeElement.contentDocument.write(res);
        this.iframe.nativeElement.contentDocument.close();
        console.log("heee");
        this.changeDetection.detectChanges();
        // this.templateHtml = atob(res);
      });
  }

  getPublicationsEditions() {
    this.appsrc.getAllApps().subscribe((data) => {
      this.allPublications = data;
    });
    console.log(this.allPublications);
  }

  changePublication(event: any) {
    this.selectedSectionNames = [];
    this.selectedEditionName = {};
    this.allEditions = [];
    this.allSections = [];

    this.appsrc.loadEditions(event.guid, 0, 10).then((editions: any[]) => {
      this.allEditions = editions;
    });
    // console.log(this.allEditions);
  }

  changeEdition(event: any) {
    this.selectedSectionNames = [];
    this.allSections = [];
    this.allPages = [];
    this.editionService
      .getSectionFromEdition(event.editionguid)
      .subscribe((data) => {
        this.allSections = data;
      });
  }

  changeSection() {
    this.selectedPages = [];
    this.allPages = [];
  }
}
