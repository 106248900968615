import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Flatplan} from '../models/Flatplan';

@Injectable({
  providedIn: 'root'
})
export class FlatplanService {

  private flatplanDict = {};

  constructor(private apiService: ApiService) { }

  getFlatplan(flatplanGuid): Promise<Flatplan> {
    return new Promise((resolve, reject) => {
      // try and get cached version first
      if (this.flatplanDict[flatplanGuid] != null) {
        resolve(this.flatplanDict[flatplanGuid]);
      }
      // can't find cached version, get flatplan from API and stores it against the flatplan guid
      else {
        this.apiService.getFlatplan(flatplanGuid).then((flatplan: Flatplan) =>
        {
          if (typeof this.flatplanDict[flatplan.flatplanguid] === 'undefined') {
            this.flatplanDict[flatplan.flatplanguid] = flatplan;
          }
          resolve(this.flatplanDict[flatplanGuid]);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  getFlatplanInline(flatplanGuid): Flatplan {
    if (this.flatplanDict[flatplanGuid] != null) {
      return this.flatplanDict[flatplanGuid];
    }
    else {
      return null;
    }
  }

}
