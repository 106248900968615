export class LeftNavChild {
  private _name: string;
  private _route: string;
  private _children: LeftNavChild[];
  constructor(name: string, route: string, children: LeftNavChild[] = null) {
    this._name = name;
    this._route = route;
    this._children = children;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get children(): LeftNavChild[] {
    return this._children;
  }

  set children(value: LeftNavChild[]) {
    this._children = value;
  }

  get route(): string {
    return this._route;
  }

  set route(value: string) {
    this._route = value;
  }
}

export class LeftNav {

  private _icon: string;
  private _name: string;
  private _children: LeftNavChild[];

  constructor(icon: string, name: string, children: LeftNavChild[])
  {
    this._icon = icon;
    this._name = name;
    this._children = children;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get children(): LeftNavChild[] {
    return this._children;
  }

  set children(value: LeftNavChild[]) {
    this._children = value;
  }
}
