
export class FlatplanPlatform {

  private _templateguid : string;
  private _id : number;


  constructor(templateguid: string, id: number) {
    this._templateguid = templateguid;
    this._id = id;


  }

  get templateguid(): string {
    return this._templateguid;
  }

  set templateguid(value: string) {
    this._templateguid = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }



}
