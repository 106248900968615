import { Params } from "@angular/router";

export class Breadcrumb{

    private _label: string;
    private _url: string;
    private _params: Params;
  private _value: string;

	constructor(label: string, url: string, params: Params, value: string) {
		this._label = label;
		this._url = url;
		this._params = params;
		this._value = value;
	}

    /**
     * Getter label
     * @return {string}
     */
	public get label(): string {
		return this._label;
	}

    /**
     * Getter url
     * @return {string}
     */
	public get url(): string {
		return this._url;
	}

    /**
     * Getter params
     * @return {Params}
     */
	public get params(): Params {
		return this._params;
	}

    /**
     * Setter label
     * @param {string} value
     */
	public set label(value: string) {
		this._label = value;
	}

    /**
     * Setter url
     * @param {string} value
     */
	public set url(value: string) {
		this._url = value;
	}

    /**
     * Setter params
     * @param {Params} value
     */
	public set params(value: Params) {
		this._params = value;
	}

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }
}
