import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ModalService} from "../../modal";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  newPassword: string;
  confirmNewPassword: string;

  constructor(private authService: AuthService,
              private modalService: ModalService,
              private toastService: ToastService) { }

  ngOnInit() {
  }

  async reset() {
    if(this.newPassword === this.confirmNewPassword) {
      try {
        const resetResponse = await this.authService.resetPassword(this.newPassword);
        this.toastService.success("Password reset succesfully");
        this.modalService.destroy();
      } catch (e) {
        console.log(e);
        this.toastService.failed(e.message);
      }
    } else {
      this.toastService.failed("Your passwords do not match");
    }

  }

}
