import {FlatplanPage} from './FlatplanPage';
import {Advert} from './Advert';

export class FlatplanSection {

  private _name: string;
  private _pages: FlatplanPage[];
  private _color: string;
  private _feedguid: string;
  private _feedtemplateguid: string;
  private _adverts: Advert;
  private _logourl: string;
  private _isspecial: boolean;


  constructor(name: string, pages: FlatplanPage[], color: string, feedguid: string, advert: Advert, feedtemplateguid: string, logourl: string, isspecial: boolean) {
    this._name = name;
    this._pages = pages;
    this._color = color;
    this._feedguid = feedguid;
    this._adverts = advert;
    this._feedtemplateguid = feedtemplateguid;
    this._logourl = logourl;
    this._isspecial = isspecial;

  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get pages(): FlatplanPage[] {
    return this._pages;
  }

  set pages(value: FlatplanPage[]) {
    this._pages = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get feedtemplateguid(): string {
    return this._feedtemplateguid;
  }

  set feedtemplateguid(value: string) {
    this._feedtemplateguid = value;
  }

  get feedguid(): string {
    return this._feedguid;
  }

  set feedguid(value: string) {
    this._feedguid = value;
  }

  get adverts(): Advert {
    return this._adverts;
  }

  set adverts(value: Advert) {
    this._adverts = value;
  }

  get logourl(): string {
    return this._logourl;
  }

  set logourl(value: string) {
    this._logourl = value;
  }


  get isspecial(): boolean {
    return this._isspecial;
  }

  set isspecial(value: boolean) {
    this._isspecial = value;
  }


}
