import { Edition } from "./edition";
import { Product } from "./Product";
import { Flatplan } from './Flatplan';
import {AppKioskApp} from "./AppKioskApp";
import {AppKioskCategory} from "./AppKioskCategory";
import {AppKiosk} from "./AppKiosk";
import {Theme} from "./Theme";
import {PageSettings} from "./PageSettings";
import { Archive } from './Archive';

export class App {

  private _app: IApp;
  private _archive: Array<Archive>;
  private _menu: IAppMenu;
  private _fonts: IAppFonts;
  private _kiosk: AppKiosk;
  private _footer: IAppFooter;
  private _header: IAppHeader;
  private _replica: IAppReplica;
  private _sections: Array<IAppSection>;
  private _advertising: IAppAdvertising;
  private _editionrules: IAppEditionRules;
  private _editions: Array<Edition>;
  private _subscriptions: any;
  private _feed: Array<Flatplan>;
  private _custom: any;
  private _locale: any;
  private _masthead: IAppMasthead;
  private _portalsettings: any;
  private _tabs: any;
  private _settings: any;
  private _helperscreens: Array<any>;
  private _categoryselector: any;
  private _datacapture: any;


  constructor(app: IApp,
              archive: Array<Archive>,
              menu: IAppMenu,
              fonts: IAppFonts,
              kiosk: AppKiosk,
              footer: IAppFooter,
              header: IAppHeader,
              replica: IAppReplica,
              sections: Array<IAppSection>,
              advertising: IAppAdvertising,
              editionrules: IAppEditionRules,
              editions: Array<Edition>,
              subscriptions: any,
              feed: Array<Flatplan>,
              custom: any,
              locale: any,
              masthead: IAppMasthead,
              portalsettings: any,
              tabs: any,
              settings: any,
              helperscreens: any,
              categoryselector: any,
              datacapture: any
              ) {


    this._app = app;
    this._archive = archive;
    this._menu = menu;
    this._fonts = fonts;
    this._kiosk = kiosk;
    this._footer = footer;
    this._header = header;
    this._replica = replica;
    this._sections = sections;
    this._advertising = advertising;
    this._editionrules = editionrules;
    this._editions = editions;
    this._subscriptions = subscriptions;
    this._feed = feed;
    this._custom = custom;
    this._locale = locale;
    this._masthead = masthead;
    if (typeof this._masthead === 'undefined') {
      this._masthead = <IAppMasthead>{
        left: {
          type: "text",
          value: ""
        },
        right: {
          type:"text",
          value: ""
        }
      };
    }

    this._portalsettings = portalsettings;
    this._tabs = tabs;
    this._settings = settings;
    this._helperscreens = helperscreens;
    this._categoryselector = categoryselector;
    this._datacapture = datacapture;

  }

  get datacapture(): any {
    return this._datacapture;
  }

  set datacapture(value: any) {
    this._datacapture = value;
  }

  get categoryselector(): any {
    return this._categoryselector;
  }

  set categoryselector(value: any) {
    this._categoryselector = value;
  }


  get portalsettings(): any {
    return this._portalsettings;
  }

  set portalsettings(value: any) {
    this._portalsettings = value;
  }

  get app(): IApp {
    return this._app;
  }

  set app(value: IApp) {
    this._app = value;
  }

  get archive(): any {
    return this._archive;
  }

  set archive(archive: any) {
    this._archive = archive;
  }

  get menu(): IAppMenu {
    return this._menu;
  }

  set menu(value: IAppMenu) {
    this._menu = value;
  }

  get fonts(): IAppFonts {
    return this._fonts;
  }

  set fonts(value: IAppFonts) {
    this._fonts = value;
  }

  get kiosk(): AppKiosk {
    return this._kiosk;
  }

  set kiosk(value: AppKiosk) {
    this._kiosk = value;
  }

  get footer(): IAppFooter {
    return this._footer;
  }

  set footer(value: IAppFooter) {
    this._footer = value;
  }

  get header(): IAppHeader {
    return this._header;
  }

  set header(value: IAppHeader) {
    this._header = value;
  }

  get replica(): IAppReplica {
    return this._replica;
  }

  set replica(value: IAppReplica) {
    this._replica = value;
  }

  get sections(): Array<IAppSection> {
    return this._sections;
  }

  set sections(value: Array<IAppSection>) {
    this._sections = value;
  }

  get feed(): Array<Flatplan> {
    return this._feed;
  }

  set feed(value: Array<Flatplan>) {
    this._feed = value;
  }

  get advertising(): IAppAdvertising {
    return this._advertising;
  }

  set advertising(value: IAppAdvertising) {
    this._advertising = value;
  }

  get editionrules(): IAppEditionRules {
    return this._editionrules;
  }

  set editionrules(value: IAppEditionRules) {
    this._editionrules = value;
  }

  get editions(): Array<Edition> {
    return this._editions;
  }

  set editions(value: Array<Edition>) {
    this._editions = value;
  }

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(value: any) {
    this._subscriptions = value;
  }

  get masthead(): IAppMasthead {
    return this._masthead;
  }

  set masthead(value: IAppMasthead) {
    console.log("SETTING MASTHEAD", value);
    this._masthead = value;
  }

  get locale(): any {
    return this._locale;
  }

  set locale(value: any) {
    this._locale = value;

  }

  get tabs(): any {
    return this._tabs;
  }

  set tabs(value: any) {
    this._tabs = value;
  }

  get settings(): any {
    return this._settings;
  }

  set settings(value: any) {
    this._settings = value;
  }

  get helperscreens(): Array<any> {
    return this._helperscreens;
  }

  set helperscreens(value: Array<any>) {
    this._helperscreens = value;
  }
}

export interface IApp {
  guid: string;
  name: string;
  snsarn: string;
  phonetype: string;
  shortname: string;
  splashurl: string;
  readerskin: string;
  tablettype: string;
  accountguid: string;
  backgroundColor: string;
  backgroundcolor: string;
  flatplanguid: string;
  applogo: string;
  platforms: Array<number>;
  openmode: string;
  apikey: string;
  sharedsecret: string;
  isurbanenabled: boolean;
  theme: Theme;
  pagesettings: PageSettings;
  readertype: string;
  replicatrackingname: string;
  feededitiontrackingname: string;
  showdownloadprompt: boolean;
  faviconurl: string;
  hidesidemenuimages: boolean;
  description: string;
  html5: any;
  overridesharebuttonhide: boolean;
  sharefootertext: string;
  usetopbarnav: boolean;
  usingzones: boolean;
  clonedchildren: any;
  archivesettings: any;
}


export interface IAppTheme {
  brandcolor: string;
  primarycolor: string;
  secondarycolor: string;
  darkfontsandicons: boolean;
  primaryfont: string;
  secondaryfont: string;
  theme: any;
}

export interface IAppItemGroupItem {
  text: string;
  type: string;
  value: string;
}
export interface IAppItemGroup {
  name: string;
  type: string;
  items: Array<IAppItemGroupItem>;
}

export interface IAppMenu {
  headertext: string;
  itemgroups: Array<IAppItemGroup>;
  archiveitemgroups: Array<IAppItemGroup>;
  replicaitemgroups: Array<IAppItemGroup>;
  highlightcolor: string;
  backgroundcolor: string;
}

export class IAppMediaFont {

  private _name: string;
  private _size: number;
  private _color: string;

  constructor(_name: string, size: number, color: string) {
    this._name = _name;
    this._size = size;
    this._color = color;
  }

  get size(): number {
    return this._size;
  }

  set size(value: number) {
    this._size = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }


}


export class IAppMediaFontObject {

  private _body: IAppMediaFont;
  private _headline: IAppMediaFont;
  private _author: IAppMediaFont;
  private _subheadline: IAppMediaFont;
  private _imagecaption: IAppMediaFont;

  constructor(body: IAppMediaFont, headline: IAppMediaFont, author: IAppMediaFont, subheadline: IAppMediaFont, imagecaption: IAppMediaFont) {
    this._body = body;
    this._headline = headline;
    this._author = author;
    this._subheadline = subheadline;
    this._imagecaption = imagecaption;
  }

  get body(): IAppMediaFont {
    return this._body;
  }

  set body(value: IAppMediaFont) {
    this._body = value;
  }

  get headline(): IAppMediaFont {
    return this._headline;
  }

  set headline(value: IAppMediaFont) {
    this._headline = value;
  }

  get author(): IAppMediaFont {
    return this._author;
  }

  set author(value: IAppMediaFont) {
    this._author = value;
  }

  get subheadline(): IAppMediaFont {
    return this._subheadline;
  }

  set subheadline(value: IAppMediaFont) {
    this._subheadline = value;
  }

  get imagecaption(): IAppMediaFont {
    return this._imagecaption;
  }

  set imagecaption(value: IAppMediaFont) {
    this._imagecaption = value;
  }




}
export class IAppArticleFont extends IAppMediaFontObject {
  constructor(body, headline, author, subheadline, imagecaption) {
    super(body, headline, author, subheadline, imagecaption);
  }
}
export class IAppSectionFrontFont extends IAppMediaFontObject {
  constructor(body, headline, author, subheadline, imagecaption) {
    super(body, headline, author, subheadline, imagecaption);
  }
}

export class IAppFonts {
  private _article: IAppArticleFont;
  private _sectionfront: IAppSectionFrontFont;
  constructor(article?: IAppArticleFont, sectionfront?: IAppSectionFrontFont) {
    this._article = article;
    this._sectionfront = sectionfront;
  }

  get article(): IAppArticleFont {
    return this._article;
  }

  set article(value: IAppArticleFont) {
    this._article = value;
  }

  get sectionfront(): IAppSectionFrontFont {
    return this._sectionfront;
  }

  set sectionfront(value: IAppSectionFrontFont) {
    this._sectionfront = value;
  }


}

export interface IFooterItem {
  text: string;
  type: string;
  value: string;
}

export interface IAppFooter {
  items: Array<IFooterItem>;
  buttoncolor: string;
  highlightcolor: string;
  backgroundcolor: string;
}

export interface IAppHeader {
  headerlogo: string;
  homeheight: string;
  buttoncolor: string;
  footercolor: string;
  naturalheight: string;
  backgroundcolor: string;
  hidemenu: boolean;
}

export interface IAppReplica {
  pubguid: string;
  hidefittowidthbuttonphone: boolean;
}

export interface IAppSection {
  name: string;
  feedguid: string;
  color: string;
  sectionguid: string;
}

export interface IAppAdvertising {
  adcode: string;
  fullpageint: number;
}

export interface IAppEditionRules {
  articleview: string;
  sectionfronts: boolean;
  placeholderimage: string;
  autolive: boolean;
  nameoverride: string;
}

export interface ISubscription {
  applesharedsecret: string;
  androidsharedsecret: string;
  subscriptionmechanisms: Array<ISubscriptionMechanism>;
  products: Array<Product>;
}

export interface IAppMasthead {
  left: {
    type: string;
    value: string;
  };
  right: {
    type: string;
    value: string;
  };
}

export interface ISubscriptionProduct {
  productguid: string;
  name: string;
  identifier: string;
}

export interface ISubscriptionMechanism {
  subscriptionmechanismguid: string;
  data: string;
}