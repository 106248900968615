import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { EditionService } from "src/app/services/edition.service";
import { ModalService } from "src/app/modal";
import { LoadingService } from "src/app/services/loading.service";
import { ToastService } from "src/app/services/toast.service";
import { Page } from "src/app/models/page";
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/User";

@Component({
  selector: "app-pdf-import",
  templateUrl: "./pdf-import.component.html",
  styleUrls: ["./pdf-import.component.css"],
})
export class PdfImportComponent implements OnInit {
  @Input("sectionName") sectionName: string;
  @Input("page") page: Page;
  @Input("isNew") isNew: boolean;
  @Output() pdfUploaded: EventEmitter<any> = new EventEmitter();

  appId: string;
  externalLink: string = "";
  presignedUrl: string = null;
  contentUrl: string = null;
  fileUploaded: boolean = false;
  isLoading: boolean = false;
  files: any = [];

  constructor(
    private editionService: EditionService,
    private modalService: ModalService,
    private authsrc: AuthService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.authsrc.getUser().subscribe((user: User) => {
      if (user.accountguid) {
        this.appId = user.accountguid;
        console.log("USER ID: ", user.accountguid);
      }
    });
    // to remove frame bordera
    if (!this.isNew) {
      this.contentUrl = this.page.contenturl + "#toolbar=0&navpanes=0&scrollbar=0";
      this.externalLink = this.page.hyperlinks[0];
    }
  }

  closeModal() {
    this.modalService.destroy();
  }

  uploadFile(event) {
    console.log(event);
    if (
      this.files.length > 0 &&
      event.length > 0 &&
      event[0].type === "application/pdf"
    ) {
      this.files = [];
    }
    if (event.length > 0) {
      this.getPresignedUrl(); // call to get presigned URL
      if (event[0].type === "application/pdf") {
        this.files.push(event[0]);
      } else {
        this.toastService.failed("Please choose a PDF file.");
      }
    }
  }

  getPresignedUrl() {
    this.fileUploaded = false; // clearing other uploaded file
    this.presignedUrl = null; // clearing previous presigned url
    this.contentUrl = null; //for resetting pdf preview

    this.editionService.getpresignedurls(this.appId).subscribe((res) => {
      if (res.hasOwnProperty("presignedUrl")) {
        this.presignedUrl = res.presignedUrl;
        this.contentUrl = res.s3Url + "#toolbar=0&navpanes=0&scrollbar=0";
        console.log(this.presignedUrl);
      }
    });
  }

  uploadPdfToS3() {
    this.isLoading = true;
    let newNameArray = this.contentUrl.split("/");
    const newName = newNameArray[newNameArray.length - 1];

    let fileObj = new File([this.files[0]], newName, {
      type: this.files[0].type,
    });

    const fileuploadurl = this.presignedUrl;
    this.editionService
      .uploadfileAWSS3(fileuploadurl, fileObj)
      .subscribe((event: any) => {
        console.log(event);
        this.fileUploaded = true;
        this.isLoading = false;
      });
  }

  deleteAttachment(index) {
    this.files = [];
  }

  savePdfPage() {
    if (this.isNew) {
      this.editionService.addPageToEdition(this.page);
      console.log(this.page);
    }
    this.page.hyperlinks = [];
    if(this.externalLink) {
      this.page.hyperlinks[0] = this.externalLink;
    } else {
      this.page.hyperlinks = [];
    }
    this.page.contenturl = this.contentUrl.replace('#toolbar=0&navpanes=0&scrollbar=0','');
    this.page.isEdited = true;
    this.editionService.iframeRefresh.next();
    this.modalService.destroy();
    this.editionService.editionHasChanged.next(true);
  }
}
