import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ExistingArticlesComponent } from '../../existing-articles/existing-articles.component';
import { EditArticleComponent } from '../../edit-article/edit-article.component';
import { ViewSpecialsComponent } from '../../view-specials/view-specials.component';
import {ModalService} from "../../../../../modal/services/modal.service";
import {EditionService} from "../../../../../services/edition.service";
import {PageType} from "../../../../../models/page";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'edit-article-nav',
  templateUrl: './edit-article-nav.component.html',
  styleUrls: ['./edit-article-nav.component.css']
})
export class EditArticleNavComponent implements OnInit {

  // @Input('isNewArticle') isNewArticle: boolean;

  @Input('sectionName') sectionName: string;
  @Input('pageguid') pageguid: string;

  @Input('isSectionFront') isSectionFront: boolean;
  @Input('isMultiArticle') isMultiArticle: boolean;
  @Input('multiArticleIndex') multiArticleIndex: number;

  @Output('newArticle') newArticle = new EventEmitter();
  @Output('existingArticle') existingArticle = new EventEmitter();

  @Input('option') option: number;

  isNew: boolean = false;
  isExist: boolean = false;
  isSpecial: boolean = false;

  constructor(private modalService: ModalService, private editionService: EditionService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.setActiveNav();
  }

  displayNewArticle() {
    let configs = {
      isNewArticle: true,

      pageguid: this.pageguid,
      sectionName: this.sectionName,
      isSectionFront: this.isSectionFront,
      isMultiArticle: this.isMultiArticle,
      multiArticleIndex: this.multiArticleIndex
    }
    this.modalService.init(EditArticleComponent, configs, {})
    //this.newArticle.emit();
  }

  displayExistingArticle() {
    if (this.sectionName) {
      this.modalService.init(ExistingArticlesComponent, {

        sectionName: this.sectionName,
        pageguid: this.pageguid,
        isSectionFront: this.isSectionFront,
        isMultiArticle: this.isMultiArticle,
        multiArticleIndex: this.multiArticleIndex
      }, {});
    }
    else {
      console.error('No section name found.')
    }
    //this.existingArticle.emit();
  }

  displaySpecials() {
    if (this.sectionName) {
      this.modalService.init(ViewSpecialsComponent, {

        sectionName: this.sectionName,
        pageguid: this.pageguid,
        isSectionFront: this.isSectionFront,
        isMultiArticle: this.isMultiArticle,
        multiArticleIndex: this.multiArticleIndex
      }, {});
    }
    else {
      console.error('No section name found.');
    }
  }

  private setActiveNav(): void {
    switch (this.option) {
      case 0:
        this.isNew = true;
        this.isExist = false;
        this.isSpecial = false;
        break;
      case 1:
        this.isExist = true;
        this.isNew = false;
        this.isSpecial = false;
        break;
      case 2:
        this.isSpecial = true;
        this.isNew = false;
        this.isExist = false;
        break;
    }
  }


  createNewArticle() {
    this.modalService.destroy();
    console.log(this.router);
    console.log(this.pageguid);
    if (typeof this.pageguid != 'undefined') {
      this.router.navigate([this.router.url + '/new'], {queryParams: {pageguid: this.pageguid, section: this.sectionName}});
    } else {
      this.router.navigate([this.router.url + '/new'], {queryParams: {section: this.sectionName}});
    }

  }

}
