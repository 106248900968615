export interface ScheduleManager {
    addSchedule(schedule?: Schedule);
    updateSchedule(i: number, sheculde: Schedule);
    deleteSchedule(i: number);
}

export enum ScheduleFrequency {
    HOURLY = "Hourly",
    DAILY = "Daily",
    WEEKLY = "Weekly",
    MONTHLY = "Monthly",
    NONE = "One-Off"
}

export class Schedule{

    private _name: string;
    private _applicationguid: string;
    private _flatplanguid: string;
    private _nextruntimeutc: string;
    private _prevruntimeutc: string;
    private _scheduleguid: string;
    private _scheduletype: ScheduleFrequency;


	constructor(name: string, applicationguid: string, flatplanguid: string, nextruntimeutc: string, prevruntimeutc: string, scheduleguid: string, scheduletype: ScheduleFrequency) {
        this._name = name;
        this._applicationguid = applicationguid;
		this._flatplanguid = flatplanguid;
		this._nextruntimeutc = nextruntimeutc;
		this._prevruntimeutc = prevruntimeutc;
		this._scheduleguid = scheduleguid;
		this._scheduletype = scheduletype;
	}


    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}


    /**
     * Getter applicationguid
     * @return {string}
     */
	public get applicationguid(): string {
		return this._applicationguid;
	}

    /**
     * Getter flatplanguid
     * @return {string}
     */
	public get flatplanguid(): string {
		return this._flatplanguid;
	}

    /**
     * Getter nextruntimeutc
     * @return {string}
     */
	public get nextruntimeutc(): string {
		return this._nextruntimeutc;
	}

    /**
     * Getter prevruntimeutc
     * @return {string}
     */
	public get prevruntimeutc(): string {
		return this._prevruntimeutc;
	}

    /**
     * Getter scheduleguid
     * @return {string}
     */
	public get scheduleguid(): string {
		return this._scheduleguid;
	}

    /**
     * Getter scheduletype
     * @return {ScheduleFrequency}
     */
	public get scheduletype(): ScheduleFrequency {
		return this._scheduletype;
	}

    /**
     * Setter applicationguid
     * @param {string} value
     */
	public set applicationguid(value: string) {
		this._applicationguid = value;
	}

    /**
     * Setter flatplanguid
     * @param {string} value
     */
	public set flatplanguid(value: string) {
		this._flatplanguid = value;
	}

    /**
     * Setter nextruntimeutc
     * @param {string} value
     */
	public set nextruntimeutc(value: string) {
		this._nextruntimeutc = value;
	}

    /**
     * Setter prevruntimeutc
     * @param {string} value
     */
	public set prevruntimeutc(value: string) {
		this._prevruntimeutc = value;
	}

    /**
     * Setter scheduleguid
     * @param {string} value
     */
	public set scheduleguid(value: string) {
		this._scheduleguid = value;
	}

    /**
     * Setter scheduletype
     * @param {ScheduleFrequency} value
     */
	public set scheduletype(value: ScheduleFrequency) {
		this._scheduletype = value;
	}


}

export class oldSchedule {

    private _name: string;
    private _id: string;
    private _appid: string;
    private _prevruntime: string;
    private _nextruntime: string;
    private _repeat: ScheduleFrequency;
    private _flatplanid: string;

    constructor(name: string, id: string, appid: string, prevruntime: string, nextruntime: string, repeat: ScheduleFrequency, flatplanid: string) {
        this._name = name;
        this._id = id;
        this._appid = appid;
        this._prevruntime = prevruntime;
        this._nextruntime = nextruntime;
        this._repeat = repeat;
        this._flatplanid = flatplanid;
    }

    /**
 * Getter name
 * @return {string}
 */
    public get name(): string {
        return this._name;
    }

    /**
     * Setter name
     * @param {string} value
     */
    public set name(value: string) {
        this._name = value;
    }

    /**
     * Getter id
     * @return {string}
     */
    public get id(): string {
        return this._id;
    }

    /**
     * Getter appid
     * @return {string}
     */
    public get appid(): string {
        return this._appid;
    }

    /**
     * Getter prevruntime
     * @return {string}
     */
    public get prevruntime(): string {
        return this._prevruntime;
    }

    /**
     * Getter nextruntime
     * @return {string}
     */
    public get nextruntime(): string {
        return this._nextruntime;
    }

    /**
     * Getter flatplanid
     * @return {string}
     */
    public get flatplanid(): string {
        return this._flatplanid;
    }

    /**
     * Setter id
     * @param {string} value
     */
    public set id(value: string) {
        this._id = value;
    }

    /**
     * Setter appid
     * @param {string} value
     */
    public set appid(value: string) {
        this._appid = value;
    }

    /**
     * Setter prevruntime
     * @param {string} value
     */
    public set prevruntime(value: string) {
        this._prevruntime = value;
    }

    /**
     * Setter nextruntime
     * @param {string} value
     */
    public set nextruntime(value: string) {
        this._nextruntime = value;
    }

    /**
     * Setter flatplanid
     * @param {string} value
     */
    public set flatplanid(value: string) {
        this._flatplanid = value;
    }

    /**
     * Getter repeat
     * @return {ScheduleFrequency}
     */
	public get repeat(): ScheduleFrequency {
		return this._repeat;
	}

    /**
     * Setter repeat
     * @param {ScheduleFrequency} value
     */
	public set repeat(value: ScheduleFrequency) {
		this._repeat = value;
	}



}
