import {Component, Input, OnInit} from '@angular/core';
import {Advert} from "../../../models/Advert";
import {AdvertType} from "../../../models/AdvertType";
import {FlatplanSection} from "../../../models/FlatplanSection";
import {ModalService} from "../../../modal/services/modal.service";
import {EditionService} from "../../../services/edition.service";
import {ApplicationsService} from "../../../services/applications.service";
import {FlatplanmanagerService} from "../../../services/flatplanmanager.service";

@Component({
  selector: 'app-flatplan-adverts',
  templateUrl: './flatplan-adverts.component.html',
  styleUrls: ['./flatplan-adverts.component.css']
})
export class FlatplanAdvertsComponent implements OnInit {

  @Input('flatplan') flatplan;
  @Input('currentSection') currentSection: FlatplanSection;

  activeAdType = "mpu";

  sectionAds: Advert;

  constructor(private modalService: ModalService, private flatplanService: FlatplanmanagerService) { }

  ngOnInit() {
    console.log(this.currentSection.adverts.mpu);
    let banner = new AdvertType(this.currentSection.adverts.banner.type, this.currentSection.adverts.banner.values.slice(0));
    let inter = new AdvertType(this.currentSection.adverts.interstitial.type, this.currentSection.adverts.interstitial.values.slice(0));
    let mpu = new AdvertType(this.currentSection.adverts.mpu.type, this.currentSection.adverts.mpu.values.slice(0));

    if (banner.type == "") {
      banner.type = 'GoogleDFP';
    }

    this.sectionAds = new Advert(banner, inter, mpu);

  }

  setActiveAdType(adType) {
    this.activeAdType = adType;
  }

  save() {
    console.log(this.sectionAds);
    this.currentSection.adverts = this.sectionAds;
    // this.flatplanService.setFlatplanSection(this.currentSection);
    this.modalService.destroy();

  }

}
