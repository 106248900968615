import {Component, Input, OnInit} from '@angular/core';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Feed} from '../../../models/Feed';
import {FeedsService} from '../../../services/feeds.service';
import {AuthService} from '../../../services/auth.service';
import {AppSection} from '../../../models/AppSection';
import {FunctionService} from '../../../services/function.service';
import {App} from '../../../models/App';
import {FlatplanmanagerService} from '../../../services/flatplanmanager.service';
import {Flatplan} from '../../../models/Flatplan';
import {FlatplanSection} from '../../../models/FlatplanSection';
import {Advert} from '../../../models/Advert';
import {AdvertType} from '../../../models/AdvertType';
import {ModalService} from '../../../modal';
import {FlatplanPage} from '../../../models/FlatplanPage';
import {LoadingService} from "../../../services/loading.service";
import {FeedTemplate} from '../../../models/FeedTemplate';
import {ApiService} from "../../../services/api.service";
import {reject} from 'q';
import {ApplicationsService} from '../../../services/applications.service';
import {Route, Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-addsection',
  templateUrl: './addsection.component.html',
  styleUrls: ['./addsection.component.css']
})
export class AddsectionComponent implements OnInit {

  @Input('scope') scope;
  @Input('flatplan') flatplan : Flatplan;
  @Input('flatplanSection') flatplanSection: FlatplanSection;
  @Input('flatplanSectionUpdate') flatplanSectionUpdate;

  public editSection = false;
  public mputype : AdvertType = new AdvertType("", [""]);
  public bannertype : AdvertType = new AdvertType("", [""]);
  public interadverttype : AdvertType = new AdvertType("", [""]);
  public advert : Advert = new Advert(this.bannertype, this.interadverttype, this.mputype);
  public pages : FlatplanPage[] = [];

  public globalFlatplan: Flatplan;
  public chosenTemplateString: string = '[]';

  public section: FlatplanSection = new FlatplanSection("", this.pages, "0,0,0", this.func.generateGUID(), this.advert, "", "", false);

  public feeds : Array<Feed>;
  public feedtemplates : Array<FeedTemplate>;

  constructor(private modalService: ModalService, private feedService: FeedsService,
              private flatplanservice : FlatplanmanagerService, private authService: AuthService,
              private func: FunctionService, private loadingService: LoadingService,
              private apiService: ApiService, private appService: ApplicationsService, private router: Router) { }


  ngOnInit() {
    this.getFeeds().then(() => {
      this.getSectionTemplates().then(() => {
        this.pageLoaded()
      });
    });
  }

  pageLoaded() {
    let me = this;

    /* If editing a section I have set a bool to check, also set the section being used, to the one passed through */
    if (this.flatplanSection != null) {
      this.editSection = true;
      // done like this so it is not referenced based as this would update automatically. this.flatplanSection will only get updated when you click edit
      this.section.name = this.flatplanSection.name;
      this.section.feedguid = this.flatplanSection.feedguid;
      this.section.color = this.flatplanSection.color;
      this.section.logourl = this.flatplanSection.logourl;
    }

    console.log(this.section);

    $(".blob").spectrum({
      color: 'rgb(' + me.section.color + ')',
      change: function(color) {
        me.section.color = color.toRgbString().replace("rgb(", "").replace(")", "").replace(" ", "").replace(" ", "");
      }
    });
  }

  getFeeds(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.feedService.loadFeedsPromise(this.authService.getUserAccountGuid()).then((feeds: Array<Feed>) => {
        this.feeds = feeds.sort((a,b) => a.name.localeCompare(b.name));
        if (this.feeds.length > 0) {
          console.log(this.feeds[0]);
          this.section.feedguid = this.feeds[0].feedguid;
        }
        resolve();
      }).catch((err) => {
        console.log(err);
      })
    });
  }

  getSectionTemplates(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.getFlatplan('sections').then(res => {
        this.globalFlatplan = res;
        resolve();
      });
    })
  }

  ngOnDestroy(): void {

  }

  getSectionPages(section: FlatplanSection): string {
    return JSON.stringify(section);
  }

  updateFlatplan() {
    if (!this.editSection) {
      this.addSection();
    } else {
      this.updateSection();
    }
    this.modalService.destroy();
  }

  addSection()
  {
    if(this.chosenTemplateString != '[]') {
      const chosenTempJson = JSON.parse(this.chosenTemplateString);
      this.section.pages = chosenTempJson.pages;
      this.section.isspecial = chosenTempJson.isspecial;

      if(chosenTempJson.feedguid == null) {
        this.section.feedguid = null;
      }
    } else {
      this.section.pages = [];
    }
    //this.section.name = this.feedService.getFeedFromFeedGuid(this.section.feedguid).name;
    this.flatplan.sections.push(this.section);
  }

  updateSection() {
    this.flatplanSection.name = this.section.name;
    this.flatplanSection.feedguid = this.section.feedguid;
    this.flatplanSection.color = this.section.color;
    this.flatplanSection.logourl = this.section.logourl;

    // call to update the route on the flatplan section page. The scope needs to be provided as its default scope is this view
    this.flatplanSectionUpdate(this.scope);
  }

  addHeaderLogo(event) {
    this.appService.uploadImage(event).then((url: string) => {
      this.section.logourl = url;
    }).catch(err => {
      console.log(err);
    });
  }
}
