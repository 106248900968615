import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { BreadcrumbService } from "./services/breadcrumb.service";
import { TrailComponent } from "./components/trail/trail.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";


@NgModule({
  declarations: [
    TrailComponent,
    BreadcrumbComponent
  ],
  providers: [
    BreadcrumbService
  ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [TrailComponent, BreadcrumbComponent]
})
export class BreadcrumbsModule {
  constructor() { }
}