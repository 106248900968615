import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from "../../../../modal/services/modal.service";
import {SectionFrontEditorComponent} from "../section-front-editor/section-front-editor.component";
import {EditionService} from "../../../../services/edition.service";
import {PageType} from "../../../../models/page";
import {ExistingArticlesComponent} from "../existing-articles/existing-articles.component";
import {PuzzlePageComponent} from "./puzzle-page/puzzle-page.component";
import {RefreshArticlesComponent} from "./refresh-articles/refresh-articles.component";
import { PdfImportComponent } from './pdf-import/pdf-import.component';
import {ApplicationsService} from "../../../../services/applications.service";
import {App} from "../../../../models/App";

@Component({
  selector: 'app-edition-page-type',
  templateUrl: './edition-page-type.component.html',
  styleUrls: ['./edition-page-type.component.css']
})
export class EditionPageTypeComponent implements OnInit {

  @Input('allowedPageTypes') allowedPageTypes: any;
  @Input('sectionName') sectionName: string;
  @Input('level') level: string;

  activeApp: App;

  constructor(private modalService: ModalService,
              private editionService: EditionService,
              private applicationService: ApplicationsService) { }

  ngOnInit() {
    this.activeApp = this.applicationService.getStaticAppState();
  }

  createSectionPage() {
    this.modalService.init(SectionFrontEditorComponent, {
      page: this.editionService.createNewPage(this.sectionName, PageType.TEMPLATESECTION, 'section'),
      isNew: true
    }, {});
  }

  createArticlePage() {
    this.modalService.init(ExistingArticlesComponent, {sectionName: this.sectionName, level: this.level}, {});
  }

  addPDFPage() {

    this.modalService.init(PdfImportComponent, {
      page: this.editionService.createNewPage(this.sectionName, PageType.PDF, 'pdf'),
      isNew: true
    }, {});
  }

  createInterPage() {
    const page = this.editionService.createNewPage(this.sectionName, PageType.INTERSTITIAL, this.level);
    this.editionService.addPageToEdition(page);
    this.modalService.destroy();
  }

  createPuzzlePage() {
    this.modalService.init(PuzzlePageComponent, {sectionName: this.sectionName, level: this.level}, {});
  }

  openRefreshedArticles() {
    const page = this.editionService.createNewPage(this.sectionName, PageType.TEMPLATEARTICLE, this.level);
    this.modalService.init(RefreshArticlesComponent, {page: page}, {});
  }

}
