import {Component, Input, OnInit} from '@angular/core';
import {EditionService} from "../../../../../services/edition.service";
import {PageType} from "../../../../../models/page";
import {ModalService} from "../../../../../modal/services/modal.service";

@Component({
  selector: 'app-puzzle-page',
  templateUrl: './puzzle-page.component.html',
  styleUrls: ['./puzzle-page.component.css']
})
export class PuzzlePageComponent implements OnInit {

  @Input('sectionName') sectionName: string;
  @Input('level') level: string;

  puzzleUrl: string;

  constructor(private editionService: EditionService, private modalService: ModalService) { }

  ngOnInit() {
  }

  addPuzzle() {
    let puzzle = this.editionService.createNewPage(this.sectionName, PageType.PUZZLE, this.level, null, this.puzzleUrl);
    this.editionService.addPageToEdition(puzzle);
    console.log(this.editionService.jsonedition);
    this.modalService.destroy();
  }

}
