import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public currentActiveRoute = '';
  public activeRoute = new Subject<string>();

  constructor(private router: Router) {
    this.updateActiveRoute(router.url);

    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(r => {
      this.updateActiveRoute(router.url);
    });
  }

  updateActiveRoute(route: string) {
    this.currentActiveRoute = route;
    this.activeRoute.next(this.currentActiveRoute);
  }
}
