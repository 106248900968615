import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  constructor() { }

  public subjLoading = new Subject();

  public setLoading() {
    this.subjLoading.next(true);
  }
  public setFinished() {
    this.subjLoading.next(false);
  }

}
