// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://mo3ijtj2nj.execute-api.eu-west-1.amazonaws.com/develop/",
  screenshotUrl: "https://sz7uv1gxv6.execute-api.eu-west-1.amazonaws.com/dev/",
  pushurl: "https://o5q5gwnz6e.execute-api.eu-west-1.amazonaws.com/dev/",
  timezones: "https://dev-feed-editions.s3-eu-west-1.amazonaws.com/timezones.json",
  replica2pdf: "https://px1p1grvd9.execute-api.eu-west-1.amazonaws.com/develop/",
  cachedUrl: "https://ep.dev.pagesuite.com/dev/",
  oldPortal: "https://live.portal.pagesuite.com/",
  replica2pdfv1: "",
  ncoAccountGuid: "04ec6974-c6b0-4cfb-85ad-8c3ff6234bda",
  oAuthDomain: "pagesuite-auth-dev.auth.eu-west-1.amazoncognito.com",
  userPoolId: 'eu-west-1_ONer1vxSj',
  userPoolWebClientId: '48d12v4ckkra15rvs2si0kp9ll',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
