import { Injectable } from '@angular/core';
import { Breadcrumb } from '../models/breadcrumb';
import { Observable, BehaviorSubject } from 'rxjs';
import { Params, ActivatedRoute, PRIMARY_OUTLET, ActivatedRouteSnapshot, Data, UrlSegment } from '@angular/router';
import {ApplicationsService} from "../../../services/applications.service";
import {EditionService} from "../../../services/edition.service";
import {FeedsService} from '../../../services/feeds.service';
import {FlatplanService} from '../../../services/flatplan.service';
import {Flatplan} from '../../../models/Flatplan';
import {App} from '../../../models/App';
import {AuthService} from '../../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();
  private prefixedBreadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();

  private _Breadcrumbs: BehaviorSubject<Array<Breadcrumb>> = new BehaviorSubject<Array<Breadcrumb>>([]);

  private appName: string;
  private editionName: string;
  private sectionName: string;
  private articleHeadline: string;

  public getBreadcrumbSource(asObservable?: boolean): Array<Breadcrumb> | Observable<Array<Breadcrumb>> {
    if (asObservable) {
      return this._Breadcrumbs.asObservable();
    }
    return this._Breadcrumbs.value;
  }

  constructor(private activatedRoute: ActivatedRoute,
              private appService: ApplicationsService,
              private authsrc: AuthService,
              private feedService: FeedsService,
              private flatplanService: FlatplanService) {
    //Retrieve cookie data for breadcrumbs
    if (localStorage.getItem('prefixedBreadcrumbs') != null) {
      this.prefixedBreadcrumbs = (JSON.parse(localStorage.getItem('prefixedBreadcrumbs')))
    }
  }

  //Store the breadcrumbs of the current route
  public setBreadcrumbRoute(breadcrumbs: Array<Breadcrumb>) {
    this.breadcrumbs = breadcrumbs;

    let allBreadcrumbs = this.prefixedBreadcrumbs.concat(this.breadcrumbs);

    this._Breadcrumbs.next(allBreadcrumbs);
  }

  public setBreadcrumbAsPrefix(breadcrumb: Breadcrumb) {
    if (this.isBreadcrumbUnique(breadcrumb)) {
      this.prefixedBreadcrumbs.push(breadcrumb);
    }

    localStorage.setItem('prefixedBreadcrumbs', JSON.stringify(this.prefixedBreadcrumbs));

    let allBreadcrumbs = this.prefixedBreadcrumbs.concat(this.breadcrumbs);
    this._Breadcrumbs.next(allBreadcrumbs);
  }

  private isBreadcrumbUnique(crumb: Breadcrumb): boolean {
    let isUnique: boolean = true;

    this.prefixedBreadcrumbs.forEach((bc: Breadcrumb) => {
      if (bc.url == crumb.url) {
        isUnique = false;
      }
    });

    return isUnique;
  }

  public hasParams(breadcrumb: Breadcrumb): Array<string | Params> {
    return Object.keys(breadcrumb.params).length ? [breadcrumb.url, breadcrumb.params] : [breadcrumb.url];
  }

  public generateBreadcrumbTrail(url: string) {
    const ROUTE_DATA_BREADCRUMB: string = "label",
      ROUTE_PARAM_BREADCRUMB: string = "",
      ROUTE_DATA_PREFIX_BREADCRUMB: string = "prefixLabel";

    let currentBreadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();
    // get the root of the current route
    let currentRoute: ActivatedRoute = this.activatedRoute.root;

    let routeIndex = 0;

    while (currentRoute.children.length > 0) {
      let childRoutes: ActivatedRoute[] = currentRoute.children, label: string = "";

      //Iterate each child route
      childRoutes.forEach((route: ActivatedRoute) => {
        currentRoute = route;
        //Verify this is the primary route
        // if (route.outlet !== PRIMARY_OUTLET) {
        //   return;
        // }

        //console.log(route.outlet);

        const x: ActivatedRouteSnapshot = route.snapshot,
          hasData: Data = (route.routeConfig && route.routeConfig.data),
          hasDynamicBreadcrumb: boolean = x.params.hasOwnProperty(ROUTE_PARAM_BREADCRUMB);

        if (hasData || hasDynamicBreadcrumb) {

          if (hasDynamicBreadcrumb) {
            //Precendence
            label = x.params[ROUTE_PARAM_BREADCRUMB];
          }
          else if (x.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
            label = x.data[ROUTE_DATA_BREADCRUMB];
          }

          // label == "{DYNAMIC}" ? label = this.getLabelFromSegment(x.url) : null;

          // let routeURL: string = this.getUrlFromRouteSnapshot(x);
          // url += `/${routeURL}`;

          //Cannot have parameters on a root route
          //routeURL.length ? null : x.params = {};
          url.length ? null : x.params = {};

          let value = "hello";

          url = x['_routerState']['url'].split('/').splice(0, x['_lastPathIndex'] + 2).join('/');

          //Create breadcrumb
          let breadcrumb: Breadcrumb = new Breadcrumb(label, url, x.params, value);

          switch (label) {
            case 'apps':
              breadcrumb.value = 'Apps'
              //url = '/apps';
              break;
            case 'APP':
              let applicationGuid = currentRoute.params['_value']['appid'];

              this.appService.getApplicationsPromise(this.authsrc.accountGUID()).then((res) => {
                let application = this.appService.getApplicationByGuid(applicationGuid);
                breadcrumb.value = application.app.name;
              });
              breadcrumb.value = '';
              breadcrumb.url += '/editions';
              break;
            case 'EDITION':
              breadcrumb.value = this.editionName;
              break;
            case 'EDITION_SECTION':
              breadcrumb.value = this.sectionName;
              break;
            case 'EDITION_SECTION_ARTICLE':
              breadcrumb.value = this.articleHeadline;
              break;
            case 'Feeds':
              //url = x['_routerState']['url'].substring(0, x['_routerState']['url'].lastIndexOf('/'));
              breadcrumb.value = 'Feeds';
              break;
            case 'FEED':
              let feedguid = currentRoute.params['_value']['feedid'];
              this.feedService.getFeedFromFeedGuidPromise(feedguid, this.authsrc.accountGUID()).then((feedobject) => {
                breadcrumb.value = feedobject.name;
              }).catch(err => {
                console.log(err);
              });
              break;
            case 'FLATPLAN':
              const flatplanGuid = currentRoute.params['_value']['flatplanid'];
              this.flatplanService.getFlatplan(flatplanGuid).then((flatplan: Flatplan) => {
                breadcrumb.value = flatplan.name;
              });
              breadcrumb.value = '';
              break;
            case 'FLATPLANSECTION':
              const flatplanSection = currentRoute.params['_value']['flatplansection'];
              breadcrumb.value = flatplanSection;
              break;
            default:
              url = '.';
              breadcrumb.value = label;
              break;
          }

          //Create breadcrumb
          //let breadcrumb: Breadcrumb = new Breadcrumb(label, url, x.params, value);
          // Add the breadcrumb as 'prefixed'. It will appear before all breadcrumbs
          if (x.data.hasOwnProperty(ROUTE_DATA_PREFIX_BREADCRUMB)) {
            this.setBreadcrumbAsPrefix(breadcrumb);
          }
          else if(label == 'Hidden') {

          }
          else {
            currentBreadcrumbs.push(breadcrumb);
          }

          routeIndex++;
        }
      });

      this.setBreadcrumbRoute(currentBreadcrumbs);
    }
  }

  setAppName(name: string) {
    this.appName = name;
    //this.generateBreadcrumbTrail("");
  }

  setEditionName(name: string) {
    this.editionName = name;
    //this.generateBreadcrumbTrail("");
  }

  setEditionSectionName(name: string) {
    this.sectionName = name;
    //this.generateBreadcrumbTrail("");
  }

  setArticleHeadline(headline: string) {
    this.articleHeadline = headline;
    //this.generateBreadcrumbTrail("");
  }

  private getLabelFromSegment(url: UrlSegment[]): string {
    return url[url.length - 1].path;
  }

  private getUrlFromRouteSnapshot(snap: ActivatedRouteSnapshot): string {
    return snap.url.map(segment => segment.path).join("/");
  }

}
