import {Injectable, Injector} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { environment } from "src/environments/environment";
import { retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoadingService } from "./services/loading.service";
import {AuthService} from "./services/auth.service";
import {AppInjector} from "./app.module";


/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  authService: AuthService;

  constructor(private loadingsrv: LoadingService, private router: Router) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = AppInjector.get(AuthService);
    if (req.url.indexOf(environment["url"]) != -1) {
      console.log(this.authService.cognitoUser)
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.cognitoUser['signInUserSession']['idToken']['jwtToken']}`
        },
        withCredentials: true
      });
    }

    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        console.log("interceptor: " + req.url, error.status);
        if (error.status === 0) {
          this.loadingsrv.setFinished();
        }
        return throwError(errorMessage);
      })
    );
  }
}
