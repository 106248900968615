import { Injectable } from "@angular/core";
import { Edition } from "../models/edition";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Article } from "../models/Article";
import { REST } from "../models/enums/api-end-points";
import { Section } from "../models/Section";
import { Subject, Observable, of } from "rxjs";

import { Page, PageType, TplType } from "../models/page";
import { FunctionService } from "./function.service";
import { PageArticle } from "../models/PageArticle";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class EditionService {
  public jsonedition: Edition;

  public activePageGuid: string;
  public activeSectionName: Subject<string> = new Subject();
  public activeArticleHeadline: Subject<string> = new Subject();
  public gotEdition: boolean;

  public updateSection = new Subject();
  public pageAdded = new Subject();

  public updateMiniSortable: Subject<any> = new Subject();

  public currentPlatformId: number;

  public changeEmitter: Subject<boolean> = new Subject<boolean>();

  public refreshEdition: Subject<any> = new Subject();

  public editionLoaded: Subject<any> = new Subject();

  public iframeRefresh: Subject<boolean> = new Subject<boolean>();

  public editionHasChanged: Subject<boolean> = new Subject<boolean>();

  public setupPoller: Subject<any> = new Subject();

  public setupjobsProcessed: Subject<any> = new Subject();

  changedArticleVisibility = [];

  pageLevelView = "section";

  jsonOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  pdfOptions = {
    headers: new HttpHeaders({}),
  };

  constructor(
    private http: HttpClient,
    private func: FunctionService,
    private apiService: ApiService
  ) {}

  reorderPages(pageGuid: string, newPosition: number) {
    let itemToMove, originalIndex, newIndex, sectionName;

    this.jsonedition.pages.forEach((page, index) => {
      if (page.pageguid == pageGuid) {
        itemToMove = page;
        originalIndex = index;
      }
    });

    sectionName = itemToMove.section;
    newIndex = newPosition + this.getFirstPageOfSection(sectionName) + 2;

    // this.jsonedition.pages.splice(originalIndex, 1);
    this.jsonedition.pages.splice(newIndex, 0, itemToMove);
  }

  deleteEdition(editionGuid: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          REST.DELETE_EDITION.toString().replace("{editionGuid}", editionGuid)
        )
        .toPromise()
        .then((res) => {
          console.log(res);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject(true);
        });
    });
  }

  createNewPage(sectionName: string,pageType: PageType,level: string,article?: Article,templateHtml?: string): Page {
    let articles = new Array<PageArticle>(),
      orderOf: number = 0,
      pages: Page[] = this.getPagesForSection(sectionName);

    console.log(pages);

    if (pages.length > 0) {
      if (pageType == PageType.TEMPLATESECTION || pageType == PageType.PDF) {
        orderOf = pages[0].orderof;
        console.log("order, " + orderOf);
        pages.forEach((page, index) => {
          if ((page.level == "section" || page.level == "pdf") && !page.toDelete) {
            console.log("section page");
            console.log(page);
            orderOf++;
          }
        });
      } else {
        orderOf = pages[pages.length - 1].orderof + 1;
      }
      console.log(orderOf);
    }
    else {

      let lastSectionWithContent = null;
      for(let section of this.jsonedition.data) {
          const sectionPages = this.getPagesForSection(section.name);
          if(sectionPages.length > 0) {
            lastSectionWithContent = sectionPages;
          }
          if(section.name == sectionName) {
            break;
          }
      }

      console.log(lastSectionWithContent);
      if(lastSectionWithContent != null) {
        orderOf = lastSectionWithContent[lastSectionWithContent.length - 1].orderof + 1;
      }


    }
    if (article) {
      articles.push(new PageArticle(sectionName, article.articleguid));
    }

    if (!templateHtml) {
      // templateHtml = "https://s3-eu-west-1.amazonaws.com/dev-ps-feed-edition-templates/82260c6a-360d-474d-bcea-b51a65ecf989/tablet/Article+3.html";
      templateHtml = this.findArticleHtml();
    }

    //TODO: Template type is hardcoded, change this when the user
    let page = new Page(
      pageType,
      orderOf,
      sectionName,
      articles,
      this.func.generateGUID(),
      TplType.HTML,
      templateHtml,
      [],
      false,
      level,
      "",
      false,
      true,
      {},
      []
    );

    return page;
  }

  findArticleHtml() {
    let html;
    this.jsonedition.pages.forEach((page) => {
      if (page.type == "templateArticle") {
        html = page.contenturl;
      }
    });

    return html;
  }

  async createNewSection(name: string, color: string) {
    console.log(this.getSection(name));
    console.log(name);

    if(!name) {
      return Promise.reject({error: {error: "A section must have a name"}});

    }
    if (this.getSection(name) === null) {
      return await this.apiService.createNewSection(
        name,
        color,
        this.jsonedition.editionGUID,
        this.jsonedition.data.length
      );
    } else {
      return Promise.reject({error: {error: "A section with this name already exists"}});
    }
  }

  async importNewSection(postObj: {}) {
    let duplicateSections: boolean;
    postObj['sections'].forEach(element => {
      let checkSection = this.getSection(element.name);
      if(checkSection !== null) {
        duplicateSections = true;
      }
    });

    if(duplicateSections) {
      return Promise.reject("Some of selected sections name already exist in edition.");
    }

    if (postObj['masterEditionGuid'] !== null && postObj['masterEditionGuid'] !== "" && postObj['sections'].length > 0) {
      return await this.apiService.importNewSection(
        postObj,
        this.jsonedition.editionGUID
      );
    } else {
      return Promise.reject("At-least one section selection is required.");
    }
  }

  getArticle(guid: string): Article {
    let matchedArticle: Article;
    this.jsonedition.data.forEach((section: Section) => {
      section.articles.forEach((article: Article, idx) => {
        if (article.articleguid == guid) {
          matchedArticle = article;
        }
      });
    });
    return matchedArticle;
  }

  getArticlesForSection(sectionName) {
    let section: Section = this.getSection(sectionName),
      articles: Article[];
    section.articles.forEach((article) => {
      let x = this.getArticle(article.articleguid);
      articles.push(x);
    });
    return articles;
  }

  putSectionIntoEdition(sectionPages, sectionName) {
    let firstPage = this.getFirstPageOfSection(sectionName);
    this.jsonedition.pages.splice(firstPage, sectionPages.length);
    for (let i = sectionPages.length - 1; i >= 0; i--) {
      let page = sectionPages[i];
      page.orderof = firstPage + i;
      this.jsonedition.pages.splice(firstPage, 0, page);
    }
  }

  incrementOrderOf(addedPage: Page) {
    // console.log(this.jsonedition.pages);
    this.jsonedition.pages.forEach((page: Page) => {
      if (page.orderof >= addedPage.orderof) {
        page.orderof = page.orderof + 1;
      }
    });
  }

  public resetPageCount(sections: Array<Section>): void {
    let pageIndex = 0;
    let newPageOrder = [];

    sections.forEach((s: Section) => {
      const pages = this.getPagesForSection(s.name);
      pages.forEach((page) => {
        page.orderof = pageIndex;
        newPageOrder.push(page);
        pageIndex++;
      });
    });

    this.jsonedition.pages = newPageOrder;
  }

  updatePageTplType(pageGuid: string, tplType: TplType): void {
    //Promise<any> {

    this.jsonedition.pages.forEach((pge: Page, i: number) => {
      if (pge.pageguid == pageGuid) {
        this.jsonedition.pages[i].templatetype = tplType;
      }
    });

    //return this.updatePages();
  }

  updatePageAdCode(adcode: string, sectionname: string, pageguid?: string) {
    // get rid of this
  }

  updatePageNavigateUrl(
    navigateurl: string,
    sectionname: string,
    pageguid?: string
  ) {
    if (pageguid) {
      this.jsonedition.pages.forEach((pge: Page, i: number) => {
        if (pge.pageguid == pageguid) {
          this.jsonedition.pages[i].contenturl = navigateurl;
          this.jsonedition.pages[i].type = PageType.PUZZLE.toString();
          console.log(this.jsonedition.pages[i]);
        }
      });
    } else {
      let temp: Page = this.createNewPage(sectionname, PageType.PUZZLE, "");
      temp.contenturl = navigateurl;
      this.addPageToEdition(temp);
      console.log(navigateurl, sectionname, this.jsonedition.pages);
    }
  }

  updatePages() {
    const body = this.func.recursivelyRemoveUnderscoreJSONKeys(
      this.jsonedition.pages
    );
    return this.http
      .post(
        REST.POST_PAGES + this.jsonedition.editionGUID,
        body,
        this.jsonOptions
      )
      .toPromise();
  }

  decrementOrderOf(removedPage: Page) {
    this.jsonedition.pages.forEach((page: Page) => {
      if (page.orderof > removedPage.orderof) {
        page.orderof = page.orderof - 1;
      }
    });
  }

  sortPagesAcsending() {
    this.jsonedition.pages.sort(function (a, b) {
      return a["orderof"] - b["orderof"];
    });
  }

  getFirstPageOfSection(sectionName: string): number {
    for (let i = 0; i < this.jsonedition.pages.length; i++) {
      if (this.jsonedition.pages[i].section == sectionName) {
        return i;
      }
    }
  }

  getFirstPageObjectOfSection(sectionName: string): Page {

    for (let i = 0; i < this.jsonedition.pages.length; i++) {
      if (this.jsonedition.pages[i].section == sectionName) {
        return this.jsonedition.pages[i];
      }
    }
  }

  getEdition(editionGUID: string): Promise<Edition> {
    this.currentPlatformId = 2;
    if (
      typeof this.jsonedition !== "undefined" &&
      this.jsonedition.editionGUID === editionGUID
    ) {
      return new Promise((resolve) => {
        this.editionLoaded.next();
        resolve(this.jsonedition);
      });
    } else {
      let promise: Promise<Edition> = this.apiService.getEdition(
        editionGUID,
        2
      );
      promise.then((res: Edition) => (this.jsonedition = res));
      this.editionLoaded.next();
      return promise;
    }
  }

  repullEdition(): Promise<Edition> {
    let promise: Promise<Edition> = this.apiService.getEdition(
      this.jsonedition.editionGUID,
      2
    );
    promise.then((res: Edition) => (this.jsonedition = res));
    return promise;
  }

  getEditionForPlatform(
    editionGUID: string,
    platformId: number
  ): Promise<Edition> {
    this.currentPlatformId = platformId;
    return new Promise((resolve) => {
      let promise: Promise<Edition> = this.apiService.getEdition(
        editionGUID,
        platformId
      );
      promise.then((res: Edition) => {
        this.jsonedition = res;
        this.editionLoaded.next();
        resolve(res);
      });
      return promise;
    });
  }

  returnSectionsFromEdition(edition: Edition): Section[] {
    let sections = new Array<Section>();
    console.log(sections);
    edition.data.forEach((section) => {
      if (section.name !== undefined) {
        sections.push(
          new Section(
            section["articles"],
            section["name"],
            section["sectionguid"],
            section["color"],
            section["logourl"],
            null
          )
        );
      }
    });
    return sections;
  }

  createNewEdition(jobguid: string) {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    var options = {
      headers: headers,
    };
    return new Promise((resolve, reject) => {
      this.http
        .post(REST.NEW_EDITION_JOB + jobguid, {}, options)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPageIndexFromGuid(pageGuid: string) {
    let found = 0;
    this.jsonedition.pages.forEach((page, index) => {
      if (page.pageguid == pageGuid) {
        found = index;
      }
    });
    return found;
  }

  addJob(job): Promise<any> {
    let body = job;

    return new Promise((resolve, reject) => {
      this.http
        .post(REST.ADD_JOB, body, this.jsonOptions)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPagesForSection(sectionName: string): Page[] {
    let thisSection = new Array<Page>();
    // console.log(this.jsonedition.pages);
    for (let i = 0; i < this.jsonedition.pages.length; i++) {
      if (this.jsonedition.pages[i].section === sectionName) {
        thisSection.push(this.jsonedition.pages[i]);
      }
    }
    return thisSection;
  }

  updateArticleInSection(updatedArticle: Article, sectionName) {
    //Null check
    if (this.jsonedition != null || typeof this.jsonedition != "undefined") {
      //Find specific article
      this.jsonedition.data.forEach((section, x) => {
        if (section.name == sectionName) {
          section.articles.forEach((article, y) => {
            if (article.articleguid == updatedArticle.articleguid) {
              this.jsonedition.data[x].articles[y] == updatedArticle;
              this.updateSection.next();
              return;
            }
          });
        }
      });
    } else {
      console.error("Json Edition does not exist yet!");
      return;
    }
  }

  addPageToEdition(newPage: Page) {
    //Update order first as it's based on the page given to it, otherwise you'll duplicate the order number
    console.log(this.jsonedition.pages);
    this.incrementOrderOf(newPage);
    this.jsonedition.pages.push(newPage);
    this.sortPagesAcsending();
    this.updateSection.next();
    // this.setArticleVisibility();
    // newPage.articles.forEach(article => {
    //   this.changedArticleVisibility.push({
    //     guid: article.articleguid,
    //     isvisible: true
    //   });
    // });
  }

  removePageFromEdition(page: Page) {
    this.decrementOrderOf(page);
    this.jsonedition.pages[
      this.jsonedition.pages.indexOf(page)
    ].toDelete = true;
    // this.setArticleVisibility();
  }

  setArticleVisibility() {
    this.jsonedition.data.forEach((section) => {
      section.articles.forEach((article) => {
        let articleExistsOnPage = false;
        this.jsonedition.pages.forEach((editionPage) => {
          editionPage.articles.forEach((pageArticle) => {
            if (
              pageArticle.articleguid == article.articleguid &&
              editionPage.toDelete
            ) {
              article.isvisible = false;
              this.changedArticleVisibility.push({
                guid: article.articleguid,
                isvisible: article.isvisible,
              });
            } else {
              article.isvisible = true;
            }
          });
        });
      });
    });

    console.log(this.jsonedition.data);
  }

  addArticleToSection(newArticle: Article, sectionName: string) {
    //Null check
    if (this.jsonedition != null || typeof this.jsonedition != "undefined") {
      //Find section
      this.jsonedition.data.forEach((section, idx) => {
        if (section.name === sectionName) {
          //section.articles.push(newArticle);
          this.jsonedition.data[idx].articles.push(newArticle);
          this.updateSection.next();
        }
      });
    } else {
      console.error("Json Edition does not exist yet!");
      return;
    }
  }

  getSection(sectionName: string): Section {
    let foundSection = null;
    this.jsonedition.data.forEach((section) => {
      if (section.name == sectionName) {
        foundSection = section;
      }
    });
    return foundSection;
  }

  postNewPages() {
    this.updateSectionOrder().then((res) => {
      console.log(res);
    });

    let pagesToSend = [];

    this.jsonedition.pages.forEach((page) => {
      let pageData = this.func.recursivelyRemoveUnderscoreJSONKeys(page);
      delete pageData.orderof;
      delete pageData.pageguid;
      const pageToPush = {
        pageguid: page.pageguid,
        orderof: page.orderof,
        data: pageData,
      };
      pagesToSend.push(pageToPush);
    });

    let postObj = {
      pages: pagesToSend,
      articlesToDelete: this.changedArticleVisibility,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(
          REST.UPDATE_PAGE_ORDER.toString()
            .replace("{editionGuid}", this.jsonedition.editionGUID)
            .replace("{platformid}", this.currentPlatformId.toString()),
          postObj,
          this.jsonOptions
        )
        .toPromise()
        .then((res) => {
          console.log(res);
          for (let i = this.jsonedition.pages.length; i--; ) {
            if (this.jsonedition.pages[i].toDelete) {
              this.jsonedition.pages.splice(i, 1);
            }
          }
          this.changedArticleVisibility.length = 0;
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  postReorderedSections() {
    let sectionOrder = [];
    this.jsonedition.data.forEach((sect) => sectionOrder.push(sect.name));

    return this.http
      .post(
        REST.UPDATE_SECTION_ORDER.toString().replace(
          "{editionGuid}",
          this.jsonedition.editionGUID
        ),
        sectionOrder,
        this.jsonOptions
      )
      .toPromise();
  }

  addArticleToPage(pageguid: string, articleguid: string, index?: number) {
    this.jsonedition.pages.forEach((page, i) => {
      if (page.pageguid == pageguid) {
        //Single article page
        let x = this.jsonedition.pages[i].articles.length;
        if (x <= 1) {
          this.jsonedition.pages[i].articles.push(
            new PageArticle(page.section, articleguid)
          ); //"812fa121-1bbc-be10-457d-3979515c8ce5";
        } else if (x > 1) {
          console.log("This page has multiple articles.");
          this.jsonedition.pages[i].articles[index] = new PageArticle(
            page.section,
            articleguid
          );
        }
        this.updateSection.next();
      }
    });
  }

  setEditionLive(isLive: boolean) {
    let postData = {
      isLive: isLive,
    };

    return new Promise((resolve, reject) => {
      this.http
        .get(
          REST.SET_LIVE.toString()
            .replace("{editionGuid}", this.jsonedition.editionGUID)
            .replace("{isLive}", isLive.toString())
        )
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }

  updateEdition(name: string, articleMode: string) {
    let postData = {
      flow: this.jsonedition.flow,
      name: name,
      image: "",
      pages: [],
      islive: this.jsonedition.name,
      pubdate: this.jsonedition.pubDateTime,
      datemode: this.jsonedition.datemode,
      sections: [],
      articlemode: articleMode,
      editionguid: this.jsonedition.editionGUID,
      lastprocessed: this.jsonedition.lastprocessed,
      applicationguid: this.jsonedition.applicationguid,
      replicajobid: this.jsonedition.replicajobid,
      finishedprocessing: true,
    };

    return this.http
      .put(
        REST.SAVE_EDITION.toString().replace(
          "{editionGuid}",
          this.jsonedition.editionGUID
        ),
        postData
      )
      .toPromise();
  }

  saveEdition(editionBody: Edition) {
    let edition = this.func.recursivelyRemoveUnderscoreJSONKeys(editionBody);
    edition = this.func.recursivelyLowercaseJSONKeys(edition);
    edition.pages = [];
    delete edition.data;
    console.log(edition);
    return this.http
      .put(
        REST.SAVE_EDITION.toString().replace(
          "{editionGuid}",
          this.jsonedition.editionGUID
        ),
        edition
      )
      .toPromise();
  }

  setEditionPDFLiveStatus(jsonBody: any, editionGUID: string) {
    console.log(jsonBody, editionGUID, REST.PATCH_EDITION_PDF);
    const postObj = jsonBody;
    return this.http
      .patch(
        REST.PATCH_EDITION_PDF.replace("{editionGuid}", editionGUID),
        postObj
      )
      .toPromise();
  }

  updateSectionOrder() {
    let promises = [];

    this.jsonedition.data.forEach((section, index) => {
      const postObj = {
        orderof: index,
        editionguid: this.jsonedition.editionGUID,
        section: {
          name: section.name,
          color: section.color,
          adverts: section.adverts,
          logourl: section.logourl,
        },
      };
      promises.push(
        this.http
          .put(
            REST.UPDATE_SECTION_ORDER.toString().replace(
              "{sectionGuid}",
              section.sectionguid
            ),
            postObj
          )
          .toPromise()
      );
    });

    return Promise.all(promises);
  }

  getPreviousArticle(articleguid: string) {
    const articleGuids = this.getListOfArticleGuids();
    for (let i = 0; i < articleGuids.length; i++) {
      if (articleGuids[i] == articleguid) {
        if (articleGuids[i - 1]) {
          return articleGuids[i - 1];
        } else {
          return null;
        }
      }
    }
  }

  getNextArticle(articleguid: string) {
    const articleGuids = this.getListOfArticleGuids();
    for (let i = 0; i < articleGuids.length; i++) {
      if (articleGuids[i] == articleguid) {
        if (articleGuids[i + 1]) {
          return articleGuids[i + 1];
        } else {
          return null;
        }
      }
    }
  }

  getListOfArticleGuids() {
    let articleGuids = [];
    for (let i = 0; i < this.jsonedition.data.length; i++) {
      for (let j = 0; j < this.jsonedition.data[i].articles.length; j++) {
        articleGuids.push(this.jsonedition.data[i].articles[j].articleguid);
      }
    }
    return articleGuids;
  }

  async deleteArticle(articleGuid) {
    // check if the article exists in the edition, if it does you aren't allowed to delete it
    let existsOnPage = false;
    this.jsonedition.pages.forEach((page) => {
      page.articles.forEach((article) => {
        if (article.articleguid == articleGuid && !page.toDelete) {
          existsOnPage = true;
        }
      });
    });

    if (existsOnPage) {
      return false;
    }
    try {
      let sectionIndex;
      let sectionArticleIndex;
      this.jsonedition.data.forEach((section, sIndex) => {
        section.articles.forEach((article, aIndex) => {
          if (article.articleguid == articleGuid) {
            sectionIndex = sIndex;
            sectionArticleIndex = aIndex;
          }
        });
      });

      this.jsonedition.data[sectionIndex].articles.splice(
        sectionArticleIndex,
        1
      );
      const del = await this.http
        .delete(
          REST.DELETE_ARTICLE.toString().replace("{articleGuid}", articleGuid)
        )
        .toPromise();
      const save = await this.postNewPages();
      console.log(del);
      console.log(save);
      return true;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  getSectionNameFromGuid(guid: string): string {
    let sectionName;
    this.jsonedition.data.forEach((section) => {
      if (section.sectionguid == guid) {
        sectionName = section.name;
      }
    });

    console.log(sectionName);

    return sectionName;
  }


  async getReplicaProgress(jobId: string, version: number) {

    let url = REST.EDITION_REPLICA_PROGRESS;

    if(version === 1) {
       url = REST.EDITION_REPLICA_PROGRESS_V1;
    }
    return await this.http
      .get(
        url.toString().replace("{jobID}", jobId),
        this.jsonOptions
      )
      .toPromise();
  }

  async generateReplicas(postBody: any, editionGuid: string, version: number): Promise<[]> {
    const body = {
      templateEditionGuid: editionGuid,
      replicaPublications: postBody,
    };

    let url = REST.GENERATE_REPLICA;

    if(version === 1) {
       url = REST.GENERATE_REPLICA_V1;
    }

    return (await this.http
      .post(url, body, this.jsonOptions)
      .toPromise()) as [];
  }

  async get(url): Promise<any> {
    return await this.http.get(url, { responseType: "text" }).toPromise();
  }

  async generateScreenshotsForReplica(editionGuid): Promise<any> {
    return await this.http
      .get(
        REST.SCREENSHOT_FOR_PDF.toString().replace(
          "{editionGuid}",
          editionGuid
        ),
        this.jsonOptions
      )
      .toPromise();
  }

  getpresignedurls(appGuid: string): Observable<any> {
    return this.http.get<any>(REST.S3_PRESIGNED_URL.replace("{accountGuid}", appGuid), this.jsonOptions);
  }

  uploadfileAWSS3(fileuploadurl, file): Observable<any> {
    //this will be used to upload all csv files to AWS S3
    return this.http.put<any>(fileuploadurl, file, this.pdfOptions);
  }

  getSectionFromEdition(editionId: string): Observable<any> {
    return this.http.get<any>(REST.GET_SECTION.replace("{editionGuid}", editionId), this.jsonOptions);
  }
}
