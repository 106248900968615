export class User{
    
    private _accountguid: string;
    private _emailaddress: string;
    private _firstname: string;
    private _islive: boolean;
    private _lastname: string;
    private _locale: string;
    private _userguid: string;
    private _accesslevel: string;
  private _data: object;

	constructor(accountguid: string, emailaddress: string, firstname: string, islive: boolean, lastname: string, locale: string, userguid: string, accesslevel: string, data: object) {
		this._accountguid = accountguid;
		this._emailaddress = emailaddress;
		this._firstname = firstname;
		this._islive = islive;
		this._lastname = lastname;
		this._locale = locale;
		this._userguid = userguid;
		this._accesslevel = accesslevel;
		this._data = data;
    }
    
    /**
     * Getter accountguid
     * @return {string}
     */
	public get accountguid(): string {
		return this._accountguid;
	}

  public get data(): object {
    return this._data;
  }

    /**
     * Getter emailaddress
     * @return {string}
     */
	public get emailaddress(): string {
		return this._emailaddress;
	}

    /**
     * Getter firstname
     * @return {string}
     */
	public get firstname(): string {
		return this._firstname;
	}

    /**
     * Getter islive
     * @return {boolean}
     */
	public get islive(): boolean {
		return this._islive;
	}

    /**
     * Getter lastname
     * @return {string}
     */
	public get lastname(): string {
		return this._lastname;
	}

    /**
     * Getter locale
     * @return {string}
     */
	public get locale(): string {
		return this._locale;
	}

    /**
     * Getter userguid
     * @return {string}
     */
	public get userguid(): string {
		return this._userguid;
	}

    /**
     * Setter accountguid
     * @param {string} value
     */
	public set accountguid(value: string) {
		this._accountguid = value;
	}

    /**
     * Setter emailaddress
     * @param {string} value
     */
	public set emailaddress(value: string) {
		this._emailaddress = value;
	}

    /**
     * Setter firstname
     * @param {string} value
     */
	public set firstname(value: string) {
		this._firstname = value;
	}

    /**
     * Setter islive
     * @param {boolean} value
     */
	public set islive(value: boolean) {
		this._islive = value;
	}

    /**
     * Setter lastname
     * @param {string} value
     */
	public set lastname(value: string) {
		this._lastname = value;
	}

    /**
     * Setter locale
     * @param {string} value
     */
	public set locale(value: string) {
		this._locale = value;
	}

    /**
     * Setter userguid
     * @param {string} value
     */
	public set userguid(value: string) {
		this._userguid = value;
	}

  get accesslevel(): string {
    return this._accesslevel;
  }

  set accesslevel(value: string) {
    this._accesslevel = value;
  }
}
