import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {App} from '../models/App';
import {Flatplan} from '../models/Flatplan';
import {ApiService} from './api.service';
import {FlatplanSection} from '../models/FlatplanSection';
import {FlatplanPage} from '../models/FlatplanPage';

@Injectable({
  providedIn: 'root'
})
export class FlatplanmanagerService {

  constructor(private apiService: ApiService) { }


  private _flatplan: BehaviorSubject<Flatplan> = new BehaviorSubject<any>(Flatplan);
  private _flatplansection: BehaviorSubject<FlatplanSection> = new BehaviorSubject<any>(FlatplanSection);
  private _flatplanpage: BehaviorSubject<FlatplanPage> = new BehaviorSubject<any>(FlatplanPage);

  public flatplan(): Observable<Flatplan> {
    return this._flatplan.asObservable();
  }

  public flatplansection(): Observable<FlatplanSection> {
    return this._flatplansection.asObservable();
  }

  public flatplanpage(): Observable<FlatplanPage> {
    return this._flatplanpage.asObservable();
  }

  public getFlatplan(): Observable<Flatplan>  {
    return this._flatplan.asObservable();
  }
  public setFlatplan(fp: Flatplan) {
    this._flatplan.next(fp);
  }


  public getFlatplanSection(): Observable<FlatplanSection>  {
      return this._flatplansection.asObservable();
  }

  public setFlatplanSection(fps: FlatplanSection) {
    this._flatplansection.next(fps);
  }

  public getFlatplanPage(): Observable<FlatplanPage>  {
    return this._flatplanpage.asObservable();
  }

  public setFlatplanPage(fps: FlatplanPage) {
    this._flatplanpage.next(fps);
  }









}
