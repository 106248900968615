export class Mapping {
    private _id: string;
    private _typeof: string;

    constructor(id: string, typeOf: string) {
        this._id = id;
        this._typeof = typeOf;
    }

    /**
     * Getter id
     * @return {string}
     */
    public get id(): string {
        return this._id;
    }

    /**
     * Getter typeof
     * @return {string}
     */
    public get typeof(): string {
        return this._typeof;
    }

    /**
     * Setter id
     * @param {string} value
     */
    public set id(value: string) {
        this._id = value;
    }

    /**
     * Setter typeof
     * @param {string} value
     */
    public set typeof(value: string) {
        this._typeof = value;
    }

}