import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import {Template, TemplateType} from "../../../../../models/Template";
import {ApiService} from "../../../../../services/api.service";
import {LoadingService} from "../../../../../services/loading.service";
import {EditionService} from "../../../../../services/edition.service";
import {AuthService} from "../../../../../services/auth.service";
import {TemplateData} from '../../../../../models/TemplateData';
import {TemplateService} from '../../../../../services/template.service';

@Component({
  selector: 'template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent implements OnInit, AfterViewInit {

  @Input('templateHtml') html: string;
  @Input('maxHeight') public maxHeight: number;
  @Input('default') default: Template;
  @Input('type') type: string;
  @Input('sectionorarticle') sectionorarticle: string;
  @Output() setTemplate: EventEmitter<any> = new EventEmitter();

  public tplsChoice: string;

  protected navTotal: number = 0;
  protected templates;
  //protected accTpls: Template[] = new Array();
  //protected globalTpls: Template[] = new Array();

  protected selectedTemplate: Template;
  protected navTemplateType: TemplateType;
  public hasPageLoaded = false;

  public showGlobalTemplates: boolean;



  protected templateList = [
    { type: TemplateType.FRONT, desc: "Front Covers" },
    { type: TemplateType.ARTICLE, desc: "Article" },
    { type: TemplateType.FRONTADS, desc: "Front Covers (with Ads)" },
    { type: TemplateType.SECTION, desc: "Section Covers" },
    { type: TemplateType.SECTIONADS, desc: "Section Covers (with Ads)" }
  ]

  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private templateService: TemplateService) { }

  ngOnInit(): void {
    this.navTotal = 100 / this.templateList.length;
    this.loader.setLoading();

    this.tplsChoice = 'global';

    //TODO: THIS IS FOR DALLAS
    this.tplsChoice = 'account';
    this.showGlobalTemplates = true;

    this.setupTemplates().then(() => {
      this.pageLoaded();
    });
  }


  private setupTemplates(): Promise<Object> {
    return new Promise<Object>((resolve, reject) => {
      this.templateService.getAllTemplatesForAccount().then((templates) => {
        this.templates = templates;
        resolve();
      });
    });
  }

  private pageLoaded() {
    this.initVars();
    this.setDefault();
    this.hasPageLoaded = true;
    this.loader.setFinished();
  }

  initVars(): void {
    if (this.html == "") {
      this.navTemplateType = this.templateList[0]['type'];
    }
    this.selectedTemplate = new Template("", <TemplateData>{}, "");
  }

  setDefault() {
    if (this.templates) {
      this.templates.account.forEach((template: Template) => {
        if (this.html === template.data.html) {
          this.setNavTemplateType(template.data.type);
          this.emitTemplate(template);
        }
      });
    }
  }

  setNavTemplateType(TemplateType): void {
    this.navTemplateType = TemplateType;
  };

  emitTemplate(template: Template): void {
    this.selectedTemplate = template;
    this.setTemplate.emit(template);
  }

  validatetemplate(template: Template) : Boolean
  {
    if (this.sectionorarticle == undefined  || this.sectionorarticle == '')
    {
      return true;
    }
    if (this.sectionorarticle.toString() === 'articles' && template.data.type.toString() === 'article')
    {
      if (this.type === 'tablet' && template.data.platform === 2)
      {
        return true;
      }
      if (this.type === 'phone' && template.data.platform === 3)
      {
        return true;
      }
      if (this.type === 'desktop') {
        if (template.data.platform === 4) {
          return true;
        } else {
          return false;
        }
      }
      if (template.data.platform === 1)
      {
        return true;
      }
      return false;
    }

    if (this.sectionorarticle === 'sections' && template.data.type === 'frontcover')
    {
      if (this.type === 'tablet' && template.data.platform === 2)
      {
        return true;
      }
      if (this.type === 'desktop' && template.data.platform === 2) /*|| template.data.platform === 4*/ {
        return true;
      }
      if (this.type === 'phone' && template.data.platform === 3)
      {
        return true;
      }
      if (template.data.platform === 1)
      {
        return true;
      }
      return false;
    }

  }

  public tplSwitch(display: string) {
    this.tplsChoice = display;
  }

  ngAfterViewInit(): void {
    if (this.default) {
      this.selectedTemplate = this.default;
    }
  }
}
