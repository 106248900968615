import {Component, OnDestroy, OnInit} from '@angular/core';
import {Flatplan} from "../../../../models/Flatplan";
import {App} from "../../../../models/App";
import {untilComponentDestroyed} from "@w11k/ngx-componentdestroyed";
import {ApplicationsService} from "../../../../services/applications.service";
import {LoadingService} from "../../../../services/loading.service";
import {ApiService} from "../../../../services/api.service";
import {EditionService} from "../../../../services/edition.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalService} from "../../../../modal/services/modal.service";
import {Location} from "@angular/common";
import * as moment from "moment";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-add-edition-from-feed-modal',
  templateUrl: './add-edition-from-feed-modal.component.html',
  styleUrls: ['./add-edition-from-feed-modal.component.css']
})
export class AddEditionFromFeedModalComponent implements OnInit, OnDestroy {

  app: App;
  flatPlans: Flatplan[];
  flatplan: string;
  showCompleted = false;
  hasError = false;

  activeApp: App;

  date: any;
  time: any;
  editionDate: any;

  constructor(private appService: ApplicationsService, private loadingService: LoadingService, private api: ApiService,
              private editionService: EditionService, private modalService: ModalService, private urlLocation: Location) { }

  ngOnInit() {

    this.activeApp = this.appService.getStaticAppState();

    this.appService.getActiveApplication().pipe(untilComponentDestroyed(this)).subscribe((app: App) => {
      this.app = app;
      this.loadingService.setFinished();
      this.api.getFlatplans(app.app.guid).then((flats: Array<Flatplan>) => {
        this.flatPlans = flats;
      });
    });


    this.editionDate = moment().tz('Europe/London');

    if(this.activeApp.locale && this.activeApp.locale['timeZoneIdentifier']) {
      this.editionDate = moment().tz(this.activeApp.locale['timeZoneIdentifier']);
    }
    this.date = new FormControl(this.editionDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'));

    this.time = this.editionDate.format('HH:mm');
  }

  ngOnDestroy() {

  }

  createNewEdition() {
    let newDate = moment(this.date.value);
    console.log('local time: ', this.time);

    this.editionDate.set('year', newDate.year());
    this.editionDate.set('month', newDate.month());
    this.editionDate.set('date', newDate.date());
    this.editionDate.set('hour', parseInt(this.time.split(':')[0]));
    this.editionDate.set('minute', parseInt(this.time.split(':')[1]));

    console.log(this.editionDate.toISOString());

    if(this.flatplan) {
      this.loadingService.setLoading();
      let job = {
        "accountGUID": this.appService.getStaticAppState().app.accountguid,
        "applicationGUID": this.appService.getStaticAppState().app.guid,
        "flatplanGUID": this.flatplan,
        "publishDateTime": this.editionDate.toISOString()
      };
      this.editionService.addJob(job)
        .then(res => this.editionService.createNewEdition(res['guid']))
        .then((edRes) => {
          this.loadingService.setFinished();
          this.showCompleted = true;
          // this.modalService.destroy();
          console.log(edRes);
          setTimeout(() => {
            this.urlLocation.back();
            this.modalService.destroy();
        }, 6000);
      });
    } else {
      this.hasError = true;
    }
  }

}
