import {Component, OnInit, Input, OnChanges} from '@angular/core';
import { MessageDialogService } from '../../services/message-dialog.service';
import { Prompt } from '../../models/Prompt';

@Component({
  selector: 'Message-Dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit, OnChanges {

  @Input("message") message: Prompt;

  public positiveText: string;
  public negativeText: string;
  public showPositive: boolean = true;
  public showNegative: boolean = false;

  public numInputVal: string = "";

  constructor(private messageService: MessageDialogService) { }

  ngOnInit() {
    this.initialiseMessageDialog();
  }

  ngOnChanges(): void {
    this.initialiseMessageDialog();
  }

  public initialiseMessageDialog() {
    switch (this.message.type) {
      case "yes-no":
        this.positiveText = "Yes";
        this.negativeText = "No";
        this.showNegative = true;
        break;
      case "ok":
        this.showNegative = false;
        this.positiveText = "Ok";
        break;
      case "ok-cancel":
      default:
        this.positiveText = "Ok";
        this.negativeText = "Cancel";
        this.showNegative = true;
        break;
    }
  }

  public hide(): void {
    this.messageService.removeDialog();
  }

  public positiveAction(): void{
    if(this.message.positiveCallback != null && typeof this.message.positiveCallback === "function"){
      this.message.positiveCallback();
    }else{
      this.hide();
    }
  }
  
  public negativeAction(): void{
    if(this.message.negativeCallback != null && typeof this.message.negativeCallback === "function"){
      this.message.negativeCallback();
    }else{
      this.hide();
    }
  }
  

}
