import { Component, OnInit } from '@angular/core';
import {ApplicationsService} from "../../../../services/applications.service";
import {App} from "../../../../models/App";
import {Edition} from "../../../../models/edition";
import {EditionService} from "../../../../services/edition.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../../../services/loading.service";
import {ModalService} from "../../../../modal/services/modal.service";
import {ToastService} from "../../../../services/toast.service";
import {FormControl} from "@angular/forms";
import * as moment from 'moment';

@Component({
  selector: 'app-edit-edition-properties',
  templateUrl: './edit-edition-properties.component.html',
  styleUrls: ['./edit-edition-properties.component.css']
})
export class EditEditionPropertiesComponent implements OnInit {

  activeApp: App;
  edition: Edition;

  editionName: string;
  editionDate: any;
  platformId = 2;

  date: any;
  time: any;

  constructor(private appService: ApplicationsService,
              private editionService: EditionService,
              private loadingService: LoadingService,
              private modalService: ModalService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.platformId = this.editionService.currentPlatformId;
    this.activeApp = this.appService.getStaticAppState();
    this.edition = this.editionService.jsonedition;
    this.editionName = this.edition.name;

    this.editionDate = moment(this.edition.pubdate).tz('Europe/London');

    if(this.activeApp.locale && this.activeApp.locale['timeZoneIdentifier']) {
      this.editionDate = moment(this.edition.pubdate).tz(this.activeApp.locale['timeZoneIdentifier']);
    }
    this.date = new FormControl(this.editionDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'));

    this.time = this.editionDate.format('HH:mm');

  }

  saveEdition() {
    let newDate = moment(this.date.value);
    console.log('local time: ', this.time);

    this.editionDate.set('year', newDate.year());
    this.editionDate.set('month', newDate.month());
    this.editionDate.set('date', newDate.date());
    this.editionDate.set('hour', parseInt(this.time.split(':')[0]));
    this.editionDate.set('minute', parseInt(this.time.split(':')[1]));
    console.log('iso time: ', this.editionDate.toISOString());



    this.edition.pubdate = this.editionDate.toISOString();
    this.edition.pubDateTime = this.editionDate.toISOString();
    this.edition.name = this.editionName;


    this.loadingService.setLoading();

    try {
      this.editionService.saveEdition(this.edition);
      this.loadingService.setFinished();
      this.toastService.success("Edition Updated");
      this.modalService.destroy();
    } catch (e) {
      this.loadingService.setFinished();
      this.toastService.failed("Edition Did not update");
      this.modalService.destroy();
    }

  }


  getTimezone() {
    if(this.activeApp.locale && this.activeApp.locale['timeZoneIdentifier']) {
      return this.activeApp.locale['timeZoneIdentifier'];
    }
    return "Europe/London";
  }

}
