import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Prompt } from '../models/Prompt';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  public showDialog: Subject<Object> = new Subject<Object>();
  public hideDialog: Subject<Object> = new Subject<Object>();
  public updateDialogEvent: Subject<Object> = new Subject<Object>();
  private messageShown: boolean = false;

  constructor() {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        e.preventDefault();
        this.removeDialog();
      }
    });
  }

  public createDialog(message: Prompt) {
    this.showDialog.next(message);
    this.messageShown = true;
  }

  public removeDialog() {
    this.hideDialog.next();
    this.messageShown = false;
  }

  /* If another prompt is required during callback phas, use updateDialog as there have been
   problems getting the type to update in time. ngOnInit will not fire again and update the type */
  public updateDialog(message: Prompt) {
    this.updateDialogEvent.next(message);
  }

  public isMessageShown(): boolean {
    return this.messageShown;
  }
}
