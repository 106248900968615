import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("Checking auth");
    if (await this.authService.isAuthenticated() === true) {
      return true;
    }
    else {
      console.log(route);
      this.router.navigate(['/signin'], { queryParamsHandling: 'preserve', preserveFragment: true } );
      return false;
    }
  }
}
