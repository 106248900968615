import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import Amplify, { Auth } from 'aws-amplify';
import * as jwtDecode from 'jwt-decode';
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-amplify-authenticate',
  templateUrl: './amplify-authenticate.component.html',
  styleUrls: ['./amplify-authenticate.component.css']
})
export class AmplifyAuthenticateComponent implements OnInit {

  decodedJwt;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  async ngOnInit() {
    const urlParams = new URLSearchParams('?' + this.route.snapshot.fragment);
    const idToken = urlParams.get('id_token');
    const accessToken = urlParams.get('access_token');
    const decodedAccessToken = jwtDecode(accessToken);
    const decodedIdToken = jwtDecode(idToken);

    localStorage.setItem('access_token', JSON.stringify(decodedAccessToken));
    localStorage.setItem('id_token', JSON.stringify(decodedIdToken));

    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000)

    // this.authService.setFederatedUser(decodedIdToken, decodedAccessToken);
  }

}
