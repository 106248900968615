import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Feed, FeedItem} from '../../models/Feed';
import {FeedTemplateRow} from '../../models/FeedTemplateRow';
import {FeedTemplateColumn} from '../../models/FeedTemplateColumn';
import {FeedTemplate} from '../../models/FeedTemplate';

@Injectable({
  providedIn: 'root'
})
export class FeedPropertiesService {

  constructor() { }


  private _jsonRowTemplate: BehaviorSubject<FeedTemplateRow> = new BehaviorSubject<FeedTemplateRow>(<FeedTemplateRow>{});
  private _jsonColumnTemplate: BehaviorSubject<FeedTemplateColumn> = new BehaviorSubject<FeedTemplateColumn>(<FeedTemplateColumn>{});
  private _rendered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _newfeedtemplate: BehaviorSubject<FeedTemplate> = new BehaviorSubject<FeedTemplate>(<FeedTemplate>{});
  public rowindex = 0;
  public colindex = 0;


  public getRow(): Observable<FeedTemplateRow> {
    return this._jsonRowTemplate.asObservable();
  }
  public setRow(value: FeedTemplateRow): void {
    this._jsonRowTemplate.next(value);
  }

  public getRendered(): Observable<boolean> {
    return this._rendered.asObservable();
  }
  public setRendered(value: boolean): void {
    this._rendered.next(value);
  }

  public getColumn(): Observable<FeedTemplateColumn> {
    return this._jsonColumnTemplate.asObservable();
  }
  public setColumn(value: FeedTemplateColumn): void {
    this._jsonColumnTemplate.next(value);
  }

  public getNewFeedemplate(): Observable<FeedTemplate> {
    return this._newfeedtemplate.asObservable();
  }
  public setNewFeedTemplate(value: FeedTemplate): void {
    this._newfeedtemplate.next(value);
  }



}
