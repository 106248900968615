import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ScheduleFrequency } from '../models/Schedule';
import { Prompt } from '../modules/message-dialog-module/models/Prompt';
import { MessageDialogService } from '../modules/message-dialog-module/services/message-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  private _RGB_REGEX = /^[0-9]{1,3},[0-9]{1,3},[0-9]{1,3}$/;
  private _HEX_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  public HEX_REGEX(): RegExp {
    return this._HEX_REGEX;
  }
  public RGB_REGEX(): RegExp {
    return this._RGB_REGEX;
  }

  constructor(private msg: MessageDialogService) { }


  public Error403(additionalMsg?: string): void {
    if (!additionalMsg) {
      additionalMsg = "";
    }

    let unauthorised: Prompt = new Prompt("Oooops", "You're account isn't authorised for this action.\n" + additionalMsg, "ok");
    unauthorised.positiveCallback = () => {
      this.msg.removeDialog();
    }
    unauthorised.negativeCallback = () => {
      this.msg.removeDialog();
    }

    this.msg.createDialog(unauthorised);
  }

  failed(): Prompt {
    let failed: Prompt = new Prompt("Hmmm...", "There has been an error processing, please try again", "ok");
    failed.positiveCallback = () => {
      this.msg.removeDialog();
    }
    failed.negativeCallback = () => {
      this.msg.removeDialog();
    }
    return failed;
  }

  reorderItemInArray(arr: Array<any>, origin: number, update: number): Array<any> {
    if (update >= arr.length) {
      let k = update - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(update, 0, arr.splice(origin, 1)[0]);
    
    return arr;
  };

  public validateEmailAddress(email: string): boolean {
    let regex: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }

  recursivelyLowercaseJSONKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    if (this.isArray(obj)) {
      return obj.map(o => this.recursivelyLowercaseJSONKeys(o));
    }
    return Object.keys(obj).reduce((prev, curr) => {
      prev[curr.toLowerCase()] = this.recursivelyLowercaseJSONKeys(obj[curr]);
      return prev;
    }, {});
  }

  isEmptyObject(obj): boolean {
    return Object.keys(obj).length === 0;
  }

  recursivelyRemoveUnderscoreJSONKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    if (this.isArray(obj)) {
      return obj.map(o => this.recursivelyRemoveUnderscoreJSONKeys(o));
    }

    return Object.keys(obj).reduce((prev, curr) => {
      prev[curr.replace("_", "")] = this.recursivelyRemoveUnderscoreJSONKeys(obj[curr]);
      return prev;
    }, {});
  }

  logVars(arr): void {
    arr.forEach(variable => {
      console.log(variable);
    });
  }

  generateGUID(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  sortAcsending(a, b, key?) {
    if (key) {
      return a[key] - b[key];
    }
    return a - b;
  }

  sortDecsending(a, b, key?) {
    if (key) {
      return b[key] - a[key];
    }
    return b - a;
  }

  getGuidFromUrl(url: string): string {
    return url.substring(url.lastIndexOf("/"), url.length);
  }

  generateShortCode(name: string): string {
    let code: string = name.replace(" ", "").substr(0, 3).toUpperCase(),
      requiredDigs: number = 6 - code.length;

    while (requiredDigs > 0) {
      code = code + this.randomInt(0, 9);
      requiredDigs--;
    }
    return code;
  }

  randomInt(min, max): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  hexToRgb(hex: string): object {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  rgbToHex(r: number, g: number, b: number): string {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  private componentToHex(c: number): string {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  convertRGBStringToHex(rgbString: string): string {
    let rgb: Array<string> = rgbString.split(',')
    let r = parseInt(rgb[0]), g = parseInt(rgb[1]), b = parseInt(rgb[2]);
    return this.rgbToHex(r, g, b);
  }

  convertHexStringToRgbString(hex: string): string {
    let rgb: object = this.hexToRgb(hex);
    return rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'];
  }

  parseDateNow(): string {
    return moment().format('YYYY-MM-DDThh:mm:ss+00:00');
  }

  parseDateTime(moment: Moment) {
    return moment.format('YYYY-MM-DDThh:mm:ss+00:00');
  }

  parseDate(moment: Moment) {
    return moment.format("ll");
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getFontColorFromBackgroundColor(rgb) {
    if ((rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186) {
      return 'black';
    } else {
      return 'white';
    }
  }

  calculateFutureTime(previousTime: string, frequency: ScheduleFrequency): string {
    let date: Moment = moment(previousTime);

    switch (frequency) {
      case ScheduleFrequency.DAILY:
        return date.add(1, 'd').toISOString();
      case ScheduleFrequency.WEEKLY:
        return date.add(1, 'w').toISOString();
      case ScheduleFrequency.MONTHLY:
        let fm = moment(previousTime).add(1, 'M');
        let fmEnd = moment(fm).endOf('month');
        return date.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd').toISOString() : fm.toISOString();
    }
  }



}
