import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import {HomeComponent} from './home.component';
import {TopNavComponent} from './top-nav/top-nav.component';
import {LeftNavComponent} from './left-nav/left-nav.component';
import {LeftNavAccordionComponent} from './left-nav/left-nav-accordion/left-nav-accordion.component';
import {LeftNavAccordionItemComponent} from './left-nav/left-nav-accordion/left-nav-accordion-item/left-nav-accordion-item.component';
import {SharedModule} from '../modules/shared/shared.module';
import { BreadcrumbsModule } from '../modules/breadcrumbs/breadcrumbs.module';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    BreadcrumbsModule
  ],
  declarations: [
    HomeComponent,
    TopNavComponent,
    LeftNavComponent,
    LeftNavAccordionComponent,
    LeftNavAccordionItemComponent,
  ]
})
export class HomeModule { }
