import { Component, OnInit } from "@angular/core";
import { EditionService } from "../../../../services/edition.service";
import { ModalService } from "../../../../modal/services/modal.service";
import { ToastService } from "../../../../services/toast.service";
import { Edition } from "src/app/models/edition";
import { Section } from "src/app/models/Section";
import { ApplicationsService } from "src/app/services/applications.service";
import {LoadingService} from "../../../../services/loading.service";

@Component({
  selector: "app-new-section",
  templateUrl: "./new-section.component.html",
  styleUrls: ["./new-section.component.css"],
})
export class NewSectionComponent implements OnInit {
  placeholder: string = 'Select';
  sectionName: string;
  sectionColor: string;
  pageView: string = "create";
  selectedPublicationName: any = {'editionguid':''};
  selectedEditionName: any = {'name':''};
  selectedSectionNames: any = [];

  allPublications: any = [{ name: "Akash" }, { name: "Ben" }, { name: "Kev" }];
  allEditions: Edition[];
  allSections: any[];

  constructor(
    private editionService: EditionService,
    private modalService: ModalService,
    private toastService: ToastService,
    private appsrc: ApplicationsService,
    private loadingService: LoadingService
  ) {
    this.getPublicationsEditions(); // to get all publications n sections for import
  }

  ngOnInit() {}

  closeModal(){
    this.modalService.destroy();
  }

  getPublicationsEditions() {
    this.appsrc.getAllApps().subscribe((data) => {
      this.allPublications = data;
    });
    console.log(this.allPublications);
  }

  changePublication(event: any) {
    this.selectedSectionNames = [];
    this.selectedEditionName = {'name':''};
    this.allEditions = [];
    this.allSections = [];

    this.appsrc.loadEditions(event.guid, 0, 10).then((editions: any[]) => {
      this.allEditions = editions;
      if(editions.length=== 0){
        this.placeholder = 'No Data Found';
      } else {
        this.placeholder = 'Select';
      }
    });
    // console.log(this.allEditions);
  }

  changeEdition(event: any) {
    this.selectedSectionNames = [];
    this.allSections = [];
    this.editionService
      .getSectionFromEdition(event.editionguid)
      .subscribe((data) => {
        this.allSections = data;
      });
  }

  createNewSection() {
    this.loadingService.setLoading();
    if (this.pageView === "create") {
      this.editionService
        .createNewSection(this.sectionName, this.sectionColor)
        .then((res) => {
          console.log(res);
          this.loadingService.setFinished();
          this.editionService.refreshEdition.next();
          this.modalService.destroy();
        })
        .catch((err) => {
          this.loadingService.setFinished();
          this.toastService.failed(err.error.error);
          console.log(err);
        });
    }
  }

  importNewSection() {
    this.loadingService.setLoading();
    let importData = {
      masterEditionGuid: this.selectedEditionName.editionguid,
      sections: this.selectedSectionNames.map((item) => ({
        guid: item.sectionguid,
        name: item.data.name,
      })),
    };

    // console.log(this.selectedPublicationName);
    // console.log(this.selectedEditionName);
    console.log(importData);
    if (this.selectedSectionNames.length > 0 && this.selectedEditionName.editionguid !== undefined ) {
      this.editionService
        .importNewSection(importData)
        .then((res) => {
          console.log(res);
          this.loadingService.setFinished();
          this.editionService.refreshEdition.next();
          this.modalService.destroy();
        })
        .catch((err) => {
          this.loadingService.setFinished();
          this.toastService.failed(err);
          console.log(err);
        });
    } else {
      this.toastService.failed("Please select all the fields");
    }
  }

  removeSection(index: number){

    this.selectedSectionNames.splice(index,1);
    const clonedArray  = Object.assign([],this.selectedSectionNames);
    this.selectedSectionNames = [];
    this.selectedSectionNames = Object.assign([],clonedArray);

  }
}

