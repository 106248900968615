import {Component, Input, OnInit} from '@angular/core';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Flatplan} from '../../../models/Flatplan';
import {FlatplanmanagerService} from '../../../services/flatplanmanager.service';
import {FlatplanSection} from '../../../models/FlatplanSection';
import {FlatplanPage} from '../../../models/FlatplanPage';
import {ModalService} from '../../../modal';
import {SelecttemplateComponent} from '../selecttemplate/selecttemplate.component';
import {TemplateListComponent} from '../../editions/view-section/shared/templates/template-list.component';
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-addpage',
  templateUrl: './addpage.component.html',
  styleUrls: ['./addpage.component.css']
})
export class AddpageComponent implements OnInit {

  @Input('mode') mode: string;
  @Input('flatplan') flatplan: Flatplan;
  @Input('flatplanSection') flatplanSection: FlatplanSection;
  @Input('platformid') platformid: string;

  constructor(private flatplanservice : FlatplanmanagerService, private modalService: ModalService, private loadingService: LoadingService) { }

  ngOnInit() {
    console.log("------------------------------");
    console.log(this.mode);
    console.log(this.flatplan);
    console.log(this.flatplanSection);
    console.log(this.platformid);
    console.log("------------------------------");

  }

  ngOnDestroy(): void {

  }

  selectSection()
  {
    const newFlatplanPage = new FlatplanPage("templateSection", [], false, "0", "section", []);
    this.loadTemplateSelector(newFlatplanPage, "sections");
  }



  selectArticle()
  {
    const newFlatplanPage = new FlatplanPage("templateArticle", [], false, "0", "article", []);
    this.loadTemplateSelector(newFlatplanPage,"articles");
  }

  loadTemplateSelector(flatplanPage: FlatplanPage, type: string) {
    this.modalService.destroy();
    this.modalService.init(SelecttemplateComponent, {
      "sectionsorarticles" : type,
      "platformid": this.platformid,
      "flatplanPage": flatplanPage,
      "flatplanSection": this.flatplanSection
  }, null);
  }

  selectAdvert()
  {
    const fps = new FlatplanPage("interstitial", [], true, "0", "interstitial",[]);
    this.flatplanSection.pages.push(fps);
    this.modalService.destroy();
  }

  selectPuzzle()
  {
    const fps = new FlatplanPage("puzzle", [], true, "0", "puzzle", []);
    this.flatplanSection.pages.push(fps);
    this.modalService.destroy();
  }


}
