import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../modal/services/modal.service';
import {App} from '../../../models/App';
import {ApplicationsService} from '../../../services/applications.service';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AuthService} from '../../../services/auth.service';
import {PSProService} from '../../../services/pspro.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-add-app-to-archive',
  templateUrl: './add-app-to-archive.component.html',
  styleUrls: ['./add-app-to-archive.component.css']
})
export class AddAppToArchiveComponent implements OnInit {

  @Input('page') page;

  hasPageLoaded = false;
  appType = "app";
  allApps = new Array<App>();
  allPublications = [];

  constructor(private appService: ApplicationsService, private modalService: ModalService, private authService: AuthService, private pspro: PSProService) { }

  ngOnInit() {
    console.log(this.page);
    this.loadAllApps().then(() => {
      this.loadReplicas().then(() => {
        this.loadPage();
      });
    });
  }

  loadPage() {
    this.hasPageLoaded = true;
  }

  private loadAllApps() {
    return new Promise((resolve, reject) => {
      this.appService.getAllApps().pipe(untilComponentDestroyed(this)).subscribe((value) => {
        this.allApps = value;
        resolve();
      });
    });
  }

  private loadReplicas() {
    return new Promise((resolve, reject) => {
      this.pspro.getPublications(this.authService.pagesuiteAccountGUID()).then((arrAccountPublications: any[]) => {
        console.log(arrAccountPublications);
        this.allPublications = arrAccountPublications;
        resolve();
      });
    });
  }

  ngOnDestroy() {

  }

  //not setting boolean to false
  addApp(app) {

    let newApp = {
      guid: "",
      type: "",
      isLocked: false
    }

    if (this.appType === "app") {
      newApp.type = "edition";
      newApp.guid = app.app.guid;
    } else if (this.appType === "replica") {
      newApp.type = "replica";
      newApp.guid = app.publicationGuid;
    }

    this.page.items.push(newApp);
    this.modalService.destroy();
  }

  changeType(type) {
    this.appType = type;
  }

  getApplicationThumb(app):string {
    if (this.appType === "app") {
      return environment.screenshotUrl + "/image/application/" + app.app.guid + "/platform/2?width=768";
    } else if (this.appType === "replica") {
      return "https://edition.pagesuite.com/get_image.aspx?w=260&pbid=" + app.publicationGuid;
    }
  }
}
