import { Component, OnInit } from '@angular/core';
import {RenderArticle} from '../RenderArticle';

@Component({
  selector: 'app-render-feed-top-image',
  templateUrl: './render-feed-top-image.component.html',
  styleUrls: ['./render-feed-top-image.component.css']
})
export class RenderFeedTopImageComponent extends RenderArticle  implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

  }

  sanitizeImg(url)
  {
    return url;
  }

}
