import {Component, OnInit} from '@angular/core';
import {ModalService} from "./modal/services/modal.service";
import {MessageDialogService} from './modules/message-dialog-module/services/message-dialog.service';
import {Prompt} from './modules/message-dialog-module/models/Prompt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private modalService: ModalService, private messageService: MessageDialogService) {}
  title = 'app';
  public showMessage = false;
  public message: any;


  ngOnInit() {
    this.showMessage = this.messageService.isMessageShown();
    this.messageService.showDialog.subscribe((value) => {
      this.message = value;
      this.showMessage = true;
    });
    this.messageService.hideDialog.subscribe((value) => {
      this.showMessage = false;
    });
  }

  removeModal() {
    this.modalService.destroy();
  }



}
