import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { Article } from "../../../../models/Article";
import { FunctionService } from "../../../../services/function.service";
import { EditionService } from "../../../../services/edition.service";
import { ModalService } from "../../../../modal/services/modal.service";
import { MessageDialogService } from "../../../../modules/message-dialog-module/services/message-dialog.service";
import { ApiService } from "../../../../services/api.service";
import { ApplicationsService } from "../../../../services/applications.service";
import { ToastService } from "../../../../services/toast.service";
import { ArticleImage } from "../../../../models/ArticleImage";
import { Video } from "../../../../models/Video";
import { Prompt } from "../../../../modules/message-dialog-module/models/Prompt";
import { REST } from "../../../../models/enums/api-end-points";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { PageType } from "../../../../models/page";
import { LoadingService } from "../../../../services/loading.service";

declare var $: any;

@Component({
  selector: "app-edit-article",
  templateUrl: "./edit-article.component.html",
  styleUrls: ["./edit-article.component.css"],
})
export class EditArticleComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("editor") editor: ElementRef;
  // @ViewChild('headlineinput') headlineInput: ElementRef;
  // @ViewChild('authorinput') authorInput: ElementRef;
  // @ViewChild('imageCaption') imageCaption: ElementRef;

  public article: Article;
  public hasImages: boolean = false;
  public isNewArticle: boolean;
  public isVideo: boolean = false;
  // public activeImageIndex: number;

  public editionGuid: string;
  public articleGuid: string;
  public pageGuid: string;
  public section: string;
  public editionName: string;

  public shortheadline: string;

  headline: string;
  subheadline: string;
  author: string;
  sectionfrontheadline: string;
  sectionfrontsubheadline: string;
  twitterhandle: string;
  category: string;

  parentSubscription = null;

  previousArticleGuid = null;
  nextArticleGuid = null;

  sub: Subscription;
  sub2: Subscription;
  sub3: Subscription;

  constructor(
    private http: HttpClient,
    private func: FunctionService,
    private editionService: EditionService,
    private modalService: ModalService,
    private messagingService: MessageDialogService,
    private apiService: ApiService,
    private appService: ApplicationsService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private loadingService: LoadingService
  ) {}

  /* Life Cycles */

  ngOnInit() {
    // this.initialiseVars();
    this.sub = this.route.params.subscribe((params: any) => {
      if (this.parentSubscription == null) {
        this.parentSubscription = this.route.parent.parent.params.subscribe(
          (parentParams) => {
            this.section = this.route.snapshot.queryParamMap.get("section");
            setTimeout(() => {
              this.editionService.activeSectionName.next(this.section);
            }, 300);
            this.sub2 = this.route.parent.parent.params.subscribe(
              (parentParentParams) => {
                this.articleGuid = params["articleid"];

                this.sub3 = this.route.parent.parent.parent.params.subscribe(
                  (outerParentParams) => {
                    this.appService.loadedApplicationGUID =
                      outerParentParams["appid"];
                  }
                );

                if (this.editionService.jsonedition != null) {
                  this.editionGuid =
                    this.editionService.jsonedition.editionGUID;
                  this.article = this.editionService.getArticle(
                    this.articleGuid
                  );
                  if (typeof this.article != "undefined") {
                    this.init();
                  } else {
                    this.initNew();
                  }
                } else {
                  this.editionService
                    .getEditionForPlatform(
                      parentParentParams["editionid"],
                      parseInt(parentParentParams["platformid"])
                    )
                    .then(() => {
                      this.editionGuid =
                        this.editionService.jsonedition.editionGUID;
                      this.article = this.editionService.getArticle(
                        this.articleGuid
                      );
                      if (typeof this.article != "undefined") {
                        this.init();
                      } else {
                        this.initNew();
                      }
                    });
                }
              }
            );
          }
        );
      } else {
        this.articleGuid = params["articleid"];
        this.article = this.editionService.getArticle(this.articleGuid);
        this.init();
      }
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.parentSubscription) {
      this.parentSubscription.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub3) {
      this.sub3.unsubscribe();
    }
    // delete this.article;
  }

  /* Methods */

  init() {
    console.log("init");
    this.previousArticleGuid = this.editionService.getPreviousArticle(
      this.article.articleguid
    );
    this.nextArticleGuid = this.editionService.getNextArticle(
      this.article.articleguid
    );
    this.editionName = this.editionService.jsonedition.name;
    this.headline = this.article.headline;
    this.shortheadline = this.article.shortheadline;
    this.editionService.activeArticleHeadline.next(this.headline);
    this.subheadline = this.article.subheadline;
    this.author = this.article.author;
    this.sectionfrontheadline = this.article.sectionfrontheadline;
    this.sectionfrontsubheadline = this.article.sectionfrontsubheadline;
    this.twitterhandle = this.article.twitterhandle;
    this.category = this.article.category;
    this.isNewArticle = false;
    setTimeout(() => {
      this.editor["editor"].setContent(this.article.description);
    }, 500);
  }

  initNew() {
    this.editionName = this.editionService.jsonedition.name;
    this.article = new Article(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      [],
      "",
      [],
      this.section,
      "",
      "",
      "",
      [],
      "",
      "",
      "",
      true,
      "",
      "",
      new Date().toISOString()
    );
    this.editionService.activeArticleHeadline.next("New Article");
    this.isNewArticle = true;
    this.headline = this.article.headline;
    this.shortheadline = this.article.shortheadline;
    this.subheadline = this.article.subheadline;
    this.author = this.article.author;
    this.sectionfrontheadline = this.article.sectionfrontheadline;
    this.sectionfrontsubheadline = this.article.sectionfrontsubheadline;
    this.twitterhandle = this.article.twitterhandle;
    this.category = this.article.category;
    setTimeout(() => {
      this.editor["editor"].setContent(this.article.description);
    }, 500);
  }

  public setArticleImages(imgs: Array<ArticleImage>): void {
    console.log(imgs);
    this.article.images = imgs;
  }

  public setArticleVideos(vids: Array<Video>): void {
    this.article.videos = vids;
  }

  public toggleNav(nav: string): void {
    if (nav == "video") {
      this.isVideo = true;
      return;
    }
    if (nav == "image") {
      this.isVideo = false;
      return;
    }
  }

  noscript(strCode): string {
    var html = $(strCode.bold());
    html.find("script").remove();
    var content = html.html().trim();
    content.replace(/<p>/g, "");
    content = content.replace(/<\/p>/g, "|||");
    content = content.replace(/<br><br>/g, "|||");

    return $("<div>" + content + "</div>").text();
  }

  removescript(strCode) {
    let html = $(strCode.bold());

    html.find("script").remove();
    html.find("img").remove();
    html.find("video").remove();
    html.find("object").remove();
    html.find("iframe").remove();
    html.find("embed").remove();
    html.find("figure").remove();
    let content = html.html().trim();

    return $("<pagesuite>" + content + "</pagesuite>").html();
  }

  saveArticle() {
    let currentTime = new Date(Date.now()),
      jsonArticle;

    this.loadingService.setLoading();

    if (this.article.images[0] && this.article.images[0]["caption"] == "") {
      delete this.article.images[0]["caption"];
    }
    if (this.article.images[0] && this.article.images[0]["credit"] == "") {
      delete this.article.images[0]["credit"];
    }

    console.log(this.article);

    /* Extracted from Modal */
    this.article.headline = this.headline;
    this.article.shortheadline = this.shortheadline;
    this.article.author = this.author;
    this.article.subheadline = this.subheadline;
    this.article.description = this.editor["editor"].getContent();
    this.article.textdescription = this.noscript(this.article.description);
    this.article.descriptionnoscript = this.removescript(
      this.article.description
    );
    this.article.twitterhandle = this.twitterhandle;
    this.article.category = this.category;

    console.log(this.article);

    // This is where we need to do

    this.article.lastupdated = currentTime.toISOString();

    if (!this.isNewArticle) {
      jsonArticle = this.func.recursivelyRemoveUnderscoreJSONKeys(this.article);
      this.postUpdateArticle(jsonArticle);
    } else {
      jsonArticle = this.func.recursivelyRemoveUnderscoreJSONKeys(this.article);
      console.log(jsonArticle);
      this.postNewArticle(jsonArticle);
      // this.update();
    }
  }

  postUpdateArticle(article) {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    var options = {
      headers: headers,
    };

    let postObject = {
      article: article,
      editionGuid: this.editionService.jsonedition.editionGUID,
      // pageGuid: this.editionService.activePageGuid,
      applicationGuid: this.appService.loadedApplicationGUID,
    };

    console.log(postObject);

    console.log(postObject);
    console.log(
      REST.UPDATE_ARTICLE.replace("{articleGuid}", this.article.articleguid)
    );

    this.http
      .post(
        REST.UPDATE_ARTICLE.toString().replace(
          "{articleGuid}",
          this.article.articleguid
        ),
        postObject,
        options
      )
      .subscribe(
        (responseMsg) => {
          this.loadingService.setFinished();
          //update Obj or Error
          console.log(responseMsg);
          if (responseMsg["updated"]) {
            // this.update();
            this.toast.success("Article Saved");
          } else {
            this.toast.failed();
            console.error("Article was not updated.");
          }
        },
        (error) => console.error(error)
      );
  }

  postNewArticle(article) {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    var options = {
      headers: headers,
    };

    let postObject = {
      article: article,
      sectionGuid: this.editionService.getSection(this.section).sectionguid,
      sectionIndex: this.editionService.getSection(this.section).articles
        .length,
    };

    console.log(postObject);

    this.http.put(REST.NEW_ARTICLE.toString(), postObject, options).subscribe(
      (responseMsg) => {
        //update Obj or Error
        this.loadingService.setFinished();
        console.log(responseMsg);
        if (responseMsg["articleguid"]) {
          // this.update();
          this.toast.success("Article Saved");
          this.article.articleguid = responseMsg["articleguid"];
          this.editionService.addArticleToSection(this.article, this.section);
          let newPage = this.editionService.createNewPage(
            this.section,
            PageType.TEMPLATEARTICLE,
            "",
            this.article
          );
          this.editionService.addPageToEdition(newPage);
          console.log(this.editionService.jsonedition);
          this.location.back();
        } else {
          this.toast.failed();
          console.error("Article was not saved.");
        }
      },
      (error) => console.error(error)
    );
  }

  previousArticle() {
    this.router.navigate(["" + this.previousArticleGuid], {
      relativeTo: this.route.parent,
    });
  }

  nextArticle() {
    this.router.navigate(["" + this.nextArticleGuid], {
      relativeTo: this.route.parent,
    });
  }
}
