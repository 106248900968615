import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Edition} from "../../../../../models/edition";
import {Section} from "../../../../../models/Section";
import {Article} from "../../../../../models/Article";
import {EditionService} from "../../../../../services/edition.service";

@Component({
  selector: 'article-categories',
  templateUrl: './article-categories.component.html',
  styleUrls: ['./article-categories.component.css']
})
export class ArticleCategoriesComponent implements OnInit {

  @Input('edition') edition: Edition;
  @Input('default') sectionName: string;
  @Output('updateArticles') articles = new EventEmitter();;

  sections: Section[] = new Array<Section>();
  selectedArticles: Article[] = new Array<Article>();

  constructor(private editionService: EditionService) { }

  ngOnInit() {
    this.updateArticleList();
  }

  public updateArticleList() {
    if (this.edition) {
      console.log("updating article list");
      this.sections = this.editionService.returnSectionsFromEdition(this.edition);
      this.displaySectionArticles(this.editionService.getSection(this.sectionName));
    }
    else {
      console.error('Edition not found');
    }
  }

  displaySectionArticles(section?: Section) {
    this.selectedArticles = [];

    if (section) {
      section.articles.forEach(article => {
        this.selectedArticles.push(article);
      });
      this.sectionName = section.name;
    }
    else {
      this.sections.forEach(section => {
        this.selectedArticles = this.selectedArticles.concat(section.articles);
      });
      this.sectionName = 'allsections';
    }
    this.articles.emit({ articles: this.selectedArticles, section: this.sectionName });

  }

}
