import {environment} from "../../../environments/environment";
import {en} from "cronstrue/dist/i18n/locales/en";

export let REST = {
    GET_ACCOUNT_TEMPLATES: environment.url + "template/list/", //[ACCOUNTGUID\",
    GET_GLOBAL_TEMPLATES: environment.url + "template/list/global",
    GET_APPLICATIONS: environment.url + "account/{accountGuid}/applications", //accountGUID
    GET_APPLICATION: environment.url + "application/{applicationGuid}", //appGuid
    GET_EDITION: environment.url + "edition/{editionGuid}/full/platform/{platformId}", //EditionGuid
    GET_FEED: environment.url + "feeds/{feedguid}/content",
    NEW_EDITION: environment.url + "edition/new",
    NEW_SECTION: environment.url + "edition/section/new",
    IMPORT_SECTION: environment.url + "edition/{editionGuid}/sections",
    DELETE_EDITION: environment.url + "edition/{editionGuid}",
    // GET_EDITIONS: environment.url + "application/{applicationGuid}/editions", //applicationGUID
    GET_EDITIONS: environment.url + "application/{applicationGuid}/platform/2/editions?from={from}&count={count}",
    GET_FEEDS: environment.url + "feed/all/{accountGuid}", //accountguid
    PARSE_FEED: environment.url + "feed/{feedguid}/parse",
    GET_FEEDS_TEMPLATES: environment.url + "feedtemplate/all",
    GET_MAPPINGS: environment.url + "feed/mappings/all",
    GET_GOOGLE_KEY: "https://gck26wvn8b.execute-api.eu-west-1.amazonaws.com/dev",
    GET_FEED_CONTENT: environment.url + "feed/{feedguid}/parsedcontent",
    DELETE_ARTICLE: environment.url + "article/{articleGuid}",

    SCHEDULES: environment.url + "application/{APPGUID}/scheduler",
    DELETE_SCHEDULES: environment.url + "application/{APPGUID}/scheduler/{SCHEDULERGUID}",

    ADD_FEED: environment.url + "feed/new",

    POST_TEMPLATES: environment.url + "templates/upload",
    POST_ARTICLE: environment.url + "article/", //articleGUID
    POST_PAGES: environment.url + "pages/", //editionguid

    UPDATE_ARTICLE: environment.url + "article/{articleGuid}", //articleGUID
    NEW_ARTICLE: environment.url + "article/new",
    // UPDATE_PAGE_ORDER: environment.url + "edition/{editionGuid}/{platformid}/update",
    UPDATE_PAGE_ORDER: environment.url + "edition/{editionGuid}/{platformid}/update",

    PUT_FEED: environment.url + "feed/{feedGuid}",

    NEW_APP: environment.url + "application/new",
    SAVE_APP: environment.url + "application/{applicationGuid}",
    SAVE_FEED_TEMPLATES: environment.url + "feedtemplate",

    GET_FLATPLANS: environment.url + "application/{applicationGuid}/flatplans",
    GET_FLATPLAN: environment.url + "flatplan/{flatplanGuid}",
    CREATE_FLATPLAN: environment.url + "flatplan/new/{applicationGuid}",
    SAVE_FLATPLAN: environment.url + "flatplan/{flatplanGuid}",

    GET_PRODUCTS: environment.url + "application/{applicationGuid}/products",
    CREATE_PRODUCT: environment.url + "product",

    SET_LIVE: environment.url + "edition/{editionGuid}/islive/{isLive}",
    LOGIN: environment.url + "user/{username}/login",
    UPLOAD: environment.url + "template/upload",
    ADD_JOB: environment.url + "job/create",
    NEW_EDITION_JOB: environment.url + "job/process/",
    UPDATE_EDITION: environment.url + "edition/{editionGuid}/{platformid}/update",

    GENERATE_SCREENSHOT_HTML: environment.url + "screenshot/template/{templateGuid}",

    GET_PUBLICATIONS: environment.url + "replica/account/{accountGuid}/Publications",
    UPLOAD_IMAGE: environment.url + "file/image/upload",
    FONTS: "https://s3-eu-west-1.amazonaws.com/dev-feed-editions/css/fonts.json",
    CHECK_SHORTCODE: environment.url + "application/shortcode/{shortCode}/exists",
    SAVE_EDITION: environment.url + "edition/{editionGuid}",
    PATCH_EDITION_PDF: "https://api.core.pagesuite.com/editions/{editionGuid}",
    UPDATE_SECTION_ORDER: environment.url + "section/{sectionGuid}",
    GENERATE_REPLICA: environment.replica2pdf + "init",
    EDITION_REPLICA_PROGRESS: environment.replica2pdf + "progress/{jobID}",
    GET_APP_CACHE: environment.url + "application/shortcode/{shortCode}/lastcacheupdate",
    GET_ARCHIVE: environment.oldPortal + "api/get_edition_from_pubguid.aspx?pubguid={pubGuid}",
    SCREENSHOT_FOR_PDF: environment.url + "screenshot/edition/{editionGuid}/2?generatescreenshots=false",
    S3_PRESIGNED_URL: environment.url + "file/presignedurl/{accountGuid}",
    GET_SECTION: environment.url + "edition/{editionGuid}/sections",
    IP_URL: environment.url + "ip/",
    GENERATE_REPLICA_V1: environment.replica2pdfv1 + "init",
    EDITION_REPLICA_PROGRESS_V1: environment.replica2pdfv1 + "progress/{jobID}",
    PATCH_REPLICA_EDITION: "https://api.core.pagesuite.com/editions/{editionGuid}"
};

