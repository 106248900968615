import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { PSProService } from "./pspro.service";
import { Subscription } from "rxjs/Subscription";
import {map, catchError} from 'rxjs/operators';
import {
  App, IAppReplica, IAppSection, IAppMenu, IAppFonts, IAppFooter, IAppHeader, IAppAdvertising, IAppEditionRules,
  IApp, ISubscription, IAppTheme, IAppMasthead
} from '../models/App';
import { ApiService } from './api.service';
import { FunctionService } from "./function.service";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Edition } from '../models/edition';
import { Schedule, ScheduleManager } from '../models/Schedule';
import { Flatplan } from "../models/Flatplan";
import { PagesuitePushAccount } from '../apps/push/shared/models/PagesuitePushAccount';
import { AppKiosk } from "../models/AppKiosk";
import { REST } from "../models/enums/api-end-points";


declare var $: any;


@Injectable({
  providedIn: 'root'
})

export class ApplicationsService {

  public addPushAccountToApplication(account: PagesuitePushAccount): Promise<any> {
    this._ActiveAppliction.value.app.apikey = account.apikey;
    this._ActiveAppliction.value.app.sharedsecret = account.apisecret;

    return this.updateApplication(this._ActiveAppliction.value);
  }

  public broadcastActiveApplication(): void {
    this._ActiveAppliction.next(this._ActiveAppliction.value);
  }

  /* App */

  private _AllApps: BehaviorSubject<Array<App>> = new BehaviorSubject<Array<App>>(new Array<App>());
  public getAllApps(): Observable<Array<App>> {
    return this._AllApps.asObservable();
  }
  public setAllApps(Apps: Array<App>) {
    this._AllApps.next(Apps);
  }


  private _ActiveAppliction: BehaviorSubject<App> = new BehaviorSubject<App>(
    new App(
      <IApp>{}, <any>{}, <IAppMenu>{}, <IAppFonts>{}, <AppKiosk>{}, <IAppFooter>{}, <IAppHeader>{}, <IAppReplica>{}, new Array<IAppSection>(), <IAppAdvertising>{}, <IAppEditionRules>{}, new Array<Edition>(), {}, new Array<Flatplan>(), {}, {}, <IAppMasthead>{}, {}, {}, {}, [], {}, {}
    ));

  public getStaticAppState(): App {
    return this._ActiveAppliction.value;
  }
  public getActiveApplication(): Observable<App> {
    return this._ActiveAppliction.asObservable();
  }
  public setActiveApplication(App: App): void {
    if (App) {
      const guid = App.app.guid;
      App.app.guid ? localStorage.setItem("appguid", guid) : null;
      this.loadedApplicationGUID = guid;

      this._ActiveAppliction.next(App);
    }
    else {
      this.loadedApplicationGUID = "";
    }
  }

  /* Vars */

  //public jsonPortal: any;
  public jsonEdition: any;
  public jsonTemplates: any;
  public jsonStaticTemplates: any;
  public jsonDynamicTemplates: any;
  public loadedEdition: any;
  public loadedApplicationGUID: string;
  public isAppsLoaded = false;
  public jsonTemplate = {};
  public tableschema;
  public tablecontent;
  public showTopbar = true;
  public addedItemToKiosk = new Subject<any>();
  public addedItemToArchive = new Subject<any>();
  public activeTab = "dashboard";
  public allowedToSave = true;
  public addingToArchive = false;

  /* AppSchedule State Manager */
  private _AppSchedules: BehaviorSubject<Array<Schedule>> = new BehaviorSubject<Array<Schedule>>(new Array<Schedule>());

  public getAppSchedules(): Observable<Array<Schedule>> {
    return this._AppSchedules.asObservable();
  }
  public setAppSchedules(schedules: Array<Schedule>): void {
    this._AppSchedules.next(schedules);
  }
  public addSchedule(schedule: Schedule): void {
    this._AppSchedules.value.push(schedule);
    this.broadcastScheduler();
  }
  public updateSchedule(i: number, schedule: Schedule): void {
    this._AppSchedules.value[i] = schedule;
    this.broadcastScheduler();
  }
  public deleteSchedule(i: number): void {
    this._AppSchedules.value.splice(i, 1);
    this.broadcastScheduler();
  }
  private broadcastScheduler(): void {
    this.setAppSchedules(this._AppSchedules.value);
  }
  /* */

  private _jsonJobs;
  public getJsonJobs(): any {
    return this._jsonJobs;
  }
  public set JsonJobs(jobs: any) {
    this._jsonJobs = jobs;
  }

  public tempaddeditems;
  public temptreedata;
  public tempdata;

  public processedTimezones;

  /* Subjects */
  public subjContent = new Subject();
  public subjEdition = new Subject();
  public subjTemplates = new Subject();
  public subjSavedApplication = new Subject();
  public subjSaveEdition = new Subject();
  public _AppMenuConfigs = new Subject();
  public subjSaveTemplate = new Subject();
  public subjSelectedTemplate = new Subject();
  public subjLoadedTemplate = new Subject();

  /* Subscriptions */
  private dataSubscription: Subscription;
  private getPSProEditionsSubscription: Subscription;

  // added by Ben
  public jsonNewEdition: any;
  public jsonNewEditionSubject = new Subject();

  public timezones = new Array;
  public updateTimezonePlaceholder = new Subject();

  constructor(private http: HttpClient, private psproService: PSProService,
    private apiService: ApiService, private func: FunctionService) {

      this.apiService.getTimezones().then(res => {
        for (const item in res) {
          for (const values in res[item]["utc"]) {
            let temp = res[item]["utc"][values];
            if (!this.timezones.includes(temp)) {
              this.timezones.push(temp);
            }
          }
        }
      });

    }

  /*loadTemplate(ref: String, func: (outer: any) => any) {
    var a = this;

    a.dataManager.getData("Templates", "Name", ref).subscribe((content: any) => {
      var data = content.Schema;
      a.jsonTemplate = data;
      a.subjLoadedTemplate.next(true);

      func(data);
    });
  }*/

  getSection(sectionName: string) {
    console.log("hereeee");
    let thisSection = [];
    for (let i = this.findFirstPageOfSection(sectionName); i < this.jsonNewEdition.pages; i++) {
      let page = this.jsonNewEdition.pages[i];
      if (page.name == sectionName || page.name == '') {
        thisSection.push(page);
      } else {
        return thisSection;
      }
    }
  }

  findFirstPageOfSection(sectionName: string) {
    for (let i = 0; i < this.jsonNewEdition.pages; i++) {
      let page = this.jsonNewEdition.pages[i];
      if (page.name == sectionName) {
        console.log(i);
        return i;
      }
    }
  }

  hasReplica() {
    if (this.getStaticAppState().replica != null) {
      return true;
    } else {
      return false;
    }
  }

  createSection(sectionPage) {
    this.jsonNewEdition.pages.push(sectionPage);
  }

  createPage() {
    let page = {};
    this.jsonNewEdition.pages.push(page);
  }

  /*saveTemplate() {
    var a = this;
    console.log("Saving template");
    var newjson = {};

    newjson["Schema"] = this.jsonTemplate;
    newjson["Articles"] = parseInt(a.des.noarticles);
    newjson["Image"] = a.des.image;
    newjson["Type"] = "Designer";
    newjson["Reference"] = a.des.ref;
    newjson["ShowNavBar"] = false;
    newjson["ShowFootBar"] = true;
    newjson["IsFrontCover"] = false;
    newjson["IsSpecial"] = false;

    if (a.des.shownavbar != null) {
      newjson["ShowNavBar"] = a.des.shownavbar;
    }

    if (a.des.showfootbar != null) {
      newjson["ShowFootBar"] = a.des.showfootbar;
    }

    if (a.des.isfrontcover != null) {
      newjson["IsFrontCover"] = a.des.isfrontcover;
    }

    if (a.des.isspecial != null) {
      newjson["IsSpecial"] = a.des.isspecial;
    }

    a.dataManager.saveData("Templates", "Name", a.des.templatename, newjson).subscribe((error: any) => {
      a.subjSaveTemplate.next(error);
    });
  }*/

  loadSchedules() {
    this.apiService.getSchedules(this.loadedApplicationGUID).then((schedules: Array<Schedule>) => {
      this.setAppSchedules(schedules);
    });
  }

  inside(events: any) {
    var a = this;
    for (var i in events) {
      if (typeof events[i] === 'object')
        a.inside(events[i]);
      else {
        if (typeof events[i] === 'string') {
          events[i] = a.stripScripts(events[i]);
        }
      }
    }
  }

  stripScripts(s: string) {
    var div = document.createElement('div');
    div.innerHTML = s;
    var scripts = div.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  }

  getData(DEGuid: string, feedID: string) {
    var url = '';
    if (feedID == "" || feedID == undefined) {
      url = 'http://stage.portal.pagesuite.com/api/get_articles_from_title.aspx?eid=' + DEGuid.toUpperCase();
    }
    else {
      if (feedID == "493") {
        url = 'http://portal.pagesuite.com/api/get_data_for_burger.aspx?appid=' + feedID;
      }
      else {
        url = 'http://dev.portal.pagesuite.com/api/get_data_for_burger.aspx?appid=' + feedID;
      }
    }
    console.log(url);

    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('Access-Control-Allow-Origin', '*');

    var options = {
      headers: headers
    };
    return this.http.get<JSON>(url);
    // return null;
  }

  public updateApplication(jsonApplication: App): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = {
      headers: headers
    };
    let body = this.func.recursivelyRemoveUnderscoreJSONKeys(jsonApplication);

    delete body.editions;

    console.log(body);

    return new Promise((resolve, reject) => {
      if(this.allowedToSave) {
        this.http.put(REST.SAVE_APP.toString().replace('{applicationGuid}', jsonApplication.app.guid), body, options)
          .toPromise().then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      } else {
        reject(false);
      }
    });
  }


  /*saveExistingApplication() {
    var a = this;
    a.dataManager.saveData("Application", "ApplicationGUID", a.loadedApplicationGUID, a._ActiveAppliction.value).subscribe(() => {
      a.subjSavedApplication.next();
    });
  }*/

  /*getTemplates() {
    var a = this;
    this.dataManager.getTemplates().subscribe((json) => {
      var content = [];
      Object.keys(json).forEach(function (key) {
        var obj = json[key];
        obj["Name"] = key;
        content.push(obj);
      });

      a.jsonTemplates = content;
      a.getStaticTemplates();
    });
  }*/

  /*getStaticTemplates() {
    var a = this;
    this.dataManager.getStaticTemplates().subscribe((json) => {
      a.jsonStaticTemplates = json;
      a.getDynamicTemplates();
    });
  }*/

  /*getDynamicTemplates() {
    var a = this;
    this.dataManager.getDynamicTemplates().subscribe((json) => {
      a.jsonDynamicTemplates = json;
      a.subjTemplates.next(true);
    });
  }*/

  processFeedEdition(JobGUID: string, callback: any) {
    this.http.get('https://fjqu1xiuwl.execute-api.eu-west-2.amazonaws.com/staging/runjob/' + JobGUID).subscribe(re => {
      if (callback) {
        callback();
      }
    });
  }

  processImages(callback: any) {
    this.http.get('https://1x1g7mjni0.execute-api.eu-west-2.amazonaws.com/dev/screenshots/' + this.loadedEdition).subscribe(re => {
      if (callback) {
        callback();
      }
    });
  }

  findDigitalEditionGUID(EditionGUID: string) {
    var found = "";
    this._ActiveAppliction.value.editions.forEach(function (item, index) {
      if (item["editionguid"] == EditionGUID) {
        found = item["digitaleditionguid"];
      }
    });
    return found;
  }

  findFeedID(EditionGUID: string) {
    var found = "";
    this._ActiveAppliction.value.editions.forEach(function (item, index) {
      if (item["editionGguid"] == EditionGUID) {
        found = item["feedid"];
      }
    });
    return found;
  }

  /*getEdition(EditionGUID: string) {
    var deGUID = this.findDigitalEditionGUID(EditionGUID);
    var feedID = this.findFeedID(EditionGUID);
    var a = this;
    a.loadedEdition = EditionGUID;

    this.dataManager.getData("edition", "editionguid", EditionGUID).subscribe(
      (response: JSON) => {
        if (Object.keys(response).length <= 2) {
          console.log("No Data");
          a.jsonEdition = { "Custom": [], "Articles": {}, "UnsortedData": { "Data": {} }, "Templates": {} };

          a.dataSubscription = a.getData(deGUID, feedID).subscribe((content: JSON) => {
              try {
                console.log(JSON.stringify(content));
                var result = decodeURIComponent(JSON.stringify(content));
              }
              catch (e) {
                console.log("error");
              }

              a.inside(content);
              var keys = Object.keys(content["Data"]);

              if (keys.length == 0) {
                console.log("No data");
                a.subjEdition.next(EditionGUID);
              }
              else {
                var newcontent = {};
                keys.forEach(function (item, index) {
                  console.log(item);
                });

                a.jsonEdition["UnsortedData"] = content;

                // Save JSON into viewstate

                localStorage.setItem("latestedition", JSON.stringify(a.jsonEdition));

                console.log("Got data");

                a.tempdata = a.jsonEdition;
                a.subjEdition.next(EditionGUID);
              }
            },
            (error) => console.log(error)
          )
        }
        else {
          a.jsonEdition = response;
          a.tempdata = a.jsonEdition;
          localStorage.setItem("latestedition", JSON.stringify(a.jsonEdition));
          a.subjEdition.next(EditionGUID);
        }
      });
  }*/

  getApplication(applicationGUID: string, loadEditions?: boolean, loadSchedules?: boolean): Promise<App> {
    console.log(applicationGUID);
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_APPLICATION.toString().replace('{applicationGuid}', applicationGUID)).toPromise().then((jsonApp) => {
        let app = this.apiService.mapAppObj(jsonApp);
        console.log(app);
        this.setActiveApplication(app);
        if (loadEditions) {
          this.loadEditions(app.app.guid, 0, 5);
        }
        if (loadSchedules) {
          this.loadSchedules();
        }

        resolve(app);


      }).catch((err) => reject(err));
    });



    // this._AllApps.value.forEach((app: App) => {
    //   if (app.app != null && app.app.guid == applicationGUID) {
    //
    //     this.setActiveApplication(app);
    //
    //
    //
    //
    //     if (loadEditions) {
    //       // this.loadEditions(app.app.guid);
    //     }
    //     if (loadSchedules) {
    //       this.loadSchedules();
    //     }
    //
    //     return app;
    //   }
    // });

  }

  getWhitelistedIp(accountGuid: string): Observable<any> {
    console.log("GET ALL IPS");
    let url = REST.IP_URL + accountGuid +'/archive';
    return this.http.get(url).map(res => res).pipe(
      catchError(e => throwError(e))
    );
  }

  postWhitelistedIp(accountGuid: string, ipList: {}, requestType: string): Observable<any> {
    console.log("POST ALL IPS");
    let url = REST.IP_URL + accountGuid +'/archive';
    if(requestType === 'post') {
      return this.http.post(url,ipList).pipe(
        catchError(e => throwError(e))
      );
    } else {
      return this.http.patch(url,ipList).pipe(
        catchError(e => throwError(e))
      );
    }
  }


  uploadImage(event) {
    return new Promise((resolve, reject) => {
      let fileList: FileList;
      //Handles various file-types
      if (event instanceof Event) {
        //Changes scope of event for typescript compiler
        event = event;
        fileList = event.target.files;
      }
      else {
        fileList = event;
      }

      let file: File = fileList[0];
      let reader = new FileReader();

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      var options = {
        headers: headers
      };

      reader.addEventListener("load",  () => {

        const kevsData = {
          "accountGUID": this.getStaticAppState().app.accountguid,
          "base64": reader.result,
          "mimeType": file.type
        };

        console.log(kevsData);
        console.log(reader);
        console.log(file);

        this.http.post(REST.UPLOAD_IMAGE, kevsData, options)
          .catch(error => Observable.throw(error))
          .subscribe(
            data => {
              console.log(data);
              resolve(data['url']);
            },
            error => {
              console.log(error);
              reject({
                code: null,
                status: error
              });
            }

          );

      }, false);

      if (file) {
        if(file.size > 3500000) {
          reject({
            code: 413,
            status: "Sorry this image is too large, images need to be less than 3.5mb in size."
          });
        } else {
          reader.readAsDataURL(file);
        }
      }


    });
  }


  loadEditions(appGuid: string, from, count) {
    return new Promise((resolve, reject) => {
      this.apiService.getEditions(appGuid, from, count).then((jsonRes: any) => {
        this._ActiveAppliction.value.editions = jsonRes;
        resolve(jsonRes);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });
  }


  loadApplications(accountGuid): void {
    this.apiService.getAllApps(accountGuid).then((res: Array<App>) => {
      res.sort((a, b) => (a["data"]["app"].name > b["data"]["app"].name) ? 1 : ((b["data"]["app"].name > a["data"]["app"].name) ? -1 : 0));
      this.setAllApps(this.mapAppsObject(res));
      this.isAppsLoaded = true;
    });
  }

  loadApplicationsPromise(accountGuid): Promise<Array<App>> {
    return new Promise<Array<App>>((resolve, reject) => {
      this.apiService.getAllApps(accountGuid).then((res: Array<App>) => {
        res.sort((a, b) => (a["data"]["app"].name > b["data"]["app"].name) ? 1 : ((b["data"]["app"].name > a["data"]["app"].name) ? -1 : 0));
        const applicationsMap = this.mapAppsObject(res);
        this.setAllApps(applicationsMap);
        this.isAppsLoaded = true;
        resolve(applicationsMap);

      });
    });
  }

  getApplicationsPromise(accountGuid): Promise<Array<App>> {
    return new Promise<Array<App>>((resolve, reject) => {
      this.getAllApps().subscribe((apps: Array<App>) => {
        if (apps.length > 0) {
          resolve(apps);
        }
        else {
          this.loadApplicationsPromise(accountGuid).then((apps: Array<App>) => {
            resolve(apps);
          }).catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  getApplicationByGuid(applicationGuid) {
    const applications = this._AllApps.getValue();
    for (var i = 0; i < applications.length; i++) {
      let thisApplication = applications[i];
      if (thisApplication.app.guid === applicationGuid) {
        return thisApplication;
      }
    }
    return null;
  }

  public validateApplication(app: App, isNewApp?: boolean): string[] | boolean {
    let missingAttributes: Array<string> = new Array<string>();

    Object.keys(app.app).forEach((key: string) => {
      isNewApp ? (key == "name" && !app.app[key] || key == "phonetype" && !app.app[key] || key == "tablettype" && !app.app[key] ?
        missingAttributes.push(key) : null) : (!app.app[key] ? missingAttributes.push(key) : null)
    });

    if (missingAttributes.length) {
      return missingAttributes || false;
    }
    else {
      return true;
    }
  }

  public convertAttriubutesForUI(x: Array<string>): Array<string> {
    //TODO: Add more attributes as required
    x.forEach((s: string, i: number) => {
      console.log(s)
      if (s == "name") {
        x[i] = "Name"
      }
      if (s == "tablettype") {
        x[i] = "Application Type"
      }
      if (s == "phonetype") {
        //TODO: When future development for independant phone and tablet is added. Ensure this value is updated accordignly 24/1/2019
        x[i] = "";
      }
    });

    return x;
  }

  public checkShortCode(shortcode: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return new Promise((resolve, reject) => {
      this.http.get(REST.CHECK_SHORTCODE.toString().replace('{shortCode}', shortcode), options).toPromise().then((res) => {
        resolve(res['exists']);
      }).catch(err => {
        reject(err);
      });
    });

  }

  mapSingleAppObject(app) {
    return new App(
      app['data']['app'],
      app['data']['archive'],
      app['data']['menu'],
      app['data']['fonts'],
      app['data']['kiosk'],
      app['data']['footer'],
      app['data']['header'],
      app['data']['replica'],
      app['data']['sections'],
      app['data']['advertising'],
      app['data']['editionrules'],
      app['data']['editions'],
      app['data']['subscriptions'],
      app['data']['feed'],
      app['data']['custom'],
      app['data']['locale'],
      app['data']['masthead'],
      app['data']['portalsettings'],
      app['data']['tabs'],
      app['data']['settings'],
      app['data']['helperscreens'],
      app['data']['categoryselector'],
      app['data']['datacapture']

    );
  }

  mapAppsObject(apps) {
    let applications = [];
    apps.forEach(app => {
      applications.push(new App(
        app['data']['app'],
        app['data']['archive'],
        app['data']['menu'],
        app['data']['fonts'],
        app['data']['kiosk'],
        app['data']['footer'],
        app['data']['header'],
        app['data']['replica'],
        app['data']['sections'],
        app['data']['advertising'],
        app['data']['editionrules'],
        app['data']['editions'],
        app['data']['subscriptions'],
        app['data']['feed'],
        app['data']['custom'],
        app['data']['locale'],
        app['data']['masthead'],
        app['data']['portalsettings'],
        app['data']['tabs'],
        app['data']['settings'],
        app['data']['helperscreens'],
        app['data']['categoryselector'],
        app['data']['datacapture']

      ));
    });
    return applications;
  }

  noscript(strCode) {
    var html = $(strCode.bold());
    html.find('script').remove();
    var content = html.html().trim();
    content.replace(/<p>/g, "");
    content = content.replace(/<\/p>/g, "|||");
    content = content.replace(/<br><br>/g, "|||");

    return $("<div>" + content + "</div>").text();
  }

  /*saveEdition() {
    var a = this;
    console.log("Saving data");

    Object.keys(a.jsonEdition["UnsortedData"]["Data"]).forEach(function (key, value) {
      a.jsonEdition["UnsortedData"]["Data"][key]["Articles"].forEach(function (item, index) {
        item["Section"] = key;

        var content = item["Description"];
        if (item["Description"] != null) {
          content = a.noscript(content);
        }
        item["TextDescription"] = content;
      });
    });

    console.log(a.loadedApplicationGUID + '/' + this.loadedEdition);
    //console.log(JSON.stringify(a.jsonEdition));
    console.log(a.jsonEdition);

    a.dataManager.saveData("Edition", "EditionGUID", a.loadedEdition, a.jsonEdition).subscribe((response) => {
      a.processImages(function () {
        a.dataManager.getData("Application", "ApplicationGUID", a.loadedApplicationGUID).subscribe((contentof) => {
          console.log(contentof);
          Object.keys(contentof["Editions"]).forEach(function (key, value) {
            if (contentof["Editions"][key]["editionGUID"] === a.loadedEdition) {
              contentof["Editions"][key]["image"] = 'https://s3-eu-west-1.amazonaws.com/dev-feed-editions/renderedpages/' + a.loadedEdition + '/page1main.png?refresh=' + response["lastUpdated"];
              contentof["Editions"][key]["lastUpdated"] = response["lastUpdated"];

              console.log(contentof);

              a.dataManager.saveData("Application", "ApplicationGUID", a.loadedApplicationGUID, contentof).subscribe((response) => {
                a.subjSaveEdition.next(true);
              });
            }
          });
        });
      });
    });
  }*/

  /*addReplica() {
    this._ActiveAppliction.value.replica = {
      pubguid: ""
    }
  }

  removeReplica() {
    this._ActiveAppliction.value.replica = <IAppReplica>{};
  }*/
}
