import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SimpleTinyComponent} from '../../apps/editions/view-section/edit-article/shared/tinymce/tinymce.component';
import {FileDropDirective} from "../../apps/file-drop.directive";
import {MatDatepickerModule, MatInputModule, MatNativeDateModule} from "@angular/material";
import {ReactiveFormsModule} from "@angular/forms";
import { PdfDragDropDirective } from '../../apps/pdf-drag-drop.directive';

@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule
  ],
  declarations: [
    SimpleTinyComponent,
    FileDropDirective,
    PdfDragDropDirective,
  ],
  exports: [
    SimpleTinyComponent,
    FileDropDirective,
    PdfDragDropDirective,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule
  ]
})
export class SharedModule { }
