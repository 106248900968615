import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-render-feed-blank',
  templateUrl: './render-feed-blank.component.html',
  styleUrls: ['./render-feed-blank.component.css']
})
export class RenderFeedBlankComponent implements OnInit {
  @Input('rowindex') rowindex: number;
  @Input('cellindex') cellindex: number;
  constructor() { }

  ngOnInit() {
  }

}
