import { Component, Input, OnInit } from "@angular/core";
import { FeedsService } from "../../../../../services/feeds.service";
import { Feed } from "../../../../../models/Feed";
import { LoadingService } from "../../../../../services/loading.service";
import { Article } from "../../../../../models/Article";
import { ToastService } from "../../../../../services/toast.service";
import { EditionService } from "../../../../../services/edition.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { REST } from "../../../../../models/enums/api-end-points";
import { Page, PageType } from "../../../../../models/page";
import { PageArticle } from "../../../../../models/PageArticle";
import { FunctionService } from "../../../../../services/function.service";
import { ModalService } from "../../../../../modal";

@Component({
  selector: "app-refresh-articles",
  templateUrl: "./refresh-articles.component.html",
  styleUrls: ["./refresh-articles.component.css"],
})
export class RefreshArticlesComponent implements OnInit {
  feeds: Feed[] = [];
  selectedFeed: Feed;
  feedContent: any[];
  activeArticleIndex: number;
  @Input("page") page: Page;

  constructor(
    private feedsService: FeedsService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private editionService: EditionService,
    private http: HttpClient,
    private func: FunctionService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.feedsService.getFeedArray().subscribe((feeds) => {
      this.feeds = feeds.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      });
      // this.updateSelectedFeed(this.feeds[0].feedguid);
    });
  }

  updateSelectedFeed(feedGuid) {
    this.feeds.forEach((feed: Feed) => {
      if (feed.feedguid == feedGuid) {
        this.selectedFeed = feed;
        this.getFeedContent(this.selectedFeed);
      }
    });
  }

  async getFeedContent(feed: Feed) {
    try {
      this.loadingService.setLoading();
      if (feed.preprocess) {
        const preprocessRes = await this.http.get(feed.preprocess).toPromise();
        console.log(preprocessRes);
      }
    } catch (e) {
      this.loadingService.setFinished();
    }
    this.feedsService.getParsedFeedContent(feed.feedguid).subscribe(
      (content: Article[]) => {
        console.log(content);
        this.feedContent = content;
        this.loadingService.setFinished();
      },
      (error1) => {
        this.loadingService.setFinished();
        this.toastService.failed(error1);
      }
    );
  }

  setActiveArticle(article, i) {
    console.log(article);
    this.activeArticleIndex = i;
  }

  getArticleImage(article) {
    if (article && article.Images) {
      if (article.Images[0]) {
        return article.Images[0].Image;
      } else {
        return "";
      }
    }
  }

  addArticle() {
    if (this.activeArticleIndex || this.activeArticleIndex == 0) {
      console.log(this.page);
      this.postNewArticle();
    } else {
      this.toastService.failed("Please select an article");
    }
  }

  postNewArticle() {
    let article = new Article(
      "",
      this.feedContent[this.activeArticleIndex].Author,
      this.feedContent[this.activeArticleIndex].Description,
      this.feedContent[this.activeArticleIndex].DescriptionNoScript,
      this.feedContent[this.activeArticleIndex].Headline,
      this.feedContent[this.activeArticleIndex].ShortHeadline,
      "",
      this.feedContent[this.activeArticleIndex].Images,
      "",
      [],
      this.page.section,
      "",
      this.feedContent[this.activeArticleIndex].TextDescription,
      this.feedContent[this.activeArticleIndex].UniqueID,
      [],
      "",
      this.feedContent[this.activeArticleIndex].SubHeadline,
      "",
      true,
      this.feedContent[this.activeArticleIndex].Category,
      this.feedContent[this.activeArticleIndex].TwitterHandle,
      new Date().toISOString()
    );

    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    var options = {
      headers: headers,
    };

    article = this.func.recursivelyRemoveUnderscoreJSONKeys(article);
    article = this.func.recursivelyLowercaseJSONKeys(article);

    let postObject = {
      article: article,
      sectionGuid: this.editionService.getSection(this.page.section)
        .sectionguid,
      sectionIndex: this.editionService.getSection(this.page.section).articles
        .length,
    };

    this.loadingService.setLoading();

    this.http.put(REST.NEW_ARTICLE.toString(), postObject, options).subscribe(
      (responseMsg) => {
        //update Obj or Error
        this.loadingService.setFinished();
        console.log(responseMsg);
        if (responseMsg["articleguid"]) {
          // this.update();
          this.toastService.success("Article Saved");
          article.articleguid = responseMsg["articleguid"];
          this.editionService.addArticleToSection(article, this.page.section);
          this.page.articles = [
            new PageArticle(this.page.section, article.articleguid),
          ];
          let newPage = this.page;
          this.editionService.addPageToEdition(newPage);
          console.log(this.editionService.jsonedition);
          this.modalService.destroy();
          this.editionService.editionHasChanged.next(true);
        } else {
          this.toastService.failed();
          console.error("Article was not saved.");
        }
      },
      (error) => console.error(error)
    );
  }
}
