import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoadingService } from "../../../../services/loading.service";
import { ApplicationsService } from "../../../../services/applications.service";
import { ApiService } from "../../../../services/api.service";
import { Router } from "@angular/router";
import { ModalService } from "../../../../modal/services/modal.service";
import { EditionService } from "../../../../services/edition.service";

@Component({
  selector: "app-add-edition-modal",
  templateUrl: "./add-edition-modal.component.html",
  styleUrls: ["./add-edition-modal.component.css"],
})
export class AddEditionModalComponent implements OnInit, OnDestroy {
  editionName: string = '';
  flow = "sectionToSection";

  constructor(
    public loading: LoadingService,
    public appService: ApplicationsService,
    public apiService: ApiService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  createNewEdition() {
    if (this.editionName.length < 1) {
      return;
    }
    this.apiService
      .createBlankEdition(
        this.editionName,
        this.flow,
        this.appService.getStaticAppState().app.guid
      )
      .then((res) => {
        console.log(res.editionGuid);
        this.modalService.destroy();
        let url = this.router.url;
        url = url.replace("/new", "/");
        this.router.navigate([url + res.editionGuid + "/2"]);
      });
  }

  selectChoice(choice) {
    this.flow = choice;
  }
}
