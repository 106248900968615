import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/User';
import {Location} from "@angular/common";

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

  constructor(public authService: AuthService, private urlLocation: Location) { }

  public user: User;
  public showDropDown = false;

  ngOnInit() {
    this.authService.getUser().subscribe((user: User) => {
      this.user = user;
    })
  }

  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  onLogout() {
    this.authService.logout();
  }

  back() {
    this.urlLocation.back();
  }
}
