import {LoadingService} from './loading.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {REST} from '../models/enums/api-end-points';

@Injectable({
  providedIn: 'root'
})

export class PSProService {
  constructor(private http: HttpClient, private loadsrc: LoadingService) {
  }

  loadPublications(accountGUID: string) {
    return new Promise((resolve, reject) => {
      const url = REST.GET_PUBLICATIONS.replace("{accountGuid}", accountGUID);
      //const url = 'https://mvplc77498.execute-api.eu-west-1.amazonaws.com/dev/replica/account/' + accountGUID + '/Publications';
      this.http.get<JSON>(url).map(data => {
        return data;
      }).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        console.log(err);
      });
    });
  }

  getPublications(accountGUID: any[]) {
    const promises = [];
    accountGUID.forEach(item => {
       promises.push(this.loadPublications(item));
    });

    return new Promise((resolve, reject) => {
      let arrAccountPublications = [];
      Promise.all(promises).then(values => {
        values.forEach(array => {
          arrAccountPublications = arrAccountPublications.concat(array);
        });
        resolve(arrAccountPublications);
      });
    });
  }
}
