import {Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit} from '@angular/core';
import {Edition} from "../../../../models/edition";
import {Article} from "../../../../models/Article";
import {EditionService} from "../../../../services/edition.service";
import {ModalService} from "../../../../modal/services/modal.service";
import {MessageDialogService} from "../../../../modules/message-dialog-module/services/message-dialog.service";
import {PageType} from "../../../../models/page";
import {Prompt} from "../../../../modules/message-dialog-module/models/Prompt";
import {Router} from "@angular/router";
import {ApplicationsService} from "../../../../services/applications.service";
import {LoadingService} from "../../../../services/loading.service";
import {ToastService} from "../../../../services/toast.service";

@Component({
  selector: 'existing-articles',
  templateUrl: './existing-articles.component.html',
  styleUrls: ['./existing-articles.component.css']
})
export class ExistingArticlesComponent implements OnInit {

  @Input('edition') edition: Edition;
  @Input('sectionName') sectionName: string;
  @Input('isSectionFront') isSectionFront: boolean;
  @Input('isMultiArticle') isMultiArticle: boolean;
  @Input('index') multiArticleIndex: number;
  @Input('pageguid') pageguid: string;
  @Input('sectionFrontIndex') sectionFrontIndex: number;
  @Input('level') level: string;

  @ViewChild('articleCategories') articleCategories;


  @Output() callback: EventEmitter<any> = new EventEmitter();

  articles: Article[] = new Array<Article>();
  private selectedArticle: Article;
  public selectedIndex: number = -1;


  constructor(private editionService: EditionService, private modalService: ModalService,
              private messageService: MessageDialogService, private router: Router,
              private appService: ApplicationsService, private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    if (!this.edition) {
      if (this['editionGuid']) {
        this.editionService.getEdition(this['editionGuid']).then((res: Edition) => {
          this.edition = res;
        });
      }
      else {
        this.edition = this.editionService.jsonedition;
      }
    }
  }


  updateSelectedArticles(data) {
    this.selectedIndex = -1;
    this.articles = data.articles;
  }

  setActiveArticle(article: Article, index: number) {
    this.selectedIndex = index;
    this.selectedArticle = article;
  }

  getArticleImage(article) {
    if(article && article.images) {
      if(article.images[0]) {
        return article.images[0].image;
      } else {
        return "";
      }
    }
  }

  confirm() {
    if (this.selectedArticle) {
      console.log(this.sectionFrontIndex);
      if (this.isSectionFront) {
        let pageIdx = this.editionService.getFirstPageOfSection(this.sectionName) + this.sectionFrontIndex;
        this.pageguid = this.editionService.jsonedition.pages[pageIdx].pageguid;
      }

      //Add to exisiting page
      if (this.pageguid) {
        //Add to specific article
        if (this.isMultiArticle) {

          this.editionService.addArticleToPage(this.pageguid, this.selectedArticle.articleguid, this.multiArticleIndex);
        }
        else {
          this.editionService.addArticleToPage(this.pageguid, this.selectedArticle.articleguid);
        }
      }
      else {
        //Create new page and add //TODO: Add articles multiple to page 10/09/2018
        let newPage = this.editionService.createNewPage(this.sectionName, PageType.TEMPLATEARTICLE, this.level, this.selectedArticle);
        this.editionService.addPageToEdition(newPage);
      }
      this.editionService.editionHasChanged.next(true);
      this.modalService.destroy();
    }
    else {
      let prompt = new Prompt("Uh Oh!", "You have not selected an article", "ok");

      prompt.positiveCallback = () => {
        this.messageService.removeDialog();
      };
      prompt.negativeCallback = () => {
        this.messageService.removeDialog();
      };

      this.messageService.createDialog(prompt);
    }
  }

  newArticle() {
    this.router.navigate([this.router.url + '/new'], {queryParams: {
      section: this.sectionName
    }});
    this.modalService.destroy();
  }

  getDateCreated(articleDate) {
    if(articleDate) {
      if(this.appService.getStaticAppState().locale) {
        return new Date(articleDate).toLocaleTimeString(this.appService.getStaticAppState().locale.languageCode, {timeZone: this.appService.getStaticAppState().locale.timeZoneIdentifier});
      }
      return new Date(articleDate).toLocaleTimeString('en-GB', {timeZone: 'Europe/London'});
    }
    return null;

  }

  async deleteArticle(articleGuid: string) {
    let prompt = new Prompt("Delete Article", "Are you sure you want to delete this article? This operation " +
      "cannot be undone and you won't be able to add this article back into this edition", "yes-no");

    prompt.positiveCallback = async () => {
      this.loadingService.setLoading();
      let res = await this.editionService.deleteArticle(articleGuid);
      console.log(res);
      if(res === true) {
        this.messageService.removeDialog();
        this.loadingService.setFinished();
        this.toastService.success("Article deleted successfully");
        this.edition = this.editionService.jsonedition;
        this.articleCategories.updateArticleList();
      } else if(res === false) {
        this.loadingService.setFinished();
        let prompt2 = new Prompt("Article already exists", "This article exists within the page flow. Please remove it before deleting it from the edition.", "ok");
        prompt2.positiveCallback = () => {
          this.messageService.removeDialog();
        };
        prompt2.negativeCallback = () => {
          this.messageService.removeDialog();
        };
        this.messageService.createDialog(prompt2);
      } else {
        this.loadingService.setFinished();
        this.messageService.removeDialog();
        this.toastService.success("Article failed to delete, please try again");
      }

    };
    prompt.negativeCallback = () => {
      this.messageService.removeDialog();
    };

    this.messageService.createDialog(prompt);

  }
}
