import { Component, OnInit } from '@angular/core';
import {FeedTemplateColumn} from '../../../models/FeedTemplateColumn';

@Component({
  selector: 'app-render-feed-papers-collection',
  templateUrl: './render-feed-papers-collection.component.html',
  styleUrls: ['./render-feed-papers-collection.component.css']
})
export class RenderFeedPapersCollectionComponent implements OnInit {

  constructor() { }
  public items : [FeedTemplateColumn];
  ngOnInit()
  {

  }

}
