import { ArticleImage } from "./ArticleImage";
import { Video } from "./Video";

export class Article {
  private _articleguid: string;
  private _shortheadline: string;
  private _author: string;
  private _description: string;
  private _descriptionnoscript: string;
  private _headline: string;
  private _image: string;
  private _images: Array<ArticleImage>; /* Image Obj to be created: 17/08/2018 */
  private _lastupdated: string;
  private _related: Array<string>;
  private _section: string;
  private _sharelink: string;
  private _textdescription: string;
  private _uniqueid: string;
  private _videos: Array<Video>; /* Video Obj to be created: 17/08/2018 */
  private _sectionfrontheadline: string;
  private _subheadline: string;
  private _sectionfrontsubheadline: string;
  private _isvisible: boolean;
  private _category: string;
  private _twitterhandle: string;
  private _datecreated: string;

  public constructor(
    articleguid: string,
    author: string,
    description: string,
    descriptionnoscript: string,
    headline: string,
    shortheadline: string,
    image: string,
    images: Array<any>,
    lastupdated: string,
    related: Array<any>,
    section: string,
    sharelink: string,
    textdescription: string,
    uniqueid: string,
    videos: Array<Video>,
    sectionfrontheadline: string,
    subheadline: string,
    sectionfrontsubheadline: string,
    isvisible: boolean,
    category: string,
    twitterhandle: string,
    datecreated: string
  ) {
    this._articleguid = articleguid;
    this._author = author;
    this._description = description;
    this._descriptionnoscript = descriptionnoscript;
    this._headline = headline;
    this._shortheadline = shortheadline;
    this._image = image;
    this._images = images;
    this._lastupdated = lastupdated;
    this._related = related;
    this._section = section;
    this._sharelink = sharelink;
    this._textdescription = textdescription;
    this._uniqueid = uniqueid;
    this._videos = videos;
    this._sectionfrontheadline = sectionfrontheadline;
    this._subheadline = subheadline;
    this._sectionfrontsubheadline = sectionfrontsubheadline;
    this._isvisible = isvisible;
    this._category = category;
    this._twitterhandle = twitterhandle;
    this._datecreated = datecreated;
  }

  /**
   * Getter articleguid
   * @return {string}
   */
  public get articleguid(): string {
    return this._articleguid;
  }

  /**
   * Getter author
   * @return {string}
   */
  public get author(): string {
    return this._author;
  }

  /**
   * Getter description
   * @return {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Getter descriptionnoscript
   * @return {string}
   */
  public get descriptionnoscript(): string {
    return this._descriptionnoscript;
  }

  /**
   * Getter headline
   * @return {string}
   */
  public get headline(): string {
    return this._headline;
  }
  /**
   * Getter shortheadline
   * @return {string}
   */
  public get shortheadline(): string {
    return this._shortheadline;
  }

  /**
   * Getter image
   * @return {string}
   */
  public get image(): string {
    return this._image;
  }

  /**
   * Getter images
   * @return {Array<any>}
   */
  public get images(): Array<any> {
    return this._images;
  }

  /**
   * Getter lastupdated
   * @return {string}
   */
  public get lastupdated(): string {
    return this._lastupdated;
  }

  /**
   * Getter related
   * @return {Array<string>}
   */
  public get related(): Array<string> {
    return this._related;
  }

  /**
   * Getter section
   * @return {string}
   */
  public get section(): string {
    return this._section;
  }

  /**
   * Getter sharelink
   * @return {string}
   */
  public get sharelink(): string {
    return this._sharelink;
  }

  /**
   * Getter textdescription
   * @return {string}
   */
  public get textdescription(): string {
    return this._textdescription;
  }

  /**
   * Getter uniqueid
   * @return {string}
   */
  public get uniqueid(): string {
    return this._uniqueid;
  }

  public get videos() {
    return this._videos;
  }

  /**
   * Setter articleguid
   * @param {string} value
   */
  public set articleguid(value: string) {
    this._articleguid = value;
  }

  /**
   * Setter author
   * @param {string} value
   */
  public set author(value: string) {
    this._author = value;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

  /**
   * Setter descriptionnoscript
   * @param {string} value
   */
  public set descriptionnoscript(value: string) {
    this._descriptionnoscript = value;
  }

  /**
   * Setter headline
   * @param {string} value
   */
  public set headline(value: string) {
    this._headline = value;
  }

  /**
   * Setter shortheadline
   * @param {string} value
   */
  public set shortheadline(value: string) {
    this._shortheadline = value;
  }

  /**
   * Setter image
   * @param {string} value
   */
  public set image(value: string) {
    this._image = value;
  }

  /**
   * Setter images
   * @param {Array<any>} value
   */
  public set images(value: Array<any>) {
    this._images = value;
  }

  /**
   * Setter lastupdated
   * @param {string} value
   */
  public set lastupdated(value: string) {
    this._lastupdated = value;
  }

  /**
   * Setter related
   * @param {Array<string>} value
   */
  public set related(value: Array<string>) {
    this._related = value;
  }

  /**
   * Setter section
   * @param {string} value
   */
  public set section(value: string) {
    this._section = value;
  }

  /**
   * Setter sharelink
   * @param {string} value
   */
  public set sharelink(value: string) {
    this._sharelink = value;
  }

  /**
   * Setter textdescription
   * @param {string} value
   */
  public set textdescription(value: string) {
    this._textdescription = value;
  }

  /**
   * Setter uniqueid
   * @param {string} value
   */
  public set uniqueid(value: string) {
    this._uniqueid = value;
  }

  public set videos(value: Video[]) {
    this._videos = value;
  }

  get sectionfrontheadline(): string {
    return this._sectionfrontheadline;
  }

  set sectionfrontheadline(value: string) {
    this._sectionfrontheadline = value;
  }

  get subheadline(): string {
    return this._subheadline;
  }

  set subheadline(value: string) {
    this._subheadline = value;
  }

  get sectionfrontsubheadline(): string {
    return this._sectionfrontsubheadline;
  }

  set sectionfrontsubheadline(value: string) {
    this._sectionfrontsubheadline = value;
  }

  get isvisible(): boolean {
    return this._isvisible;
  }

  set isvisible(value: boolean) {
    this._isvisible = value;
  }

  get category(): string {
    return this._category;
  }

  set category(value: string) {
    this._category = value;
  }

  get twitterhandle(): string {
    return this._twitterhandle;
  }

  set twitterhandle(value: string) {
    this._twitterhandle = value;
  }

  get datecreated(): string {
    return this._datecreated;
  }

  set datecreated(value: string) {
    this._datecreated = value;
  }
}
