import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { LoadingService } from './loading.service';
import { HttpHeaders } from '@angular/common/http';
import { ApplicationsService } from "./applications.service";
import { FeedsService } from './feeds.service';
import { App } from '../models/App';
import { User } from '../models/User';
import { Feed } from '../models/Feed';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import {REST} from "../models/enums/api-end-points";
import Amplify, { Auth } from 'aws-amplify';
import {environment} from "../../environments/environment";
import {parseDomain, ParseResultType} from "parse-domain";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private $loggedInUser: BehaviorSubject<User>;

  public cognitoUser;

  public getUser(): Observable<User> {
    return this.$loggedInUser.asObservable();
  }
  public setUser(user: User): void {
    this.$loggedInUser.next(user);
  }
  public getUserAccountGuid(): string {
    return this.$loggedInUser.value.accountguid;
  }

  public pagesuiteAccountGUID(): any[] {
    if(typeof this.$loggedInUser.value.data["PageSuiteProfessionalAccountGUID"] != 'undefined') {
      return this.$loggedInUser.value.data["PageSuiteProfessionalAccountGUID"];
    } else {
      return [];
    }

  }

  constructor(private http: HttpClient,
              private router: Router,
              private loadingsrv: LoadingService,
              private feedService: FeedsService) {
    this.$loggedInUser = new BehaviorSubject<User>(new User("", "", "", false, "", "", "", "", {}));

    const parseResult = parseDomain(
      window.location.hostname
    );

    let cookieDomain = window.location.hostname;

    if (parseResult.type === ParseResultType.Listed) {
      const {subDomains, domain, topLevelDomains} = parseResult;

      console.log(subDomains); // ["www", "some"]
      console.log(domain); // "example"
      console.log(topLevelDomains); // ["co", "uk"]
      cookieDomain = domain;
      topLevelDomains.forEach(tld => {
        cookieDomain = cookieDomain + '.' + tld;
      });
    } else {
    }

    console.log(cookieDomain);


    Amplify.configure({
      Auth: {
        region: 'eu-west-1',
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        cookieStorage: {
          // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: cookieDomain,
          // OPTIONAL - Cookie path
          path: '/',
          // OPTIONAL - Cookie expiration in days
          expires: 365,
          // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: "strict",
          // OPTIONAL - Cookie secure flag
          // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: false,
        },
        oauth: {
          domain: environment.oAuthDomain,
          scope: ['email', 'openid'],
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    });

  }

  async logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
      Auth.signOut();
    }

    this.router.navigate(['/signin']);
  }

  accountGUID(): string {
    return this.$loggedInUser.value.accountguid;
  }




  async signin(email: string, password: string) {

    try {
      this.cognitoUser = await Auth.signIn(email, password);
      console.log(this.cognitoUser);
      return this.cognitoUser;
  } catch (error) {
      console.log('error signing in', error);
      throw error;
  }

}

  async resetPassword(newPassword) {
    const reset = await Auth.completeNewPassword(this.cognitoUser, newPassword,  {email: this.cognitoUser['username']});
    console.log(reset);
  }

  async getCogntioUser() {
    return await Auth.currentAuthenticatedUser();
  }


  async isAuthenticated() {
    try {
      if(await Auth.currentAuthenticatedUser()) {
        this.cognitoUser = await Auth.currentAuthenticatedUser();
        console.log(this.cognitoUser);
        if(this.cognitoUser.username.indexOf('newscorpokta') !== -1) {
          this.$loggedInUser.next(new User(environment.ncoAccountGuid, this.cognitoUser['signInUserSession']['idToken']['payload']['email'], "", true, "", "", this.cognitoUser['username'], "", {}))
        } else {
          this.$loggedInUser.next(new User(this.cognitoUser['attributes']['custom:accountguid'], this.cognitoUser['attributes']['email'], "", true, "", "", this.cognitoUser['attributes']['sub'], "", {}))
        }
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      // if(this.getFederatedUser()) {
      //   return true;
      // }
      return false;
    }
  }

  // async setFederatedUser(idToken, accessToken) {
  //
  // }

  async getFederatedUser() {
    if(localStorage.getItem('id_token')) {
      let idToken = JSON.parse(localStorage.getItem('id_token'));
      console.log(idToken);
      // this.cognitoUser['signInUserSession']['idToken']['jwtToken'] = localStorage.getItem('id_token');
      // this.$loggedInUser.next(new User("this.cognitoUser['attributes']['custom:accountguid']", "email", "", true, "", "", "sub", "", {}))
      return true;
    }
  }

  async sendResetCode(username) {
    return Auth.forgotPassword(username);
  }

  async resetPasswordFromCode(username, code, newPassword) {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }
}
