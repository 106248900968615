import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Template} from '../models/Template';
import {REST} from '../models/enums/api-end-points';
import {Feed} from '../models/Feed';
import {FunctionService} from './function.service';
import {App} from '../models/App';
import {BehaviorSubject, Observable} from 'rxjs';
import {Flatplan} from '../models/Flatplan';
import {Schedule} from '../models/Schedule';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  _TemplateApi = REST.GET_GLOBAL_TEMPLATES;
  // all the templates the account has access to
  public templates = {
    all: [],
    global: [],
    account: []
  };
  // just the templates in this account
  //public accountTemplates: Template[] = [];
  // list of all the default templates
  //public globalTemplates: Template[] = [];

  public templatesByGuid = {};

  private _accounttemplates: BehaviorSubject<Array<Template>> = new BehaviorSubject<Array<Template>>(new Array<Template>());
  private _globaltemplates: BehaviorSubject<Array<Template>> = new BehaviorSubject<Array<Template>>(new Array<Template>());

  constructor(private http: HttpClient,
              private func: FunctionService,
              private apiService: ApiService,
              private authService: AuthService) { }
  public mapTemplateObj(template): Template {
    //TODO: 17 / 09 / 2018 Update the backend to match lowercase attributes and remove this function
    template = this.func.recursivelyLowercaseJSONKeys(template);
    return new Template(
      template.templateguid,
      template.data,
      template.accountguid
    );
  }

  mapTemplatesObject(temps) {
    let templates : Template[] = [];
    temps.forEach(temp => {
      temp = this.func.recursivelyLowercaseJSONKeys(temp);
      templates.push(new Template(
        temp['templateguid'],
        temp['data'],
        temp['accountguid']
      ));
    });
    //this.templates.all = templates;
    return templates;
  }

  getAllTemplatesForAccount() {
    return new Promise((resolve, reject) => {
      if (this.templates.all.length > 0) {
        resolve(this.templates);
      } else {
        this.apiService.getGlobalTemplates().then((globalTemplates: Template[]) => {
          this.templates.global = globalTemplates;
          this.apiService.getAccountTemplates(this.authService.accountGUID()).then((accountTemplates: Template[]) => {
            this.templates.account = accountTemplates;
            this.templates.all = this.templates.global.concat(this.templates.account);
            this.setTemplatesObject();

            resolve(this.templates);
          }).catch((err) => {
            reject(err);
          })
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }

  async getTemplateFromUrlPromise(url: string) {
    let allTemplates = await this.getAllTemplatesForAccount();
    return this.getTemplateFromUrl(url);
  }

  setTemplatesObject() {
    for (var i = 0; i < this.templates.all.length; i++) {
      const thisTemplate = this.templates.all[i];
      this.templatesByGuid[thisTemplate.templateguid] = thisTemplate;
    }
  }

  getTemplateByGuid(templateGuid: string) {
    if (Object.keys(this.templatesByGuid).length > 0) {
      return this.templatesByGuid[templateGuid];
    }
    else {
      return null;
    }
  }

  public getAccountTemplates(): Observable<Array<Template>> {
    return this._accounttemplates.asObservable();
  }
  public setAccountTemplates(templates: Array<Template>): void {
    this.templates.account = this.templates.account.concat(templates);
    this._accounttemplates.next(templates);
  }

  public getGlobalTemplates(): Observable<Array<Template>> {
    return this._globaltemplates.asObservable();
  }
  public setGlobalTemplates(templates: Array<Template>): void {
    this.templates.global = this.templates.global.concat(templates);
    this._globaltemplates.next(templates);
  }

  public getTemplateWithPromise() {

  }

  public getTemplateFromUrl(url: string): Template {
    for(let temp of this.templates.all) {
      if (temp.data.html == url) {
        console.log("found temppalte")
        return temp;
      }
    }
  }

  public matchTemplateToPlatform(template: Template, platformId: number): Template {
    /* For desktop to match Section Fronts to Tablet Section Fronts */
    if (template.data.type !== 'article' && platformId === 4) {
      platformId = 2;
    }

    /* If we find the template with the same name, against the platform id return it */
    for (let i = 0; i < this.templates.all.length; i++) {
      const thisTemplate = this.templates.all[i];
      if (thisTemplate.data.platform === platformId && thisTemplate.data.name === template.data.name) {
        return thisTemplate;
      }
    }
    /* No template found - return null */
    return null;
  }

  public getTemplateArticleCount(guid: string): number {
    console.log(this.templatesByGuid[guid]);
    let thisTemplate = this.templatesByGuid[guid];
    if (thisTemplate != null) {
      return thisTemplate.data.articles;
    }
    return 0;

  }

}
