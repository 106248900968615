export interface FeedValidationMap{
  ChosenName: string;
  FeedMappingTypeID: string;
  Filter: string;
  FullPath: string;
  Grouping: string;
  IsSelected: string;
  ItemCount: string;
  Level: string;
  NodeName: string;
  Relative: string;
  RelativePath: string;
}

export interface FeedMapping {
  feednode: string;
  fullpath: string;
  node: string;
}

export interface FeedArticle {
  headline: string;
  lastupdated: string;
  guid: string;
  author: string;
  videourl: string;
}

export class Feed {

  private _name: string;
  private _path: string;
  private _url: string;
  private _searchnode: string;
  private _accountguid: string;
  private _feedguid: string;
  private _lastupdated: string;
  private _mappings: Array<FeedMapping>;
  private _isdeleted: boolean;
  private _preprocess: string;

  constructor(name: string, path: string, url: string, searchnode: string, accountguid: string, feedguid: string, lastupdated: string, mappings: Array<FeedMapping>, isdeleted: boolean, preprocess: string) {
    this._name = name;
    this._path = path;
    this._url = url;
    this._searchnode = searchnode;
    this._accountguid = accountguid;
    this._feedguid = feedguid;
    this._lastupdated = lastupdated;
    this._mappings = mappings;
    this._isdeleted = isdeleted;
    this._preprocess = preprocess;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter path
   * @return {string}
   */
  public get path(): string {
    return this._path;
  }

  /**
   * Getter url
   * @return {string}
   */
  public get url(): string {
    return this._url;
  }

  /**
   * Getter searchnode
   * @return {string}
   */
  public get searchnode(): string {
    return this._searchnode;
  }

  /**
   * Getter accountguid
   * @return {string}
   */
  public get accountguid(): string {
    return this._accountguid;
  }

  /**
   * Getter geedguid
   * @return {string}
   */
  public get feedguid(): string {
    return this._feedguid;
  }

  /**
   * Getter lastupdated
   * @return {string}
   */
  public get lastupdated(): string {
    return this._lastupdated;
  }

  /**
   * Getter mappings
   * @return {Array<FeedMapping>}
   */
  public get mappings(): Array<FeedMapping> {
    return this._mappings;
  }

  /**
   * Getter isdeleted
   * @return {boolean}
   */
  public get isdeleted(): boolean {
    return this._isdeleted;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter path
   * @param {string} value
   */
  public set path(value: string) {
    this._path = value;
  }

  /**
   * Setter url
   * @param {string} value
   */
  public set url(value: string) {
    this._url = value;
  }

  /**
   * Setter searchnode
   * @param {string} value
   */
  public set searchnode(value: string) {
    this._searchnode = value;
  }

  /**
   * Setter accountguid
   * @param {string} value
   */
  public set accountguid(value: string) {
    this._accountguid = value;
  }

  /**
   * Setter feedguid
   * @param {string} value
   */
  public set feedguid(value: string) {
    this._feedguid = value;
  }

  /**
   * Setter lastupdated
   * @param {string} value
   */
  public set lastupdated(value: string) {
    this._lastupdated = value;
  }

  /**
   * Setter mappings
   * @param {Array<FeedMapping>} value
   */
  public set mappings(value: Array<FeedMapping>) {
    this._mappings = value;
  }

  /**
   * Setter isdeleted
   * @param {boolean} value
   */
  public set isdeleted(value: boolean) {
    this._isdeleted = value;
  }

  get preprocess(): string {
    return this._preprocess;
  }

  set preprocess(value: string) {
    this._preprocess = value;
  }
}

export interface FeedItem {
  Author: string;
  Description: string;
  GUID: string;
  Headline: string;
  ImageCaption: string;
  ImageURL: string;
  Images: Array<string>;
  LastUpdated: string;
  ShareLink: string;
  VideoURL: string;
  Videos: Array<string>;
}
