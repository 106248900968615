import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./home.component";
import { AuthGuard } from '../services/auth-guard.service';


const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      {
        path: '', loadChildren: '../apps/apps.module#AppsModule', canActivate: [AuthGuard]
      },
      {
        path: 'apps', 
        data: {
          label: 'apps'
        }, 
        loadChildren: '../apps/apps.module#AppsModule', canActivate: [AuthGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})
export class HomeRoutingModule { }
