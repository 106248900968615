import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Template} from '../../../models/Template';
import {ModalService} from '../../../modal';
import {untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Flatplan} from '../../../models/Flatplan';
import {FlatplanmanagerService} from '../../../services/flatplanmanager.service';
import {FlatplanSection} from '../../../models/FlatplanSection';
import {FlatplanPage} from '../../../models/FlatplanPage';
import {FlatplanPlatform} from '../../../models/FlatplanPlatform';
import {TemplateService} from '../../../services/template.service';
import {Feed} from '../../../models/Feed';
import {forEach} from '@angular/router/src/utils/collection';
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-selecttemplate',
  templateUrl: './selecttemplate.component.html',
  styleUrls: ['./selecttemplate.component.css']
})
export class SelecttemplateComponent implements OnInit, OnDestroy {

  constructor(
    private modalService: ModalService,
    private flatplanservice : FlatplanmanagerService,
    private templateService : TemplateService,
    private loadingService: LoadingService) { }

  @Input('flatplanSection') flatplanSection: FlatplanSection;
  @Input('flatplanPage') flatplanPage: FlatplanPage;
  @Input('sectionsorarticles') sectionsorarticles: string;
  @Input('load') load: boolean = false;
  @Input('platformid') platformid: number;
  @Input('articleCount') articleCount: number;

  public device = "tablet";
  public isFullPage = false;
  public chosenTemplate = null;
  public phoneTemplate : Template = null;
  public tabletTemplate : Template = null;
  public desktopTemplate: Template = null;
  public temps: Array<Template> = new Array<Template>();


  ngOnInit() {

    console.log(this.flatplanSection);
    console.log(this.flatplanPage);

    switch (this.platformid) {
      case 2:
        this.device = 'tablet';
        break;
      case 3:
        this.device = 'phone';
        break;
      case 4:
        this.device = 'desktop';
        break;
    }

    this.templateService.getGlobalTemplates().pipe(untilComponentDestroyed(this)).subscribe((templates: Array<Template>) => {
      console.log(templates);
      this.temps = this.temps.concat(templates);

      this.loadingService.setFinished();

    });
  }

  ngOnDestroy(): void {

  }

  setArticleCountForFlatplanPage(flatplanPage: FlatplanPage) {
    let maxArticleCount = 0;
    for (var i = 0; i < flatplanPage.platforms.length; i++) {
      let thisPlatform = flatplanPage.platforms[i];
      maxArticleCount = Math.max(maxArticleCount, this.templateService.getTemplateArticleCount(thisPlatform.templateguid));
    }
    flatplanPage.articleCount = maxArticleCount + "";
  }

  select(temp : Template) {
    this.chosenTemplate = temp;
  }

  setAllPlatformTemplatesFromTemplate() {
    this.tabletTemplate = this.getTemplateForPlatform(2);
    this.phoneTemplate = this.getTemplateForPlatform(3);
    this.desktopTemplate = this.getTemplateForPlatform(4);
  }

  getTemplateForPlatform(platformId: number): Template {
    if (platformId === this.platformid) {
      return this.chosenTemplate;
    } else {
      const platformTemplate = this.templateService.matchTemplateToPlatform(this.chosenTemplate, platformId);
      if (platformTemplate != null) {
        return platformTemplate;
      }
    }
    return this.chosenTemplate;
  }

  getFlatplanPlatform(): FlatplanPlatform {
    for (let i = 0; i < this.flatplanPage.platforms.length; i++) {
      let thisFlatplanPlatform = this.flatplanPage.platforms[i];
      if (thisFlatplanPlatform.id === this.platformid) {
        return thisFlatplanPlatform;
      }
    }
    // if no platform found with given platformid, create the platform id
    let newPlatform = new FlatplanPlatform(this.chosenTemplate.templateguid, this.platformid);
    this.flatplanPage.platforms.push(newPlatform);
    return newPlatform;

  }

  savePage() {
    /* platforms.length is 0 when creating a new page
       In this scenario we need to set all the templates, based off the template chosen
     */

    if (this.flatplanPage.platforms.length === 0) {
      this.setAllPlatformTemplatesFromTemplate();

      this.flatplanPage.platforms.push(new FlatplanPlatform(this.tabletTemplate.templateguid, 2));
      this.flatplanPage.platforms.push(new FlatplanPlatform(this.phoneTemplate.templateguid, 3));
      this.flatplanPage.platforms.push(new FlatplanPlatform(this.desktopTemplate.templateguid, 4));

      this.setArticleCountForFlatplanPage(this.flatplanPage);
      this.flatplanSection.pages.push(this.flatplanPage);

      this.modalService.destroy();
    }
    else {

      let thisFlatplanPlatform = this.getFlatplanPlatform();
      thisFlatplanPlatform.templateguid = this.chosenTemplate.templateguid;

      if (this.flatplanPage.type === 'templateSection') {
        this.setArticleCountForFlatplanPage(this.flatplanPage);
      }

      this.modalService.destroy();
    }
  }

  close()
  {
    this.modalService.destroy();
  }
}
