import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {ModalService} from "../modal";
import {EditionPageTypeComponent} from "../apps/editions/view-section/edition-page-type/edition-page-type.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import Amplify, { Auth } from 'aws-amplify';
import {environment} from "../../environments/environment";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private loadingService: LoadingService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private domsanitizer: DomSanitizer,
              private modalService: ModalService) { }

  @ViewChild('text') texts: ElementRef;
  @ViewChild('password') password: ElementRef;

  public monkey = "Monkey_Middle.png";
  public space = "spacemonkeyicon";
  public error: any;

  ngOnInit(): void { }


  changevizor() {
    this.space = "spacemonkeydown";
    this.monkey = "Monkey_Closed.png";
  }

  async login() {
    this.loadingService.setLoading();
    try {
      const user = await this.authService.signin(this.texts.nativeElement.value, this.password.nativeElement.value);
      console.log(user);
      if (user['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
        console.log('need to reset password');
        this.modalService.init(PasswordResetComponent, {}, {});

      } else {
        this.router.navigate(['/apps'], { relativeTo: this.route });
      }
    } catch (e) {
      console.log(e);
      this.error = e.message;
    }

    this.loadingService.setFinished();
    // this.loadingService.setLoading();

    // const res = await this.authService.signin(this.texts.nativeElement.value, this.password.nativeElement.value);

    // if(res.error) {
    //   this.error = this.domsanitizer.bypassSecurityTrustHtml(res.error['Error']);
    // } else {
    //   this.error = "";

    // }
    // this.loadingService.setFinished();
  }

  capturecontent() {
    this.space = "spacemonkeyicon";

    switch (true) {
      case this.texts.nativeElement.value.length < 4:
        this.monkey = "Monkey_Left2.png";
        break;
      case this.texts.nativeElement.value.length < 8:
        this.monkey = "Monkey_Left1.png";
        break;
      case this.texts.nativeElement.value.length < 12:
        this.monkey = "Monkey_MiddleDown.png";
        break;
      case this.texts.nativeElement.value.length < 16:
        this.monkey = "Monkey_Right2.png";
        break;
      case this.texts.nativeElement.value.length < 20:
        this.monkey = "Monkey_Right1.png";
        break;
    }
  }

  amplifySignIn() {
    Auth.federatedSignIn();
  }

  shouldShowNCLogin() {
    if(environment.production && window.location.hostname !== "newscorp.admin.pagesuite.com") {
      return false;
    }
    return true;
  }

  forgotPassword() {
    this.modalService.init(ForgotPasswordComponent, {}, {});
  }
}
