import { Component, OnInit } from '@angular/core';
import {Article} from '../../../models/Article';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {RenderArticle} from '../RenderArticle';

@Component({
  selector: 'app-render-feed-style-b',
  templateUrl: './render-feed-style-b.component.html',
  styleUrls: ['./render-feed-style-b.component.css']
})
export class RenderFeedStyleBComponent extends RenderArticle  implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

  }

  sanitizeImg(url)
  {
    return url;
  }

}
