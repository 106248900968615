
export class AdvertType {
  private _type: string;
  private _values: string[];


  constructor(type: string, values: string[]) {
    this._type = type;
    this._values = values;
  }

  /**
   * Getter image
   * @return {string}
   */
  public get type(): string {
    return this._type;
  }

  /**
   * Getter caption
   * @return {string}
   */
  public get values(): string[] {
    return this._values;
  }

  /**
   * Getter size
   * @return {Object}
   */

  public set type(value: string) {
    this._type = value;
  }

  /**
   * Setter caption
   * @param {string} value
   */
  public set values(value: string[]) {
    this._values = value;
  }

}
