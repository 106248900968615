import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, Params } from '@angular/router';
import { filter } from 'rxjs/operators'
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Observable } from 'rxjs';
import {ApplicationsService} from "../../../../services/applications.service";
import {EditionService} from "../../../../services/edition.service";
import {FlatplanmanagerService} from "../../../../services/flatplanmanager.service";

@Component({
  selector: 'breadcrumb-trail',
  templateUrl: './trail.component.html',
  styleUrls: ['./trail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TrailComponent implements OnInit {

  //Breadcrumb Trail
  public $breadcrumbs: Observable<Array<Breadcrumb>>;

  public constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private appService: ApplicationsService,
    private editionService: EditionService,
    private flatplanService: FlatplanmanagerService) {
    this.$breadcrumbs = <Observable<Array<Breadcrumb>>>this.breadcrumbService.getBreadcrumbSource(true);
  }

  ngOnInit(): void {

    this.router.navigated ? this.breadcrumbService.generateBreadcrumbTrail("") : null;
    //Subscribe and trigger on the NavigationEnd event
    this.router.events.pipe(filter(event => {
      return event instanceof NavigationEnd;
    })).subscribe((value: NavigationEnd) => {
      this.breadcrumbService.generateBreadcrumbTrail(value.url);
    });

    this.editionService.editionLoaded.subscribe((ed) => {
      this.breadcrumbService.setEditionName(this.editionService.jsonedition.name);
      this.breadcrumbService.generateBreadcrumbTrail('');
    });

    this.editionService.activeSectionName.subscribe((name) => {
      this.breadcrumbService.setEditionSectionName(name);
      this.breadcrumbService.generateBreadcrumbTrail('');
    });

    this.editionService.activeArticleHeadline.subscribe((headline) => {
      this.breadcrumbService.setArticleHeadline(headline);
      this.breadcrumbService.generateBreadcrumbTrail('');
    });

    this.flatplanService.flatplan().subscribe((flatplan) => {
      //this.breadcrumbService.setFlatplanName(flatplan.name);
    });

    this.appService.getActiveApplication().subscribe(app => {
      if(app.app && app.app.guid) {
        this.breadcrumbService.setAppName(app.app.name);
      }
    });

  }



}
