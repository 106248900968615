import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FeedTemplate} from '../../../models/FeedTemplate';
import {ModalService} from '../../../modal';
import {FeedPropertiesService} from '../feed-properties.service';

@Component({
  selector: 'app-new-feed-template',
  templateUrl: './new-feed-template.component.html',
  styleUrls: ['./new-feed-template.component.css']
})
export class NewFeedTemplateComponent implements OnInit {

  constructor(private modalService: ModalService, private newfeedsrc: FeedPropertiesService) { }
  public newfeed : FeedTemplate = new FeedTemplate(this.newGuid(), "", [], true);
  @Output() feedout: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  addFP()
  {
    this.newfeedsrc.setNewFeedTemplate(this.newfeed);
    this.modalService.destroy();
  }

}
