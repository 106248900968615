import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from "../../../../modal";
import {EditionService} from "../../../../services/edition.service";
import {Edition} from "../../../../models/edition";
import {ApplicationsService} from "../../../../services/applications.service";
import {App} from "../../../../models/App";
import {LoadingService} from "../../../../services/loading.service";
import {ToastService} from "../../../../services/toast.service";
import {AuthService} from "../../../../services/auth.service";
import {User} from "../../../../models/User";
import {forEach} from "@angular/router/src/utils/collection";

@Component({
  selector: 'app-pdf-generation',
  templateUrl: './pdf-generation.component.html',
  styleUrls: ['./pdf-generation.component.css']
})
export class PdfGenerationComponent implements OnInit {

  @Input('edition') edition: Edition;
  @Input('application') application: App;
  replicaObj: any = [];

  public user: User;

  constructor(private modalService: ModalService,
              private editionService: EditionService,
              private loadingService: LoadingService,
              private toastService: ToastService,
              private authService: AuthService,
              private appService: ApplicationsService) { }

  ngOnInit() {
    this.authService.getUser().subscribe((user: User) => {
      this.user = user;
    })
  }

  closeModal() {
    this.modalService.destroy();
  }


  async reprocessPub(pubGuid, editionGuid) {

    this.editionService.setupjobsProcessed.next();

    if(this.user.emailaddress !== 'newscorp-support@pagesuite.com' && !editionGuid) {
      this.toastService.failed("Cannot reprocess while edition is processing");
      return;
    }

    this.loadingService.setLoading();

    const pages = await this.editionService.postNewPages();
    this.toastService.success("Edition Saved");

    const screenshots = await this.editionService.generateScreenshotsForReplica(this.edition.editionGUID);

    this.toastService.success("Pages Rescheduled");

    let replicaPostObj;
    for(let publication of this.application.portalsettings.edition2replica.replicaPublications) {
      if(publication.guid === pubGuid) {
        replicaPostObj = publication;
        break;
      }
    }
    this.replicaObj = [];
    this.replicaObj = await this.editionService.generateReplicas([replicaPostObj], this.edition.editionGUID, this.appService.getStaticAppState().portalsettings.edition2replica.version);
    console.log(this.replicaObj);
    for(let localJob of this.edition.replicajobs) {
      if(localJob.replicapublicationguid == pubGuid) {
        console.log("updating job");
        localJob.jobid = this.replicaObj[0]['jobID'];
        localJob.replicaeditionguid = null;
        localJob.progress = 0;
        localJob.pdfIslive = true;
      }
    }

    this.toastService.success("Replica Job Started");

    await this.editionService.saveEdition(this.edition);

    console.log(this.edition);

    this.loadingService.setFinished();

    this.editionService.setupPoller.next();
  }

  launchEdition(editionGuid: string) {

    if(editionGuid) {
      window.open('https://edition.pagesuite.com/launch.aspx?eid=' + editionGuid, "_blank");
    } else {
      this.toastService.failed("Edition not ready");
    }
  }

  async togglePdfLive(editionGuid: string, index : number, pdfIsOnline: boolean) {

    if(pdfIsOnline) {
      this.edition.replicajobs[index].pdfislive = false;
    } else {
      this.edition.replicajobs[index].pdfislive = true ;
    }

    await this.editionService.saveEdition(this.edition);
    console.log('pdf' + pdfIsOnline);
    let liveObj = {
                    'isLive': pdfIsOnline,
                    'lastModified': new Date()
                  };

    await this.editionService.setEditionPDFLiveStatus(liveObj,editionGuid);
    console.log('Status od PDF changed');

  }

  async triggerEnotify(editionGuid) {
    if(editionGuid) {
      this.loadingService.setLoading();
      let url = this.application.portalsettings['edition2replica']['enotify'].replace('{editionGuid}', editionGuid);
      // url = 'https://edition.pagesuite.com/tribune/enotify.aspx?edid=7a2e7d88-5f5b-43ed-b773-9d74b75871ca';
      const enotifyResponse = await this.editionService.get(url);
      console.log(enotifyResponse);
      this.loadingService.setFinished();
      this.toastService.success(enotifyResponse);
    } else {
      this.toastService.failed("Edition not ready");
    }
  }

  async triggerBulkEnotify() {

  }



  calculateClasses() {
    let allReady = false;

    if(this.edition.replicajobs) {
      allReady = true;
      this.edition.replicajobs.forEach(job => {
        if(!job['replicaeditionguid']) {
          allReady = false;
        }
      });

    }


    if(allReady) {
      return {
        'green': true
      };
    } else {
      return {
        'disabled': true
      };
    }
  }

  async triggerAllEnotify() {
    this.loadingService.setLoading();

    let promises = [];

    if(this.calculateClasses()['disabled']) {
      this.toastService.failed("Please wait till all editions are processed");
      this.loadingService.setFinished();
      return;
    }
    if(this.edition.replicajobs) {
      this.edition.replicajobs.forEach(job => {
        if(job['replicaeditionguid']) {
          const url = this.application.portalsettings['edition2replica']['enotify'].replace('{editionGuid}', job['replicaeditionguid']);
          promises.push(this.editionService.get(url));
        }
      });

      try {
        await Promise.all(promises);
        this.loadingService.setFinished();
        this.toastService.success("All eNotify messages sent");
      } catch (e) {
        this.loadingService.setFinished();
        console.log(e);
        this.toastService.success("There was a problem sending your eNotify messages");
      }

    }


  }
}
