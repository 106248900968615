import { Page } from "./page";
import { Section } from "./Section";

export class Edition {


  constructor(private _editionGUID: string, private _name: string, private _pages: Page[], private _data: Section[],
              private _isLive: boolean, private _pubDateTime: string, private _flow: string, private _datemode: string,
              private _articlemode: string, private _lastprocessed: string, private _applicationguid: string, private _replicajobid: string,
              private _finishedprocessing: boolean, private _pubdate: string, private _pdfeditionguid: string, private _replicajobs: any[]) { }

  get editionGUID() {
    return this._editionGUID;
  }
  set editionGUID(value) {
    this._editionGUID = value;
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
  }

  get pages() {
    return this._pages;
  }
  set pages(value) {
    this._pages = value;
  }

  get data() {
    return this._data;
  }
  set data(value) {
    this._data = value;
  }

  get isLive() {
    return this._isLive;
  }
  set isLive(value) {
    this._isLive = value;
  }

  get pubDateTime() {
    return this._pubDateTime;
  }
  set pubDateTime(value) {
    this._pubDateTime = value;
  }

  get flow() {
    return this._flow;
  }
  set flow(value) {
    this._flow = value;
  }

  get datemode(): string {
    return this._datemode;
  }

  set datemode(value: string) {
    this._datemode = value;
  }

  get articlemode(): string {
    return this._articlemode;
  }

  set articlemode(value: string) {
    this._articlemode = value;
  }

  get lastprocessed(): string {
    return this._lastprocessed;
  }

  set lastprocessed(value: string) {
    this._lastprocessed = value;
  }

  get applicationguid(): string {
    return this._applicationguid;
  }

  set applicationguid(value: string) {
    this._applicationguid = value;
  }

  get replicajobid(): string {
    return this._replicajobid;
  }

  set replicajobid(value: string) {
    this._replicajobid = value;
  }

  get finishedprocessing(): boolean {
    return this._finishedprocessing;
  }

  set finishedprocessing(value: boolean) {
    this._finishedprocessing = value;
  }

  get pubdate(): string {
    return this._pubdate;
  }

  set pubdate(value: string) {
    this._pubdate = value;
  }

  get pdfeditionguid(): string {
    return this._pdfeditionguid;
  }

  set pdfeditionguid(value: string) {
    this._pdfeditionguid = value;
  }

  get replicajobs(): any[] {
    return this._replicajobs;
  }

  set replicajobs(value: any[]) {
    this._replicajobs = value;
  }

}
